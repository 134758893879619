import React from 'react';
import Box from './Box';
import LoadingOverlay from './LoadingOverlay';

function FullLoadingPage() {
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
      justify="center"
      position="absolute"
      left="0"
      top="0"
    >
      <LoadingOverlay isVisible={true} translucent={false} fullOverlay={true} />
    </Box>
  );
}

export default FullLoadingPage;
