import 'moment/locale/de';
import 'moment/locale/en-gb';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { FullLoadingPage, LicensePage, NoPermissionPage, SessionCheck } from '@looxr/components';
import {
  ADMIN_PORTAL_URL,
  AUTH_API_URL,
  Collections,
  LEAK_PORTAL_URL,
  LOGIN_PORTAL_URL,
  ROLES
} from '@looxr/constants';
import { BaseTheme, GlobalStyle } from '@looxr/theme';
import { Translate, useLanguage } from '@looxr/utils';
import Routes from './Routes';
import { ApiService, AppStateService, AuthService, FirebaseService } from './services';

function App() {
  const language = useLanguage();
  const [isLoading, setIsLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const [sessionUserID, setSessionUserID] = useState(null);

  // Effect to update user and customer of user and check permissions
  useEffect(() => {
    const checkUser = async (userDoc) => {
      const userData = userDoc.data();
      let permission = false;
      if (userData.role === ROLES.admin || userData.role === ROLES.looxr) {
        permission = true;
      }

      setHasPermission(permission);

      // If permission is valid update user and customer internal states
      if (permission) {
        const user = {
          id: userDoc.id,
          ref: userDoc.ref,
          ...userData
        };

        const customerDoc = await userData.customer.get();

        const customer = {
          id: customerDoc.id,
          ref: customerDoc.ref,
          ...customerDoc.data()
        };

        // i18n setup
        Translate.changeLanguage(userData.language);
        moment.locale(userData.language);

        AppStateService.setUser(user);
        AppStateService.setCustomer(customer);
      }
    };

    let unsub = null;

    if (sessionUserID) {
      unsub = FirebaseService.getUserQuery(sessionUserID).onSnapshot(checkUser);
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [sessionUserID]);

  // Effect to init loading of the app and react to firebase auth changes
  useEffect(() => {
    Translate.init();

    console.info('Loaded LOOXR:Admin');
    console.info('AUTH URL => ', AUTH_API_URL);
    console.info('ADMIN URL => ', ADMIN_PORTAL_URL);
    console.info('LEAK URL => ', LEAK_PORTAL_URL);
    console.info('LOGIN URL => ', LOGIN_PORTAL_URL);

    const init = async () => {
      await FirebaseService.init();
      const hasSession = await AuthService.status();
      let sessionUser;

      // 1. auth url => auth/saml/status
      // 1.1 status 200
      // 1.1.1 get user auth/saml/user
      // 1.2. status 401
      // 1.2.1 => redirect to auth/saml/login
      if (hasSession) {
        sessionUser = await AuthService.getUser();
      } else {
        AppStateService.reset();
        await FirebaseService.logout();
        // triggers a redirect
        AuthService.login();
      }

      FirebaseService.ref.auth().onAuthStateChanged(async (fbSessionUser) => {
        if (fbSessionUser) {
          if (fbSessionUser && hasSession && fbSessionUser.uid !== sessionUser.user_token) {
            setSessionUserID(null);
            AppStateService.reset();
            await FirebaseService.logout();
          } else {
            // Load User doc based on session users uuid
            const userDoc = await FirebaseService.getUser(fbSessionUser.uid);
            const userData = userDoc.data();

            const user = {
              id: userDoc.id,
              ref: userDoc.ref,
              ...userData
            };

            AppStateService.setUser(user);

            if (userData.role === ROLES.admin || userData.role === ROLES.looxr) {
              setHasPermission(true);
              setSessionUserID(userDoc.id);

              const user = {
                id: userDoc.id,
                ref: userDoc.ref,
                ...userData
              };

              // i18n setup
              Translate.changeLanguage(userData.language);
              moment.locale(userData.language);

              const customerRef = userData.customer;
              const customerDoc = await customerRef.get();
              const customerData = customerDoc.data();

              const customer = {
                id: customerDoc.id,
                ref: customerDoc.ref,
                ...customerData
              };

              AppStateService.setUser(user);
              AppStateService.setCustomer(customer);
            }
          }

          // @TODO start a listener to load user changes and customer changes
          setIsLoading(false);
        } else if (hasSession) {
          setSessionUserID(null);
          AppStateService.reset();

          try {
            await FirebaseService.signInWithToken(sessionUser.firebase_token);
          } catch (e) {
            FirebaseService.logout();
            AuthService.logout();
          }
        }
      });
    };

    init();
  }, []);

  const handleAcceptLicense = async ({ sendAsMail, sendToMail }) => {
    setIsLoading(true);
    const userDoc = AppStateService.user;
    const update = { ...userDoc };

    delete update.ref;
    delete update.id;

    userDoc.acceptedLicense = true;
    userDoc.acceptedLicenseAt = FirebaseService.getTimestamp();

    update.acceptedLicense = true;
    update.acceptedLicenseAt = FirebaseService.getTimestamp();

    AppStateService.setUser(userDoc);
    await FirebaseService.upsertDoc(userDoc.id, update, Collections.user);

    if (sendAsMail) {
      await ApiService.sendLicenseMail(sendToMail);
    }

    setIsLoading(false);
  };

  const renderApp = () => {
    if (isLoading) {
      return <FullLoadingPage currentLocale={language} />;
    }

    if (AppStateService.user === null) {
      return <FullLoadingPage currentLocale={language} />;
    }

    if (hasPermission) {
      if (AppStateService.user.acceptedLicense) {
        return (
          <>
            <Routes />
            <SessionCheck
              checkFunction={AuthService.status}
              onSessionEnd={async () => {
                AppStateService.reset();
                await FirebaseService.logout();
                AuthService.login();
              }}
            />
          </>
        );
      }

      return <LicensePage currentLocale={language} onLicenseAccepted={handleAcceptLicense} />;
    }

    return <NoPermissionPage currentLocale={language} />;
  };

  return (
    <ThemeProvider theme={BaseTheme}>
      <GlobalStyle />
      {renderApp()}
    </ThemeProvider>
  );
}

export default App;
