import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  Box,
  Button,
  DateInput,
  Label,
  LoadingBox,
  SelectRH as Select,
  TextInputRH
} from '@looxr/components';
import { useLanguage, useTranslation } from '@looxr/utils';
import { getPackageOptions, getPackageTypes } from '../../constants';
import { AppStateService, FirebaseService } from '../../services';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

const StyledFormButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button:first-child {
    margin-right: 20px;
  }
`;

function CustomerPackageForm({ onSave, onCancel, packageData, customerData }) {
  const language = useLanguage();
  const tn = useTranslation();

  const { register, unregister, handleSubmit, errors, reset, setValue, watch } = useForm({
    defaultValues: { type: null, validUntil: '', maxAccounts: '' }
  });

  const [packageOptions, setPackageOptions] = useState([]);
  const [validUntilValue, setValidUntilValue] = useState(null);
  const [accountNumberEditable, setAccountNumberEditable] = useState(false);

  const selectedPackage = watch('type');

  // Effect to load initial given data
  useEffect(() => {
    if (packageData) {
      const config = getPackageTypes().find((type) => type.name === packageData.type);
      const date =
        packageData.validUntil && packageData.validUntil.toDate
          ? packageData.validUntil.toDate()
          : null;
      setValue('validUntil', date);
      setValidUntilValue(date);

      if (config) {
        setAccountNumberEditable(AppStateService.isLOOXR() || config.accountsEditable);
      }
    }
  }, [packageData]);

  // Effect to update max accounts from config when package type changed
  useEffect(() => {
    const config = getPackageTypes().find((type) => type.name === selectedPackage);

    // only override when is new
    if (config && packageData.id === undefined) {
      setAccountNumberEditable(AppStateService.isLOOXR() || config.accountsEditable);
      setValue('maxAccounts', config.accounts);
    }
  }, [selectedPackage]);

  // Effect to register custom date input
  useEffect(() => {
    register({ name: 'validUntil' }, { required: true });

    return () => {
      unregister('validUntil');
    };
  }, [register]);

  // Effect to reset form data once changed
  useEffect(() => {
    // reset used to init form with loaded data
    if (packageData && packageData.id) {
      reset({ ...packageData });
    }
  }, [packageData]);

  // Effect to load valid package options when customer data changes
  useEffect(() => {
    let options = getPackageOptions();

    // strip out options that can not be choosen by this type of customer
    if (customerData) {
      const validTypes = getPackageTypes()
        .filter((packageConfig) => packageConfig.validForType.includes(customerData.type))
        .map((packageConfig) => packageConfig.name);
      options = options.filter((option) => validTypes.includes(option.value));
    }

    // opt group the options
    const optGroups = [];
    optGroups.push({
      label: 'Leckage',
      options: options.filter((option) => option.value.indexOf('PACKAGE_LEAK') >= 0)
    });

    optGroups.push({
      label: 'Druckluft',
      options: options.filter((option) => option.value.indexOf('PACKAGE_PRESSURE') >= 0)
    });

    setPackageOptions(optGroups);
  }, [customerData]);

  const updateValidUntil = (date) => {
    // form update & trigger validation
    setValue('validUntil', date, true);
  };

  const save = async (data) => {
    const update = { ...data };

    if (packageData.id) {
      update.id = packageData.id;
    }

    // transform date to firebase timestamp
    if (update.validUntil.toDate === undefined) {
      update.validUntil = FirebaseService.getTimestamp(update.validUntil);
    }

    // transform max account to number
    update.maxAccounts = parseInt(update.maxAccounts, 10);

    onSave(update);
  };

  return (
    <LoadingBox loading={packageOptions.length === 0} renderChildren={packageOptions.length > 0}>
      <form onSubmit={handleSubmit(save)} noValidate>
        <StyledFormBox>
          <Box paddingY={3} paddingX={3} width="100%">
            <Label htmlFor="type" required={true} valid={errors?.type === undefined}>
              {tn('general.package')}
              {/* Paket */}
            </Label>
            <Select
              disabled={packageData.id !== undefined}
              id="type"
              name="type"
              optGroups={packageOptions}
              placeholder="Bitte wählen"
              ref={register({ required: true })}
            />
          </Box>
          <Box paddingY={3} paddingX={3} width="100%">
            <Label htmlFor="maxAccounts" required={true} valid={errors?.maxAccounts === undefined}>
              {tn('admin.page.customerForm.packages.accountLimit')}
              {/* Account Anzahl */}
            </Label>
            <TextInputRH
              //
              disabled={!accountNumberEditable}
              id="maxAccounts"
              name="maxAccounts"
              type="number"
              ref={register({ required: true })}
            />
          </Box>
          <Box paddingY={3} paddingX={3} width="100%">
            <Label htmlFor="validUntil" required={true} valid={errors?.validUntil === undefined}>
              {tn('general.validUntil')}
              {/* Gültig bis */}
            </Label>
            <DateInput
              locale={language}
              id="validUntil"
              name="validUntil"
              value={validUntilValue}
              onChange={(date) => updateValidUntil(date)}
            />
          </Box>
        </StyledFormBox>
        <Box display="flex" justify="center" align="center">
          <StyledFormButtonBox paddingX={3} paddingY={5} width={300} marginTop={10}>
            <Button text={packageData.id ? tn('general.save') : tn('general.add')} type="submit" />
            <Button
              text={tn('general.cancel')} // "Abbrechen"
              background="red"
              onClick={() => onCancel()}
            />
          </StyledFormButtonBox>
        </Box>
      </form>
    </LoadingBox>
  );
}

CustomerPackageForm.propTypes = {
  packageData: PropTypes.any,
  customerData: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

CustomerPackageForm.defaultProps = {
  packageData: null,
  customerData: null,
  onSave: () => {},
  onCancel: () => {}
};

export default CustomerPackageForm;
