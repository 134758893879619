import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  faAngleRight as NextIcon,
  faAngleLeft as PrevIcon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@looxr/utils';
import Box from './Box';
import Button from './Button';
import Text from './Text';

const StyledSortTablePager = styled(Box)`
  ${(props) =>
    props.fixedBottom &&
    css`
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    `}
`;

const StyledSortTablePagerInfo = styled(Box)`
  span:first-child {
    margin-left: 0;
  }

  span {
    margin-left: 6px;
  }
`;

const StyledSortTablePagerPages = styled(Box)`
  button:first-child {
    margin-left: 0;
  }

  button {
    margin-left: 3px;
  }
`;

const SortTablePager = ({
  currentPage,
  onPageUpdate,
  condensed,
  fixedBottom,
  perPage,
  totalCount,
  dataName,
  selectedRows
}) => {
  const tn = useTranslation();

  const sendPageUpdate = (newPage) => {
    const maxPage = Math.ceil(totalCount / perPage);
    let pageUpdate = newPage;
    pageUpdate = Math.max(1, pageUpdate);
    pageUpdate = Math.min(maxPage, pageUpdate);

    onPageUpdate(pageUpdate);
  };

  /**
   * Render page select buttons
   */
  const renderPages = () => {
    const pageCount = Math.ceil(totalCount / perPage);
    let pagesToRender = [];

    // to much pages result into page splitting with more button and current page +1 -1
    // "<firstpage>" 2 3 ... <lastpage>
    // <firstpage> "2" 3 ... <lastpage>
    // <firstpage> 2 "3" 4 ... <lastpage>
    // <firstpage> ... 3 "4" 5 ... <lastpage>
    // <firstpage> ... 14 "15" 16 ... <lastpage>
    // <firstpage> ... 15 "16" 17 <lastpage>
    // <firstpage> ... 15 16 "17" <lastpage>
    // <firstpage> ... 16 17 "<lastpage>"
    if (pageCount > 10) {
      // add first page
      pagesToRender.push(renderPageBtn(1));

      // add current page-1 - if not first page
      if (currentPage !== 1 && currentPage - 1 !== 1) {
        pagesToRender.push(renderMoreBtn('left'));
        if (currentPage !== pageCount) {
          pagesToRender.push(renderPageBtn(currentPage - 1));
        }
      }

      // add current page
      if (currentPage === 1) {
        pagesToRender.push(renderPageBtn(2));
        pagesToRender.push(renderPageBtn(3));
      } else if (currentPage === pageCount) {
        pagesToRender.push(renderPageBtn(pageCount - 2));
        pagesToRender.push(renderPageBtn(pageCount - 1));
      } else if (currentPage !== 1 && currentPage !== pageCount) {
        pagesToRender.push(renderPageBtn(currentPage));
      }

      // add current page +1 if not lastpage
      if (currentPage + 1 !== pageCount && currentPage !== pageCount) {
        if (currentPage !== 1) {
          pagesToRender.push(renderPageBtn(currentPage + 1));
        }
        pagesToRender.push(renderMoreBtn('right'));
      }

      // add last page - if lastpage === current page +1 this overwrites it
      pagesToRender.push(renderPageBtn(pageCount));
    } else {
      const pages = [];
      for (let i = 1; i <= pageCount; i += 1) {
        pages.push(i);
      }
      pagesToRender = pages.map((pageNum) => renderPageBtn(pageNum));
    }

    return pagesToRender;
  };

  const renderPageBtn = (pageNum) => {
    return (
      <Button
        size="sm"
        width={35}
        minWidth={30}
        borderRadius={3}
        background={pageNum === currentPage ? 'green' : 'grey'}
        key={`sort-table-page-btn-${pageNum}`}
        onClick={() => sendPageUpdate(pageNum)}
        className="page"
        text={`${pageNum}`}
      />
    );
  };

  /**
   * render more pages button
   */
  const renderMoreBtn = (key) => {
    return (
      <Button
        size="sm"
        width={35}
        minWidth={30}
        borderRadius={3}
        background="grey"
        key={key}
        disabled
        className="page more"
        text="..."
      />
    );
  };

  /**
   * render button for prev page
   */
  const renderPrevBtn = () => {
    return (
      <Button
        size="sm"
        width={35}
        minWidth={30}
        borderRadius={3}
        background="grey"
        onClick={() => sendPageUpdate(currentPage - 1)}
        icon={<FontAwesomeIcon icon={PrevIcon} color="#fff" size="sm" />}
      />
    );
  };

  /**
   * render button for next page
   */
  const renderNextBtn = () => {
    return (
      <Button
        size="sm"
        width={30}
        minWidth={30}
        borderRadius={3}
        background="grey"
        onClick={() => sendPageUpdate(currentPage + 1)}
        icon={<FontAwesomeIcon icon={NextIcon} color="#fff" size="sm" />}
      />
    );
  };

  /**
   * render pager if needed
   */
  const renderPager = () => {
    if (totalCount <= 0) {
      return null;
    }

    return (
      <StyledSortTablePager
        fixedBottom={fixedBottom}
        display="flex"
        direction="row"
        justify="space-between"
        paddingX={4}
        paddingY={3}
      >
        <StyledSortTablePagerInfo display="flex" direction="row">
          <Text size="sm" color="black" bold>
            {(currentPage - 1) * perPage + 1}
            {'-'}
            {totalCount < perPage ? totalCount : Math.min(totalCount, currentPage * perPage)}
          </Text>

          <Text size="sm" color="black">
            {tn('general.of')}
            {/* von */}
          </Text>
          <Text size="sm" color="purple" bold>
            {totalCount} {dataName}
          </Text>
          {selectedRows.length > 0 && (
            <Text size="sm" color="black">
              {`(${selectedRows.length} ${tn('general.selected')})`}
              {/* `(${selectedRows.length} Ausgewählt)` */}
            </Text>
          )}
        </StyledSortTablePagerInfo>
        {totalCount > perPage && (
          <StyledSortTablePagerPages display="flex" direction="row">
            {renderPrevBtn()}
            {condensed === true ? null : renderPages()}
            {renderNextBtn()}
          </StyledSortTablePagerPages>
        )}
      </StyledSortTablePager>
    );
  };
  return renderPager();
};

SortTablePager.propTypes = {
  onPageUpdate: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  dataName: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  condensed: PropTypes.bool,
  fixedBottom: PropTypes.bool,
  selectedRows: PropTypes.array
};

SortTablePager.defaultProps = {
  condensed: false,
  fixedBottom: false,
  selectedRows: []
};

export default SortTablePager;
