import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { LoadingBox } from '@looxr/components';
import { TreeGraph } from '../../components';
import { useLoadCustomers } from '../../hooks';
import { extractIdsFromChildMap } from '../../utils';

function CustomerTreeGraph({ customer, parent }) {
  const { getCustomerByIds } = useLoadCustomers();
  const [graphChildData, setGraphChildData] = useState({});
  const [loading, setLoading] = useState(true);

  // Data shoudl be cached since this can be a heavy load
  // when cached check the map against the loaded data
  // needs a sorting to be consistent the way the tree shows up
  useEffect(() => {
    setLoading(true);
    const mapChildDocToTreeGraph = (map, docs) => {
      const treeNodes = [];
      const levelKeys = Object.keys(map);

      levelKeys.forEach((key) => {
        const id = key.trim();
        const doc = docs.filter((doc) => doc.id === id)[0];

        if (doc) {
          const treeNode = { name: doc.name1, children: [] };
          const nextLevel = map[key];
          const nextLevelKeys = Object.keys(nextLevel);
          if (nextLevel && nextLevelKeys.length > 0) {
            treeNode.children = mapChildDocToTreeGraph(nextLevel, docs);
          }

          treeNodes.push(treeNode);
        }
      });

      return treeNodes;
    };

    const sortTreeNode = (node) => {
      if (node.children.length > 0) {
        node.children.sort((a, b) => (a.name > b.name ? 1 : -1));

        for (let i = 0; i < node.children.length; i += 1) {
          let child = node.children[i];
          child = sortTreeNode(child);
        }
      }

      return node;
    };

    const mapCustomerDocsToGraph = (customer, parent, childMap, childData) => {
      const treeRoot = {
        name: parent.name1,
        children: [
          {
            name: customer.name1,
            children: []
          }
        ]
      };

      treeRoot.children[0].children = mapChildDocToTreeGraph(childMap, childData);

      return treeRoot;
    };

    const buildTreeData = async () => {
      const { childMap } = customer;
      const childIds = extractIdsFromChildMap(childMap);
      // @NOTE having the parent with its childmap here is a potential breach
      // the user below the parent should not be able to see the full tree map of the parent
      // would lead worst case to somebody be able to see the full customer tree for looxr
      const childDocs = await getCustomerByIds(childIds);
      const graphData = mapCustomerDocsToGraph(customer, parent, childMap, childDocs);
      const sorted = sortTreeNode(graphData);
      setGraphChildData(sorted);
      setLoading(false);
    };

    buildTreeData();
  }, [customer]);

  return (
    <LoadingBox loading={loading} renderChildren={!loading}>
      <TreeGraph data={graphChildData} />
    </LoadingBox>
  );
}

CustomerTreeGraph.propTypes = {
  customer: PropTypes.any,
  parent: PropTypes.any
};

CustomerTreeGraph.defaultProps = {
  customer: {},
  parent: null
};

export default CustomerTreeGraph;
