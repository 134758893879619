import 'firebase/auth';
import 'firebase/firestore';

import firebase from 'firebase/app';
import { Collections, FirebaseConfig } from '@looxr/constants';

const FirebaseService = {
  ref: null,

  /**
   * Create a firebase timestamp
   */
  getTimestamp(srcDate) {
    const fromDate = srcDate !== undefined ? srcDate : new Date();
    return firebase.firestore.Timestamp.fromDate(fromDate);
  },

  /**
   * Init Firebase
   */
  async init() {
    if (this.ref === null) {
      FirebaseService.ref = firebase.initializeApp(FirebaseConfig);
    }

    return true;
  },

  /**
   * Sign in with custom token
   */
  async signInWithToken(token) {
    return new Promise((resolve, reject) => {
      return this.ref
        .auth()
        .signInWithCustomToken(token)
        .then((user) => resolve(user))
        .catch((err) => reject(err));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      return this.ref
        .auth()
        .signOut()
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  },

  /**
   * Get query for all components
   */
  getComponentQuery() {
    return FirebaseService.ref.firestore().collection(Collections.component);
  },

  /**
   * Get all customer documents
   */
  async getCustomers() {
    return FirebaseService.ref.firestore().collection(Collections.customer).get();
  },

  /**
   * Get all packages assigned to the customer
   */
  async getPackagesOfCustomer(customerRef) {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.package)
      .where('customer', '==', customerRef)
      .get();
  },

  /**
   * Get all user documents
   */
  async getUsers() {
    return FirebaseService.ref.firestore().collection(Collections.user).get();
  },

  /**
   * Get all user for customer documents
   */
  async getUsersForCustomer(customerRef) {
    return FirebaseService.ref
      .firestore()
      .collection(Collections.user)
      .where('customer', '==', customerRef)
      .get();
  },

  /**
   * Get all users which have given packe for type of login
   * @param {*} type
   * @param {*} customerPackage
   */
  async getUsersByPackage(type, packageRef) {
    const dataKey = type === 'leak' ? 'leakLogin' : 'pressureLogin';
    return FirebaseService.ref
      .firestore()
      .collection(Collections.user)
      .where(`${dataKey}.package`, '==', packageRef)
      .get();
  },

  /**
   * Get single user by uid
   */
  async getUser(uid) {
    return FirebaseService.ref.firestore().collection(Collections.user).doc(uid).get();
  },

  /**
   * Get single user query by uid
   */
  getUserQuery(uid) {
    return FirebaseService.ref.firestore().collection(Collections.user).doc(uid);
  },

  /**
   * Create / Update Document
   */
  async upsertDoc(id, data, collection) {
    try {
      let result;
      let resultDoc;
      if (id) {
        resultDoc = await this.ref.firestore().collection(collection).doc(id).set(data);
        result = true;
      } else {
        resultDoc = await this.ref.firestore().collection(collection).add(data);

        result = resultDoc.id;
      }

      return result;
    } catch (error) {
      console.log(`Error writing ${collection} with data - [${id}]`, data);
      console.log(error);
      return false;
    }
  },

  /**
   * Get leaks by component
   */
  async getLeaksByComponent(component) {
    try {
      const leaks = [];
      const snapshot = await this.ref
        .firestore()
        .collection(Collections.leak)
        .where('components.refs', 'array-contains', component.ref)
        .get();

      snapshot.forEach((doc) => {
        leaks.push({
          id: doc.id,
          ref: doc.ref,
          ...doc.data()
        });
      });

      return leaks;
    } catch (error) {
      console.log(`Error getting leaks by component`, component);
      console.log(error);
      return false;
    }
  },

  /**
   * Get leaks by repair component
   */
  async getLeaksByRepairComponent(component) {
    try {
      const leaks = [];
      const snapshot = await this.ref
        .firestore()
        .collection(Collections.leak)
        .where('repairComponents.refs', 'array-contains', component.ref)
        .get();

      snapshot.forEach((doc) => {
        leaks.push({
          id: doc.id,
          ref: doc.ref,
          ...doc.data()
        });
      });

      return leaks;
    } catch (error) {
      console.log(`Error getting leaks by component`, component);
      console.log(error);
      return false;
    }
  },

  /**
   * Delete component
   */
  async deleteComponent(component) {
    try {
      await this.ref.firestore().collection(Collections.component).doc(component.id).delete();

      return true;
    } catch (error) {
      console.log(`Error deleting component`, component);
      console.log(error);
      return false;
    }
  },

  /**
   * Update user profile data
   */
  async updateProfileData(id, data) {
    try {
      const result = await this.ref.firestore().collection(Collections.user).doc(id).update(data);

      return result;
    } catch (error) {
      console.log(`Error updating profile of user - [${id}]`, data);
      console.log(error);
      return false;
    }
  },

  async getLeaksByUserWithFilter(user, range) {
    const leaks = [];
    if (user && range) {
      const { from, to } = range;

      if (from && to) {
        try {
          const snapshot = await this.ref
            .firestore()
            .collection(Collections.leak)
            .where('createdBy', '==', user.ref)
            .where('createdAt', '>=', from)
            .where('createdAt', '<=', to)
            .get();

          snapshot.forEach((doc) => {
            leaks.push({
              id: doc.id,
              ref: doc.ref,
              ...doc.data()
            });
          });
        } catch (e) {
          console.log('Error getting leaks by user and filter');
          console.log(e);
        }
      }
    }

    return leaks;
  }
};

export default FirebaseService;
