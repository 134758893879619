import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from '@looxr/utils';
import GriesingerImage from '../assets/griesinger_new.png';
import Box from './Box';
import Modal from './Modal';
import Text from './Text';

const StyledAvatar = styled(Box)`
  img {
    width: 150px;
    display: block;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.purple};
    & > * {
      color: ${(props) => props.theme.colors.purple};
    }
  }
`;

function HelpModal({ isVisible, onClose, blockScroll }) {
  const tn = useTranslation();

  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      closeOnBackdrop={true}
      closeOnEsc={true}
      showModalCloseIcon={true}
      blockScroll={blockScroll}
    >
      <Box display="flex" alignItems="center" direction="column" padding={6}>
        <StyledAvatar display="flex" alignItems="center" direction="column">
          <Box>
            <img src={GriesingerImage} alt="Marina Griesinger" />
          </Box>
          <Box marginTop={4}>
            <Text color="black" bold>
              Marina Griesinger
            </Text>
          </Box>
        </StyledAvatar>
        <Box padding={6} display="flex" alignItems="center" direction="column">
          <Box>
            <Text color="purple" size="xl">
              {tn('general.helpOverlay.needHelp')}
              {/* Benötigen Sie Unterstützung? */}
            </Text>
          </Box>

          <Box>
            <Text size="sm">
              {tn('general.helpOverlay.iAnswerYourQuestions')}
              {/* Gerne beantworte ich Ihre Fragen */}
            </Text>
          </Box>
        </Box>
        <Box display="flex" width="100%" alignItems="center" justify="space-evenly" direction="row">
          <Box>
            <Text bold>
              {tn('general.phone')}
              {/* Telefon */}
            </Text>
            <Text>+49 711 - 7972 118</Text>
          </Box>
          <Box>
            <Text bold>
              {tn('general.fax')}
              {/* Telefax */}
            </Text>
            <Text>+49 711 - 7972 174</Text>
          </Box>
          <Box>
            <Text bold>
              {tn('general.email')}
              {/* E-Mail */}
            </Text>
            <StyledLink href="mailto:marina.griesinger@looxr.de">
              <Text>marina.griesinger@looxr.de</Text>
            </StyledLink>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

HelpModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  blockScroll: PropTypes.bool
};

HelpModal.defaultProps = {
  onClose: () => {},
  blockScroll: true
};

export default HelpModal;
