import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';

const StyledFooter = styled(Box)`
  position: ${props => (props.isSticky ? 'fixed' : 'relative')};
  bottom: ${props => (props.isSticky ? '0px' : null)};
  border-top: 1px solid ${props => props.theme.colors.borderColor};
`;

function Footer({ children, sticky }) {
  return (
    <>
      <StyledFooter
        isSticky={sticky}
        width="100%"
        background="white"
        noElevation
        display="flex"
        alignItems="left"
        justify="space-between"
        noBorderRadius
        zIndex="90"
        height={68}
        paddingX={5}
      >
        {children}
      </StyledFooter>
      {sticky && <Box height={78} />}
    </>
  );
}

Footer.propTypes = {
  children: PropTypes.any,
  sticky: PropTypes.bool
};

Footer.defaultProps = {
  children: null,
  sticky: false
};

export default Footer;
