import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Text from './Text';

const pixelOrGivenValue = (value) => {
  if (value === 'auto') {
    return 'auto';
  }
  return typeof value === 'number' ? `${value}px` : value;
};

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.grey : props.theme.colors[props.background]};
  color: ${(props) => props.theme.colors.white};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '3px 3px 17px 0 rgba(0, 0, 0, 0.05)'};
  border-radius: ${(props) => `${pixelOrGivenValue(props.borderRadius)}`};
  border: none;
  outline: 0;
  padding: ${(props) => (props.size === 'sm' ? '3px 10px' : '8px 15px')};
  position: relative;
  ${(props) => (!props.width && props.inline ? 'width: auto' : 'width: 100%')};
  ${(props) => props.width && `width: ${props.width}px`};
  ${(props) => props.height && `height: ${props.height}px`};
  min-width: ${(props) => (props.minWidth ? `${pixelOrGivenValue(props.minWidth)}` : '100px')};
  ${(props) => props.noShadow && `box-shadow: none`};
  ${(props) => props.noPadding && `padding: 0px`};

  &:hover:not([disabled]) {
    opacity: 0.9;
  }

  ${(props) =>
    props.hasIconAndText &&
    css`
      display: flex;
      align-items: center;

      span:first-child {
        margin-left: 10px;
      }

      span:nth-child(2) {
        width: 100%;
        display: inline-block;
        text-align: center;
      }
    `};
`;

function Button({ icon, iconColor, text, textSize, ...props }) {
  /* eslint-disable react/button-has-type */
  return (
    <StyledButton hasIconAndText={icon && text} {...props}>
      {icon !== null && (
        <Text inline color={iconColor} size={textSize}>
          {icon}
        </Text>
      )}

      {text !== '' && (
        <Text color="#fff" bold align="center" overflow="normal" size={textSize}>
          {text}
        </Text>
      )}
    </StyledButton>
  );
  /* eslint-enable react/button-has-type */
}

Button.propTypes = {
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  textSize: PropTypes.string,
  width: PropTypes.number,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noShadow: PropTypes.bool
};

Button.defaultProps = {
  textSize: 'sm',
  width: null,
  minWidth: '',
  disabled: false,
  inline: false,
  type: 'button',
  size: 'base',
  name: '',
  background: 'green',
  color: 'white',
  text: '',
  onClick: () => {},
  icon: null,
  iconColor: '#fff',
  borderRadius: 36,
  noShadow: false
};

export default Button;
