import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { faPlus as AddIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ContentBox, Popup, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import CustomerPackage from './CustomerPackage';

const StyledPackageGridItem = styled(Box)`
  position: relative;
  width: 50%;
  height: 325px;

  &:first-child {
    padding-left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.small}) and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    &:nth-child(2n) {
      padding-right: 0;
    }
    &:nth-child(2n + 1) {
      padding-left: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) and (max-width: ${(props) =>
      props.theme.breakpoints.large}) {
    width: 33.3333%;

    &:nth-child(3n) {
      padding-right: 0;
    }
    &:nth-child(3n + 1) {
      padding-left: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    width: 25%;

    &:nth-child(4n) {
      padding-right: 0;
    }
    &:nth-child(4n + 1) {
      padding-left: 0;
    }
  }
`;

const StyledPackageBox = styled(Box)`
  display: flex;
  padding: 6px;
  height: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: ${(props) =>
    props.active ? props.theme.colors.white : props.theme.colors.disabled};
  border: 1px solid ${(props) => props.theme.colors.borderColor};

  * {
    ${(props) => !props.active && `color: ${props.theme.colors.grey}; !important`}
  }

  &.package-new {
    div,
    span {
      cursor: pointer;
    }

    &:hover span,
    &:hover svg {
      opacity: 0.75;
    }
  }

  svg {
    color: ${(props) => props.theme.colors.purple};
    fill: ${(props) => props.theme.colors.purple};
    * {
      fill: ${(props) => props.theme.colors.purple};
    }
  }
`;

function CustomerFormPackages({ customerData, packages, onDelete, onEdit, onCreate }) {
  const tn = useTranslation();

  const [showRemovePackagePopup, setShowRemovePackagePopup] = useState(false);
  const [removePackage, setRemovePackage] = useState(null);

  const handleDelete = (packageData) => {
    setRemovePackage(packageData);
    setShowRemovePackagePopup(true);
  };

  if (customerData.archived && packages.length === 0) {
    return null;
  }

  return (
    <>
      <ContentBox
        title={tn('admin.page.customerForm.packages.title')} // "Pakete"
        marginTop={5}
        noBackground
        noBorder
        noPadding
      >
        <Box display="flex" direction="row" width="100%" wrap="wrap">
          {packages
            .filter((p) => p.active)
            .map((packageData, index) => (
              <StyledPackageGridItem
                paddingY={3}
                paddingX={3}
                key={packageData.id ? packageData.id : index}
              >
                <StyledPackageBox active={packageData.active}>
                  <CustomerPackage
                    packageData={packageData}
                    onDeleteClick={() => handleDelete(packageData)}
                    onEditClick={() => onEdit(packageData)}
                  />
                </StyledPackageBox>
              </StyledPackageGridItem>
            ))}

          {packages
            .filter((p) => !p.active)
            .map((packageData, index) => (
              <StyledPackageGridItem
                paddingY={3}
                paddingX={3}
                key={packageData.id ? packageData.id : index}
              >
                <StyledPackageBox active={packageData.active}>
                  <CustomerPackage
                    packageData={packageData}
                    onDeleteClick={() => handleDelete(packageData)}
                    onEditClick={() => onEdit(packageData)}
                  />
                </StyledPackageBox>
              </StyledPackageGridItem>
            ))}

          {!customerData.archived && (
            <StyledPackageGridItem paddingY={3} paddingX={3}>
              <StyledPackageBox
                active={true}
                className="package-new"
                noElevation
                onClick={() => onCreate()}
              >
                <Box>
                  <Box display="flex" justify="center" alignContent="center" marginY={5}>
                    <FontAwesomeIcon icon={AddIcon} size="2x" />
                  </Box>
                  <Text size="lg" color="purple">
                    {tn('admin.page.customerForm.packages.addPackage')}
                    {/* Paket hinzufügen */}
                  </Text>
                </Box>
              </StyledPackageBox>
            </StyledPackageGridItem>
          )}
        </Box>
      </ContentBox>

      <Popup
        show={showRemovePackagePopup}
        title={tn('admin.popup.deletePackage.title')} // "Paket entfernen"
        message={tn('admin.popup.deletePackage.text')} // "Möchten Sie dieses Paket wirklich entfernen ? Gültige Zugänge die zu diesem Paket gehören werden ebenfalls entfernt."
        confirmText={tn('admin.popup.deletePackage.buttonsYes')} // "Entfernen"
        abortText={tn('admin.popup.deletePackage.buttonsNo')} // "Abbrechen"
        confirmColor="green"
        abortColor="red"
        confirmAction={() => {
          setShowRemovePackagePopup(false);
          onDelete(removePackage);
        }}
        abortAction={() => setShowRemovePackagePopup(false)}
      />
    </>
  );
}
/*


*/
CustomerFormPackages.propTypes = {
  customerData: PropTypes.any,
  packages: PropTypes.any,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func
};

CustomerFormPackages.defaultProps = {
  customerData: {},
  packages: [],
  onDelete: () => {},
  onEdit: () => {},
  onCreate: () => {}
};

export default CustomerFormPackages;
