import PropTypes from 'prop-types';
import React from 'react';
// import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { faPlus as AddIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@looxr/utils';
import Box from './Box';
import Button from './Button';
import Text from './Text';

const StyledContentHolderBox = styled(Box)``;
const StyledContentBox = styled(Box)`
  border: ${(props) => (props.noBorder ? '0px' : '1px')} solid
    ${(props) => props.theme.colors.borderColor};
  min-height: 50px;
`;

function ContentBox({
  children,
  title,
  noPadding,
  noBorder,
  showAddBtn,
  onAddClick,
  noBackground,
  width,
  ...rest
}) {
  const tn = useTranslation();

  return (
    <StyledContentHolderBox
      width={width}
      display="flex"
      direction="column"
      alignItems="flex-start"
      justify="flex-start"
      {...rest}
    >
      <Box display="flex" direction="row" marginBottom={2}>
        {title.length > 0 && (
          <Box>
            <Text color="grey" size="md" bold inline>
              {title}
            </Text>
          </Box>
        )}

        {showAddBtn && (
          <Box marginX={5} paddingX={2}>
            <Button
              size="sm"
              color="green"
              text={tn('general.add')} // "Hinzufügen"
              width={150}
              onClick={() => onAddClick()}
              icon={<FontAwesomeIcon icon={AddIcon} />}
            />
          </Box>
        )}
      </Box>
      <StyledContentBox
        width="100%"
        background={noBackground ? null : 'white'}
        noElevation
        noBorder={noBorder}
        display="flex"
        alignItems="flex-start"
        justify="flex-start"
        padding={noPadding ? 0 : 5}
      >
        {children}
      </StyledContentBox>
    </StyledContentHolderBox>
  );
}

ContentBox.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noBorder: PropTypes.bool,
  noBackground: PropTypes.bool,
  children: PropTypes.any,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  showAddBtn: PropTypes.bool,
  addRoute: PropTypes.string,
  onAddClick: PropTypes.func
};

ContentBox.defaultProps = {
  width: '100%',
  noBorder: false,
  noBackground: false,
  children: null,
  title: '',
  noPadding: false,
  showAddBtn: false,
  addRoute: '',
  onAddClick: () => {}
};

export default ContentBox;
