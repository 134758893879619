import PropTypes from 'prop-types';
import { useEffect } from 'react';

function SessionCheck({ checkFunction, onSessionEnd }) {
  useEffect(() => {
    let timeCheckInterval = null;

    const clearCheckInterval = () => {
      if (timeCheckInterval) {
        try {
          clearInterval(timeCheckInterval);
        } catch (e) {
          console.log(e);
        }
      }
    };

    const setupInterval = () => {
      timeCheckInterval = setInterval(async () => {
        const hasSession = await checkFunction();
        if (!hasSession) {
          onSessionEnd();
        }
      }, 5000);
    };

    setupInterval();

    return () => {
      clearCheckInterval();
    };
  }, [checkFunction, onSessionEnd]);

  return null;
}

SessionCheck.propTypes = {
  checkFunction: PropTypes.func,
  onSessionEnd: PropTypes.func
};

SessionCheck.defaultProps = {
  checkFunction: () => {},
  onSessionEnd: () => {}
};

export default SessionCheck;
