import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { ADMIN_PORTAL_URL, LEAK_PORTAL_URL, LOGIN_PORTAL_URL } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import AdminIcon from '../assets/icons/app_icon_admin.svg';
import LeckageIcon from '../assets/icons/app_icon_leckage.svg';
import LooxrIcon from '../assets/icons/app_icon_looxr.svg';
import Box from './Box';

const StyledAppMenu = styled(Box)`
  z-index: 200;
  border: 1px solid ${(props) => props.theme.colors.grey2};
  &:first-child {
    margin-top: 0;
  }
`;

const StyledAppMenuItem = styled(Box)`
  margin-top: 10px;
  cursor: pointer;

  a {
    text-decoration: none;
  }

  img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  span {
    color: ${(props) => props.theme.colors.grey};
    font-size: 14px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grey2};
    span {
      color: ${(props) => props.theme.colors.green};
    }
  }
`;

function NaviAppMenu({ canSeeAdmin }) {
  const tn = useTranslation();

  const navigate = (url) => {
    window.location.href = url;
  };

  return (
    <StyledAppMenu
      display="flex"
      alignItems="left"
      justify="left"
      background="white"
      direction="column"
    >
      {canSeeAdmin && (
        <StyledAppMenuItem
          paddingX={5}
          paddingY={2}
          display="flex"
          alignItems="center"
          justify="left"
          direction="row"
          onClick={() => navigate(ADMIN_PORTAL_URL)}
        >
          <img src={AdminIcon} alt="admin" />
          <span>{tn('general.appMenu.admin')}</span>
        </StyledAppMenuItem>
      )}

      <StyledAppMenuItem
        paddingX={5}
        paddingY={2}
        display="flex"
        alignItems="center"
        justify="left"
        direction="row"
        onClick={() => navigate(LOGIN_PORTAL_URL)}
      >
        <img src={LooxrIcon} alt="login" />
        <span>{tn('general.appMenu.login')}</span>
      </StyledAppMenuItem>

      {/*
        import {
          ADMIN_PORTAL_URL,
          LEAK_PORTAL_URL,
          LOGIN_PORTAL_URL,
          PRESSURE_PORTAL_URL
        } from '@looxr/constants';

        import PressureIcon from '../assets/icons/app_icon_druckluft.svg';

        <StyledAppMenuItem
          paddingX={5}
          paddingY={2}
          display="flex"
          alignItems="center"
          justify="left"
          direction="row"
          onClick={() => navigate(PRESSURE_PORTAL_URL)}
        >
          <img src={PressureIcon} alt="pressure" />
          <span>{tn('general.appMenu.pressure')}</span>
        </StyledAppMenuItem>
      */}

      <StyledAppMenuItem
        paddingX={5}
        paddingY={2}
        display="flex"
        alignItems="center"
        justify="left"
        direction="row"
        onClick={() => navigate(LEAK_PORTAL_URL)}
      >
        <img src={LeckageIcon} alt="leak" />
        <span>{tn('general.appMenu.leak')}</span>
      </StyledAppMenuItem>
    </StyledAppMenu>
  );
}

NaviAppMenu.propTypes = {
  canSeeAdmin: PropTypes.bool
};

NaviAppMenu.defaultProps = {
  canSeeAdmin: false
};

export default NaviAppMenu;
