import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, SortTable } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { getPackageUserListTableConfig } from '../../constants';

function CustomerPackageUserList({ users }) {
  const tn = useTranslation();
  const history = useHistory();

  const navigateToUser = (data) => {
    history.push(`/user/${data.id}`);
  };

  return (
    <Box minHeight="400px">
      <SortTable
        tableKey="customers"
        data={users}
        hasPagination={true}
        pagerCondensed={true}
        perPage={10}
        saveState={false}
        noDataText={tn('admin.page.userList.table.noData')} // "Keine Benutzerdaten zu diesen Kriterien vorhanden"
        dataName={tn('admin.page.userList.table.title')} // "Benutzer"
        columns={getPackageUserListTableConfig()}
        hasRowHover={true}
        onRowClick={navigateToUser}
        defaulSortBy="lastname"
        defaultSortOrder="asc"
      />
    </Box>
  );
}

CustomerPackageUserList.propTypes = {
  users: PropTypes.any
};

CustomerPackageUserList.defaultProps = {
  users: []
};

export default CustomerPackageUserList;
