import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Box from './Box';
import LanguageIcon from './LanguageIcon';

const StyledLangSelect = styled(Box)``;

function LanguageSelect({ currentLocale, onChange }) {
  return (
    <StyledLangSelect display="flex" alignItems="center" justify="center">
      <Box display="flex" alignItems="center" justify="center" width={30} height={30}>
        <LanguageIcon
          languageKey="de"
          currentLocale={currentLocale}
          onClick={(languageKey) => onChange(languageKey)}
        />
      </Box>
      <Box display="flex" alignItems="center" justify="center" width={30} height={30}>
        <LanguageIcon
          languageKey="en"
          currentLocale={currentLocale}
          onClick={(languageKey) => onChange(languageKey)}
        />
      </Box>
    </StyledLangSelect>
  );
}

LanguageSelect.propTypes = {
  currentLocale: PropTypes.string,
  onChange: PropTypes.func
};

LanguageSelect.defaultProps = {
  currentLocale: 'en',
  onChange: () => {}
};

export default LanguageSelect;
