import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';

const StyledTeaserHolderBox = styled(Box)``;
const StyledTeaserBox = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
`;

function TeaserBox({ children }) {
  return (
    <StyledTeaserHolderBox display="flex" alignItems="center" justify="center">
      <StyledTeaserBox
        background="white"
        direction="row"
        noElevation
        width="400px"
        display="flex"
        justify={children.length > 1 ? 'space-evenly' : 'center'}
        alignItems="center"
        padding={3}
      >
        {children}
      </StyledTeaserBox>
    </StyledTeaserHolderBox>
  );
}

TeaserBox.propTypes = {
  children: PropTypes.any
};

TeaserBox.defaultProps = {
  children: null
};

export default TeaserBox;
