import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import LooxrLogoWhite from '../assets/icons/logo-white.svg';
import PatternBoxBlue from '../assets/icons/pattern-box-blue.png';
import PatternBoxRed from '../assets/icons/pattern-box-red.png';
import PatternL from '../assets/icons/pattern-subhead-lg.png';
import PatternM from '../assets/icons/pattern-subhead-md.png';
import PatternS from '../assets/icons/pattern-subhead-sm.png';
import Box from './Box';
import Text from './Text';

const StyledBanner = styled(Box)`
  ${(props) =>
    props.type === '' &&
    css`
      background: url(${PatternS}) no-repeat;
      background-position: center right;
      height: 150px;

      @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
        background: url(${PatternM}) no-repeat;
        background-position: top right;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.large}) {
        background: url(${PatternL}) no-repeat;
        background-position: top right;
      }
    `}

  ${(props) =>
    props.inBox &&
    props.type !== '' &&
    css`
      background: url(${props.type.toLowerCase() === 'leak' ? PatternBoxRed : PatternBoxBlue})
        no-repeat;
      background-position: 100% 0;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: 95px;
    `}
`;

const StyledBannerLogo = styled(Box)`
  img {
    display: block;
    min-width: 100px;
    max-width: 200px;
    width: 30%;
  }
`;

const StyledBannerText = styled(Text)`
  color: ${(props) => props.theme.colors.white};
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
`;

const StyledBannerSubText = styled(Text)`
  color: ${(props) => props.theme.colors.white};
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.55);
`;

function Banner({ text, subtext, inBox, type }) {
  return (
    <StyledBanner inBox={inBox} type={type} noBorderRadius>
      {text.length > 0 || subtext.length > 0 ? (
        <Box
          display="flex"
          direction="column"
          alignItems="center"
          justify="center"
          width="100%"
          height="100%"
        >
          {text.length > 0 && (
            <StyledBannerText size="3xl" bold>
              {text}
            </StyledBannerText>
          )}
          {subtext.length > 0 && (
            <StyledBannerSubText size={text.length > 0 ? 'xl' : '2xl'} bold>
              {subtext}
            </StyledBannerSubText>
          )}
        </Box>
      ) : (
        <StyledBannerLogo
          display="flex"
          alignItems="center"
          justify="center"
          width="100%"
          height="100%"
        >
          <img src={LooxrLogoWhite} alt="logo" />
        </StyledBannerLogo>
      )}
    </StyledBanner>
  );
}

Banner.propTypes = {
  text: PropTypes.string,
  subtext: PropTypes.string,
  inBox: PropTypes.bool,
  type: PropTypes.string
};

Banner.defaultProps = {
  text: '',
  subtext: '',
  inBox: false,
  type: ''
};

export default Banner;
