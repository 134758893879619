import { Collections } from '@looxr/constants';
import { FirebaseService } from '../services';
import { extractIdsFromChildMap } from '../utils';

function useUpdateChildMap() {
  const updateParent = async (id, data) => {
    const parentRef = data.parent;

    if (parentRef) {
      const parentId = parentRef.id;
      const parentDoc = await parentRef.get();
      const parentData = parentDoc.data();
      parentData.childMap[id] = data.childMap;

      parentData.childIds = extractIdsFromChildMap(parentData.childMap);

      await FirebaseService.upsertDoc(parentId, parentData, Collections.customer);

      if (parentData.parent) {
        return updateParent(parentRef.id, parentData);
      }
    }

    return true;
  };

  return {
    updateParent
  };
}

export default useUpdateChildMap;
