import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, Label, SelectRH as Select, Text, TextInput, TextInputRH } from '@looxr/components';
import {
  LOOXR_FIREBASE_ID,
  MAIL_PATTERN,
  getLangOptions,
  getRoleOptions,
  getSalutationOptions
} from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { AppStateService } from '../../services';
import { emailIsUnique, userNameIsUnique } from '../../utils';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function UserFormGeneral({ register, errors, userRef, customerData, isCreate, isArchived }) {
  const tn = useTranslation();

  return (
    <>
      <Box display="flex" direction="column">
        <StyledFormBox>
          <Box marginY={3} marginX={3} width={200}>
            <Label htmlFor="customer">
              {tn('admin.page.userForm.general.customer')}
              {/* Kundenzugehörigkeit */}
            </Label>
            <TextInput id="customer" name="customer" disabled={true} value={customerData?.name1} />
          </Box>
          {customerData && customerData.id !== LOOXR_FIREBASE_ID && (
            <Box marginY={3} marginX={3} width={200}>
              <Label htmlFor="role">
                {tn('general.role')}
                {/* Rolle */}
              </Label>
              <Select
                id="role"
                name="role"
                disabled={
                  // eslint-disable-next-line no-nested-ternary
                  isArchived ? true : userRef ? userRef.id === AppStateService.user.id : false
                }
                options={getRoleOptions()}
                placeholder={tn('general.choose')} // "Bitte wählen"
                ref={register({ required: true })}
              />
            </Box>
          )}

          <Box marginY={3} marginX={3} width={200}>
            <Label htmlFor="language">
              {tn('general.language')}
              {/* Bevorzugte Sprache */}
            </Label>
            <Select
              disabled={isArchived}
              id="language"
              name="language"
              options={getLangOptions()}
              ref={register({ required: true })}
            />
          </Box>
        </StyledFormBox>

        <StyledFormBox>
          <Box marginY={3} marginX={3} width={200}>
            <Label htmlFor="salutation" required valid={errors.salutation === undefined}>
              {tn('general.salutation')}
              {/* Anrede */}
            </Label>
            <Select
              disabled={isArchived}
              id="salutation"
              name="salutation"
              placeholder={tn('general.choose')} // "Bitte wählen"
              options={getSalutationOptions()}
              ref={register({ required: true })}
            />
          </Box>

          <Box marginY={3} marginX={3} width={200}>
            <Label htmlFor="firstname" required valid={errors.firstname === undefined}>
              {tn('general.firstname')}
              {/* Vorname */}
            </Label>
            <TextInputRH
              disabled={isArchived}
              id="firstname"
              name="firstname"
              ref={register({ required: true })}
            />
          </Box>

          <Box marginY={3} marginX={3} width={200}>
            <Label htmlFor="lastname" required valid={errors.lastname === undefined}>
              {tn('general.lastname')}
              {/* Nachname */}
            </Label>
            <TextInputRH
              disabled={isArchived}
              id="lastname"
              name="lastname"
              ref={register({ required: true })}
            />
          </Box>
        </StyledFormBox>

        <StyledFormBox>
          <Box marginY={3} marginX={3} width={200}>
            <Label
              htmlFor="email"
              required
              valid={errors.email === undefined && errors.emailDuplicate === undefined}
            >
              {tn('general.email')}
            </Label>
            <TextInputRH
              id="email"
              name="email"
              disabled={isArchived || !isCreate}
              ref={register({
                required: true,
                pattern: MAIL_PATTERN,
                validate: (mail) => emailIsUnique(isCreate, mail, errors)
              })}
            />
            {errors.email && errors.email.type === 'validate' && (
              <Text size="sm" color="red">
                {tn('general.validation.emailDuplicateNotice')}
                {/* E-Mail ist vergeben */}
              </Text>
            )}
          </Box>
          <Box marginY={3} marginX={3} width={200}>
            <Label htmlFor="username" valid={errors.username === undefined}>
              {tn('general.username')}
              {/* Benutzername */}
            </Label>
            <TextInputRH
              disabled={isArchived}
              id="username"
              name="username"
              ref={register({
                validate: (username) => userNameIsUnique(isCreate, username, userRef)
              })}
            />
            {errors.username && errors.username.type === 'validate' && (
              <Text size="sm" color="red">
                {tn('general.validation.usernameDuplicateNotice')}
                {/* Benutzername ist vergeben */}
              </Text>
            )}
          </Box>
        </StyledFormBox>
      </Box>
    </>
  );
}

UserFormGeneral.propTypes = {
  register: PropTypes.any.isRequired,
  errors: PropTypes.any.isRequired,
  customerData: PropTypes.any,
  isCreate: PropTypes.bool.isRequired,
  isArchived: PropTypes.bool,
  userRef: PropTypes.any
};

UserFormGeneral.defaultProps = {
  userRef: null,
  customerData: null,
  isArchived: false
};

export default UserFormGeneral;
