import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@looxr/utils';
import LoadingGif from '../assets/icons/loading.gif';
import Box from './Box';
import Text from './Text';

const StyledLoadingBackground = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey2};
  opacity: ${(props) => (props.translucent ? 0.9 : 1)};
  z-index: 100;
`;

const StyledLoading = styled(Box)`
  cursor: default;
  z-index: 101;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
`;

function LoadingOverlay({ translucent, isVisible, fullOverlay }) {
  const tn = useTranslation();

  if (isVisible) {
    return (
      <>
        <StyledLoadingBackground
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justify="center"
          position={fullOverlay ? 'fixed' : 'absolute'}
          direction="column"
          z-index="990"
          left="0"
          top="0"
          translucent={translucent}
        />
        <StyledLoading
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justify="center"
          position={fullOverlay ? 'fixed' : 'absolute'}
          direction="column"
          z-index="999"
          left="0"
          top="0"
        >
          <img src={LoadingGif} alt="loading" />
          <Text color="black" size="sm">
            {tn('general.loading')}
          </Text>
        </StyledLoading>
      </>
    );
  }

  return null;
}

LoadingOverlay.propTypes = {
  translucent: PropTypes.bool,
  fullOverlay: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired
};

LoadingOverlay.defaultProps = {
  translucent: true,
  fullOverlay: true
};

export default LoadingOverlay;
