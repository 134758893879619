import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';

const StyledBox = styled(Box)`
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  thead {
    th {
      text-align: left;
      border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
      color: ${(props) => props.theme.colors.black};
      font-size: 14px;
      font-weight: 700;
      padding-top: 14px;
      padding-bottom: 12px;
      letter-spacing: 0.5px;
      line-height: 24px;

      &:first-child {
        padding-left: 17px;
      }

      &:last-child {
        padding-right: 17px;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};

      &:nth-child(odd) {
        background-color: ${(props) => props.theme.colors.oddColor};
      }

      &:nth-child(even) {
        background-color: ${(props) => props.theme.colors.white};
      }

      ${(props) =>
        props.hasHover &&
        css`
          cursor: pointer;
          &:not(.no-hover):hover {
            background-color: ${(props) => props.theme.colors.green};

            td,
            * {
              color: ${(props) => props.theme.colors.white};
            }
          }
        `};
    }

    td {
      text-align: left;
      color: ${(props) => props.theme.colors.black};
      font-size: 14px;
      font-weight: 500;
      padding-top: 9px;
      padding-bottom: 9px;
      line-height: 23px;

      &:first-child {
        padding-left: 17px;
      }

      &:last-child {
        padding-right: 17px;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: center;
      }
    }
  }
`;

function TableBox({ children, hover, ...rest }) {
  return (
    <StyledBox hasHover={hover} {...rest} background="white" noElevation>
      {children}
    </StyledBox>
  );
}

TableBox.propTypes = {
  children: PropTypes.any,
  hover: PropTypes.bool
};

TableBox.defaultProps = {
  children: null,
  hover: true
};

export default TableBox;
