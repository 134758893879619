import { CUSTOMER_TYPES, ROLES } from '@looxr/constants';

const AppStateService = {
  user: null,
  customer: null,

  /**
   * Reset app state
   */
  reset() {
    this.removeUser();
  },

  /**
   * Set user
   */
  setUser(user) {
    this.user = user;
  },

  /**
   * Remove current user
   */
  removeUser() {
    this.user = null;
    this.customer = null;
  },

  /**
   * Check if a user is present
   */
  hasUser() {
    return this.user !== null;
  },

  /**
   * Set customer
   */
  setCustomer(customer) {
    this.customer = customer;
  },

  isLOOXR() {
    return this.user && this.user.role === ROLES.looxr;
  },

  isServicePartner() {
    return AppStateService.customer.type === CUSTOMER_TYPES.service;
  },

  isOEM() {
    return AppStateService.customer.type === CUSTOMER_TYPES.oem;
  },

  isCustomer() {
    return AppStateService.customer.type === CUSTOMER_TYPES.customer;
  }
};

export default AppStateService;
