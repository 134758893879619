import 'react-day-picker/lib/style.css';
import 'moment/locale/de';
import 'moment/locale/en-gb';

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import styled from 'styled-components/macro';
import { faTimes as ResetIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from './Box';
import Text from './Text';

const StyledDateRangeHolder = styled.div`
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: no-wrap;
  flex-direction: row;
  display: flex;

  div.DayPickerInput {
    display: flex;
    width: 50%;
  }

  div.DayPickerInput:first-child {
    margin-right: 10px;
  }

  input {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.borderColor};
    color: ${(props) => props.theme.colors.black};
    outline: 0;
    width: 100%;
    border-radius: 4px;
    padding: 7px;
    font-size: 14px;
  }

  .DayPicker {
    &-Day {
      padding: 1px;
      border-radius: 0;
      &--today {
        color: ${(props) => props.theme.colors.green};
      }
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.colors.purple};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};
    opacity: 0.5;
  }

  .DayPicker-Day {
    border-radius: 0 !important;
  }

  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .DayPickerInput-Overlay {
    width: 550px;
  }

  .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`;

const StyledResetIcon = styled(Box)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

function OverlayComponent({ children, ...props }) {
  return (
    <Box background="white" zIndex="1" position="absolute" left="0" top={20} {...props}>
      {children}
    </Box>
  );
}

function DateRangeInput({
  onChange,
  range,
  disabled,
  name,
  locale,
  showReset,
  placeholderFrom,
  placeholderTo
}) {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useEffect(() => {
    setFrom(range.from);
    setTo(range.to);
  }, [range]);

  const toInputRef = useRef(null);

  const handleFromChange = (fromUpdate) => {
    setFrom(fromUpdate);
    const update = {};
    update.from = fromUpdate ? moment(fromUpdate).startOf('day').toDate() : fromUpdate;
    update.to = to;
    onChange(update);
  };

  const handleToChange = (toUpdate) => {
    setTo(toUpdate);
    const update = {};
    update.from = from;
    update.to = toUpdate ? moment(toUpdate).endOf('day').toDate() : toUpdate;
    onChange(update);
  };

  const reset = () => {
    setFrom(null);
    setTo(null);
    onChange({ from: null, to: null });
  };

  const modifiers = { start: from, end: to };

  return (
    <StyledDateRangeHolder isDisabled={disabled}>
      <DayPickerInput
        name={name}
        value={from}
        placeholder={placeholderFrom}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          canChangeMonth: true,
          selectedDays: [from, { from, to }],
          disabledDays: { after: to },
          // toMonth: to,
          modifiers,
          numberOfMonths: 2,
          locale,
          localeUtils: MomentLocaleUtils,
          onDayClick: () => toInputRef.current.getInput().focus()
        }}
        onDayChange={handleFromChange}
        overlayComponent={OverlayComponent}
      />
      <DayPickerInput
        ref={toInputRef}
        value={to}
        placeholder={placeholderTo}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          canChangeMonth: true,
          selectedDays: [from, { from, to }],
          disabledDays: { before: from },
          modifiers,
          month: from,
          // fromMonth: from,
          numberOfMonths: 2,
          locale,
          localeUtils: MomentLocaleUtils
        }}
        onDayChange={handleToChange}
        overlayComponent={OverlayComponent}
      />
      {showReset && (
        <Box display="flex" alignItems="center" justify="center" width="5%">
          {from !== null && to !== null && (
            <StyledResetIcon
              padding={2}
              alignSelf="center"
              alignItems="center"
              justify="center"
              alignContent="center"
              onClick={() => reset()}
            >
              <Text color="red" size="sm">
                <FontAwesomeIcon icon={ResetIcon} />
              </Text>
            </StyledResetIcon>
          )}
        </Box>
      )}
    </StyledDateRangeHolder>
  );
}

DateRangeInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholderFrom: PropTypes.string,
  placeholderTo: PropTypes.string,
  range: PropTypes.shape({
    from: PropTypes.any,
    to: PropTypes.any
  }),
  locale: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showReset: PropTypes.bool
};

DateRangeInput.defaultProps = {
  placeholderFrom: 'dd.mm.yyyy',
  placeholderTo: 'dd.mm.yyyy',
  range: { from: null, to: null },
  locale: 'de',
  disabled: false,
  showReset: false,
  onChange: () => {}
};

OverlayComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

OverlayComponent.defaultProps = {
  children: null
};

export default DateRangeInput;
