import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import Box from './Box';
import Button from './Button';
import Text from './Text';

const StyledPopupWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const StyledBackDrop = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.grey2};
  opacity: 0.9;
`;

function Popup({
  size,
  show,
  title,
  message,
  confirmText,
  abortText,
  confirmColor,
  confirmAction,
  abortAction,
  abortColor,
  blockScroll
}) {
  const closeOnEscKey = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        abortAction();
      }
    },
    [abortAction]
  );

  useEffect(() => {
    if (show) {
      if (blockScroll) {
        document.body.style.overflow = 'hidden';
      }
      document.addEventListener('keydown', closeOnEscKey, false);
    } else {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', closeOnEscKey, false);
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', closeOnEscKey, false);
    };
  }, [show, closeOnEscKey]);

  if (!show) {
    return null;
  }

  return (
    <StyledPopupWrapper>
      <Box
        zIndex={1001}
        position="fixed"
        height="100%"
        width="100%"
        left="0"
        display="flex"
        justify="center"
        alignItems="center"
      >
        <Box width={size} background="white" padding={5}>
          <Text size="lg" color="grey" bold>
            {title}
          </Text>
          <Box marginY={5}>
            <Text size="sm">{message}</Text>
          </Box>
          <Box display="flex" justify="center" alignItems="center" marginTop={10}>
            <Box>
              <Button text={confirmText} onClick={confirmAction} background={confirmColor} />
            </Box>

            <Box marginLeft={5}>
              <Button text={abortText} onClick={abortAction} background={abortColor} />
            </Box>
          </Box>
        </Box>
      </Box>
      <StyledBackDrop show={true} onClick={abortAction} />
    </StyledPopupWrapper>
  );
}

Popup.propTypes = {
  size: PropTypes.number,
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmColor: PropTypes.string,
  confirmText: PropTypes.string,
  abortText: PropTypes.string,
  abortColor: PropTypes.string,
  confirmAction: PropTypes.func,
  abortAction: PropTypes.func,
  blockScroll: PropTypes.bool
};

Popup.defaultProps = {
  size: 400,
  show: false,
  title: '',
  message: '',
  confirmColor: 'green',
  confirmText: '',
  abortText: 'Abbrechen',
  abortColor: 'red',
  confirmAction: null,
  abortAction: null,
  blockScroll: true
};

export default Popup;
