export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_NUMBER = 'sort_number';
export const SORT_DATE = 'sort_date';
export const SORT_BOOL = 'sort_bool';
export const SORT_STRING = 'sort_string';

const getPropertyValue = (prop, object) => {
  let value;

  if (typeof prop === 'function') {
    value = prop(object);
  } else if (prop.includes('.')) {
    const parts = prop.split('.');
    let property = object;

    for (let i = 0; i < parts.length; i = +1) {
      property = property[parts[i]];
    }
  } else {
    value = object[prop];
  }

  if (typeof value === 'string') {
    value = value.toLowerCase();
  }

  return value;
};

/**
 * Default asc array sort by given property
 * => string
 * => number
 * @param {*} sortProp
 */
export const sortDefaultAsc = (sortProp) => {
  return (a, b) => {
    const aPropValue = getPropertyValue(sortProp, a);
    const bPropValue = getPropertyValue(sortProp, b);

    // @TODO handle date sort, bool sort, number sort
    if (aPropValue !== undefined && bPropValue !== undefined && aPropValue < bPropValue) {
      return -1;
    }
    if (aPropValue !== undefined && bPropValue !== undefined && aPropValue > bPropValue) {
      return 1;
    }
    return 0;
  };
};

/**
 * Default desc array sort by given property
 * => string
 * => number
 * @param {*} sortProp
 */
export const sortDefaultDesc = (sortProp) => {
  return (a, b) => {
    const aPropValue = getPropertyValue(sortProp, a);
    const bPropValue = getPropertyValue(sortProp, b);

    // @TODO handle date sort, bool sort, number sort
    if (aPropValue !== undefined && bPropValue !== undefined && aPropValue < bPropValue) {
      return 1;
    }
    if (aPropValue !== undefined && bPropValue !== undefined && aPropValue > bPropValue) {
      return -1;
    }
    return 0;
  };
};
