import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { faUserCircle as ProfileIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, ContentBox, Label, Text, TextInputRH } from '@looxr/components';
import { MAIL_PATTERN, PHONE_PATTERN } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

const StyledAvatar = styled(Box)`
  padding: 20px;

  img {
    max-width: 100%;
  }

  div {
    width: 200px;
    height: 200px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 100px;
    color: ${(props) => props.theme.colors.grey};
  }
`;

const StyledAvatarBtns = styled(Box)`
  button {
    margin-right: 5px;
  }

  button:last-child {
    margin-right: 0;
  }
`;

const HiddenUploadInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  outline: 0;
  visibility: none;
  display: none;
  width: 100%;
  padding: 10px;
`;

function CustomerFormContact({ register, errors, customerData, imageSrc, onImageUpdate }) {
  const tn = useTranslation();
  const uploadRef = useRef();

  const onFileChange = (event) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const src = e.target.result;
      onImageUpdate(src);
    };

    // max 2MB
    const maxSize = 2 * 1024 * 1024;
    const file = event.target.files[0];

    // @TODO check type => make accept a constant and check there

    if (file && file.size <= maxSize) {
      // read the image file as a data URL.
      reader.readAsDataURL(event.target.files[0]);
    } else {
      alert(tn('admin.page.customerForm.contactPerson.imageNotice'));
    }
  };

  const focusUpload = () => {
    uploadRef.current.click();
  };

  return (
    <>
      <ContentBox
        title={tn('admin.page.customerForm.contactPerson.title')} // "Ansprechpartner"
        marginTop={5}
      >
        <Box display="flex" direction="row" width="100%">
          <Box display="flex" direction="column" width="50%">
            <StyledFormBox>
              <Box marginY={3} marginX={3} width={524}>
                <Label htmlFor="contact.name" valid={errors?.contact?.name === undefined}>
                  {tn('general.name')}
                </Label>
                <TextInputRH
                  disabled={customerData.archived}
                  id="contact.name"
                  name="contact.name"
                  ref={register}
                />
              </Box>
            </StyledFormBox>

            <StyledFormBox>
              <Box marginY={3} marginX={3} width={524}>
                <Label htmlFor="contact.email" valid={errors?.contact?.email === undefined}>
                  {tn('general.email')}
                </Label>
                <TextInputRH
                  disabled={customerData.archived}
                  id="contact.email"
                  name="contact.email"
                  ref={register({
                    required: false,
                    pattern: MAIL_PATTERN
                  })}
                />
              </Box>
            </StyledFormBox>

            <StyledFormBox>
              <Box marginY={3} marginX={3} width={524}>
                <Label htmlFor="contact.phone" valid={errors?.contact?.phone === undefined}>
                  {tn('general.phone')}
                </Label>
                <TextInputRH
                  disabled={customerData.archived}
                  id="contact.phone"
                  name="contact.phone"
                  ref={register({
                    required: false,
                    pattern: PHONE_PATTERN
                  })}
                />
              </Box>
            </StyledFormBox>
          </Box>
          <Box display="flex" justify="center" alignContent="center" width="50%">
            <HiddenUploadInput
              ref={uploadRef}
              id="contact.upload"
              name="contact.upload"
              autoComplete="off"
              type="file"
              accept=".png,.jpg,.jpeg"
              onInput={onFileChange}
              onClick={(event) => {
                // @TODO fix it
                // eslint-disable-next-line no-param-reassign
                event.target.value = null;
              }}
            />
            <Box display="flex" justify="center" direction="column">
              <StyledAvatar display="flex" justify="center" alignContent="center">
                {imageSrc ? (
                  <Box display="flex" justify="center" alignContent="center">
                    <img src={imageSrc} alt="contact" />
                  </Box>
                ) : (
                  <Box display="flex" justify="center" alignContent="center">
                    <FontAwesomeIcon icon={ProfileIcon} />
                  </Box>
                )}
              </StyledAvatar>
              {!customerData.archived && (
                <>
                  <Text size="sm" color="grey" align="center">
                    {tn('admin.page.customerForm.contactPerson.imageNotice')}
                    {/* PNG oder JPG mit max. 2MB */}
                  </Text>
                  <StyledAvatarBtns display="flex" justify="center" direction="row">
                    <Button
                      width={150}
                      text={tn('general.chooseImage')} // "Auswählen"
                      background="green"
                      onClick={() => focusUpload()}
                    />
                    {imageSrc && (
                      <Button
                        width={150}
                        text={tn('general.delete')} // "Löschen"
                        background="red"
                        onClick={() => onImageUpdate(null)}
                      />
                    )}
                  </StyledAvatarBtns>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </ContentBox>
    </>
  );
}

CustomerFormContact.propTypes = {
  customerData: PropTypes.any,
  imageSrc: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any.isRequired,
  onImageUpdate: PropTypes.func
  // onUpdate: PropTypes.func.isRequired
};

CustomerFormContact.defaultProps = {
  customerData: null,
  imageSrc: null,
  errors: undefined,
  onImageUpdate: () => {}
};

export default CustomerFormContact;
