import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import styled from 'styled-components';
import {
  Alert,
  Banner,
  Box,
  Button,
  Info,
  LoadingOverlay,
  Modal,
  Popup,
  TeaserBox,
  Text
} from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { getCustomerDefault } from '../../constants';
import { useLoadCustomers, useLoadUsers, usePackages, useUpdateChildMap } from '../../hooks';
import { AppStateService, CustomerService } from '../../services';
import CustomerFormContact from '../CustomerForm/CustomerFormContact';
import CustomerFormGeneral from '../CustomerForm/CustomerFormGeneral';
import CustomerFormParams from '../CustomerForm/CustomerFormParams';
import CustomerFormUrls from '../CustomerForm/CustomerFormPrivacy';
import CustomerTreeGraph from '../CustomerForm/CustomerTreeGraph';

const StyledFormButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  button:first-child {
    margin-right: 20px;
  }
`;

function CustomerProfile() {
  const tn = useTranslation();

  const { getUsersByCustomer } = useLoadUsers();
  const { getPackagesOfCustomer } = usePackages();

  const { updateParent } = useUpdateChildMap();
  const { getCustomerById } = useLoadCustomers();

  const { register, handleSubmit, errors, reset, formState } = useForm({
    defaultValues: getCustomerDefault()
  });

  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [showTreeGraphModal, setShowTreeGraphModal] = useState(false);

  const [customerRef, setCustomerRef] = useState(null);
  const [customerData, setCustomerData] = useState(getCustomerDefault());

  const [parentRef, setParentRef] = useState(null);
  const [parentData, setParentData] = useState(null);

  const [maxUserCount, setMaxUserCount] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);

  const [contactPersonImageSrc, setContactPersonImageSrc] = useState(null);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showMaxUserPopup, setShowMaxUserPopup] = useState(false);

  // Effect to load initial data
  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const searchString = location.search ? location.search.replace('?', '') : null;
      let searchParams = null;
      if (searchString) {
        searchParams = new URLSearchParams(searchString);
      }

      let activeUserCount = 0;
      let maxUserCount = 0;
      let customerParentRef = null;
      let customerParentDoc = null;
      let customerParentData = null;

      const customerDoc = await getCustomerById(AppStateService.customer.id);
      const customerData = customerDoc.data();

      const customerPackages = await getPackagesOfCustomer(customerDoc.ref);

      if (customerPackages.length > 0) {
        maxUserCount = customerPackages
          .map((p) => (p.active ? p.maxAccounts : 0))
          .reduce((p, n) => p + n);
      }

      const userOfCustomerSnapshot = await getUsersByCustomer(customerDoc.ref);
      userOfCustomerSnapshot.forEach((doc) => {
        const docData = doc.data();
        if (!docData.archived === true) {
          activeUserCount += 1;
        }
      });

      customerParentRef = customerData.parent ? customerData.parent : null;

      if (customerParentRef) {
        customerParentDoc = await customerParentRef.get();
      }

      if (customerParentDoc) {
        customerParentData = customerParentDoc.data();
      }

      if (customerData.contact && customerData.contact.image) {
        setContactPersonImageSrc(customerData.contact.image);
      }

      // reset used to init form with loaded data
      reset({ ...customerData });

      setMaxUserCount(maxUserCount);
      setActiveUserCount(activeUserCount);
      setCustomerRef(customerDoc);
      setCustomerData(customerData);
      setParentRef(customerParentRef);
      setParentData(customerParentData);
      setLoading(false);

      if (searchParams !== null && searchParams.has('showUserNotice')) {
        setShowMaxUserPopup(true);
      }
    };

    load();
  }, [id, location]);

  const save = async (data) => {
    const hasErrors = Object.keys(errors).length > 0;

    if (!hasErrors) {
      setLoading(true);

      const updateResult = await CustomerService.processFormData({
        isCreate: false,
        customerData,
        customerRef,
        formData: data,
        contactImage: contactPersonImageSrc,
        brandImage: null,
        parentRef
      });

      if (updateResult !== false) {
        await updateParent(updateResult.id, updateResult.data);

        // @TODO capture if error or not
        // @TODO capute which type/stage caused the error
        // @TODO only proceed when no error
        reset({ ...updateResult.data });

        setCustomerData(updateResult.data);
        setCustomerRef(updateResult.doc);
        setLoading(false);
      } else {
        setShowErrorPopup(true);
      }
    }
  };

  const cancel = () => {
    history.push('/');
  };

  const renderConfirmLeaveModal = (onConfirm, onCancel) => {
    return (
      <Popup
        show
        size={400}
        title={tn('general.popup.unsaved.title')} // "Ungespeicherte Daten"
        message={tn('general.popup.unsaved.text')} // "Möchten Sie die ungeänderten Daten verwerfen"
        confirmColor="red"
        confirmText={tn('general.popup.unsaved.buttonYes')} // "Ja"
        confirmAction={onConfirm}
        abortAction={onCancel}
        abortText={tn('general.popup.unsaved.buttonNo')} // "Nein"
        abortColor="green"
      />
    );
  };

  return (
    <>
      <Banner
        text={tn('admin.page.customerProfle.title')} // "Kundenprofil"
        subtext={customerData.name1}
      />
      <Box paddingX={10} paddingY={10} minHeight="calc(100vh - 143px)">
        {customerData.archived && (
          <TeaserBox>
            <Text size="sm" bold color="purple">
              {tn('admin.page.customerForm.archivedNotice')}
              {/* Dieser Kunde ist archiviert */}
            </Text>
          </TeaserBox>
        )}

        <TeaserBox>
          <Text size="sm" bold color="purple">
            {activeUserCount} / {maxUserCount} {tn('admin.page.customerForm.activeUserNumbers')}
          </Text>
          <Info infoText={tn('admin.page.customerForm.activeUserNote')} bubbleWidth={300} />
        </TeaserBox>

        <form onSubmit={handleSubmit(save)} noValidate>
          <CustomerFormGeneral
            register={register}
            errors={errors}
            customerData={customerData}
            customerRef={customerRef}
            isCreate={false}
            canEditType={false}
            parentData={parentData}
            showMoreMenu={false}
          />

          <CustomerFormUrls register={register} errors={errors} customerData={customerData} />

          <CustomerFormContact
            register={register}
            errors={errors}
            customerData={customerData}
            imageSrc={contactPersonImageSrc}
            onImageUpdate={(base64) => setContactPersonImageSrc(base64)}
          />

          <CustomerFormParams register={register} errors={errors} customerData={customerData} />

          <StyledFormButtonBox paddingX={0} paddingY={5} width={400} marginTop={10}>
            <Button
              disabled={customerData.archived}
              text={tn('general.save')} // "Speichern"
              type="submit"
            />
            <Button
              text={tn('general.cancel')} // "Abbrechen"
              background="red"
              onClick={() => cancel()}
            />
          </StyledFormButtonBox>
        </form>
      </Box>

      <LoadingOverlay isVisible={loading} />

      <Alert
        show={showErrorPopup}
        title={tn('general.error')}
        message={tn('admin.page.userForm.errorOnSaveNotice')}
        btnText={tn('general.ok')}
        btnAction={() => setShowErrorPopup(false)}
      />

      <Alert
        show={showMaxUserPopup}
        title={tn('general.notice')}
        message={tn('admin.page.customerForm.maxUserNotice')}
        btnText={tn('general.ok')}
        btnAction={() => setShowMaxUserPopup(false)}
      />

      <Modal
        show={showTreeGraphModal}
        size={1200}
        closeOnBackdrop={true}
        closeOnEsc={true}
        showModalCloseIcon={true}
        onClose={() => setShowTreeGraphModal(false)}
      >
        <CustomerTreeGraph customer={customerData} parent={parentData} />
      </Modal>

      <NavigationPrompt when={formState.dirty}>
        {({ onConfirm, onCancel }) => renderConfirmLeaveModal(onConfirm, onCancel)}
      </NavigationPrompt>
    </>
  );
}

export default CustomerProfile;
