import PropTypes from 'prop-types';
import React from 'react';
import FloatyBox from 'react-floatybox';
import Box from './Box';
import Text from './Text';

const Info = ({ infoText, bubbleWidth, align, alignInner, side, flip }) => {
  // const [showInfo, setShowInfo] = useState(false);

  const infoBubble = () => {
    return (
      <Box background="white" width={bubbleWidth} paddingX={5} paddingY={4}>
        <Box direction="column" display="flex">
          <Text size="sm">{infoText}</Text>
        </Box>
      </Box>
    );
  };

  return (
    <FloatyBox
      bubble={infoBubble}
      open="hover"
      side={side}
      align={align}
      alignInner={alignInner}
      flip={flip}
      trap={true}
    >
      <Box
        display="inline-block"
        background="grey"
        borderRadius="50"
        width="20px"
        height="20px"
        cursor="pointer"
        marginLeft={3}
      >
        <Box display="flex" justify="center" alignItems="center" height="22px" cursor="pointer">
          <Text size="sm" color="white" bold inline>
            i
          </Text>
        </Box>
      </Box>
    </FloatyBox>
  );
};

Info.propTypes = {
  infoText: PropTypes.string,
  bubbleWidth: PropTypes.number,
  align: PropTypes.string,
  alignInner: PropTypes.string,
  side: PropTypes.string,
  flip: PropTypes.bool
};

Info.defaultProps = {
  infoText: '',
  align: 'left',
  alignInner: 'left',
  side: 'top',
  bubbleWidth: 250,
  flip: true
};

export default Info;
