/* eslint-disable */
// @TODO redo keys from loops
// /private/var/www/looxr-admin/src/components/SortTable.js
// 190:16  error  Do not use Array index in keys  react/no-array-index-key

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SORT_ASC,
  SORT_DESC,
  SORT_NUMBER,
  SORT_STRING,
  sortDefaultAsc,
  sortDefaultDesc,
  useTranslation
} from '@looxr/utils';
import DESC_ICON from '../assets/icons/sort-down.svg';
import ASC_ICON from '../assets/icons/sort-up.svg';
import Box from './Box';
import Checkbox from './Checkbox';
import SortTablePager from './SortTablePager';
import TableBox from './TableBox';
import Text from './Text';

const StyledSortTable = styled(Box)`
  table {
    width: 100%;

    th.sortable {
      cursor: pointer;
    }

    th.activesort {
      color: ${(props) => props.theme.colors.purple};
    }

    th img {
      display: inline-block;
      margin-left: 3px;
      position: relative;
      top: 1px;
    }
  }
`;

const StyledActionButton = styled(Box)`
  color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.purple)};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

function SortTable({
  tableKey, // table id
  hasPagination, // show pager
  hasRowHover, // show row hover if true
  pagerFixedAtBottom, // pager position fixed at bottom,
  pagerCondensed, // pager that only shows prev + next
  perPage, // show X per page => only when hasPagination = true
  columns, // column config
  saveState, // save the table sort and pager state
  noDataText, // no data text
  data, // the table rows,
  dataName, // data name to display inside pager
  defaulSortBy, // default sort by column key
  defaultSortOrder, // default sort order,
  extraData, // extra data to pass to sort table to mange updates
  contentHeight, // determine content height to be able to have pager fixed at bottom
  hasSelectableRows,
  selectedRows,
  resetPageOnDataUpdate,
  rowActions,
  onSelectAllChange, // all row selection has changed
  onRowSelectChange, // one row selection has changed
  onRowClick, // row click handler,
  onRowActionClick
}) {
  const tn = useTranslation();

  const [visibleData, setVisibleData] = useState([]);
  const [sortBy, setSortBy] = useState(defaulSortBy);
  const [sortOrder, setSortOrder] = useState(
    defaultSortOrder !== undefined ? defaultSortOrder : SORT_ASC
  );
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Effect to start at page 1 if the data or extra data change
   */
  useEffect(() => {
    if (resetPageOnDataUpdate) {
      setCurrentPage(1);
    }
  }, [resetPageOnDataUpdate, data]);

  /**
   * Effect to update visible data when table dependend data changes
   * => sort data
   * => page data if configured
   */
  useEffect(() => {
    const sortCol = columns.filter((col) => col.key === sortBy)[0];
    let visible = data;

    if (sortCol !== undefined) {
      visible = sortData(visible, sortCol, sortOrder);
    }

    if (hasPagination) {
      visible = pageData(visible, currentPage, perPage);
    }

    setVisibleData(visible);
  }, [data, columns, currentPage, perPage, sortOrder, sortBy, hasPagination, extraData]);

  /**
   * @TODO save state
   * on mount / unmount
   * => load / save state when configured
   */
  useEffect(() => {
    // check if saved state is available

    return () => {
      if (saveState) {
        // perform state save
      }
    };
  }, [saveState]);

  /**
   * sort the data based on given sort configuration
   */
  const sortData = (data, sortCol, sortOrder) => {
    const sorted = data;
    const sortProp = sortCol.sortValue;
    const sortType = sortCol.sortType;
    let sortFunc;

    switch (sortType) {
      case SORT_STRING:
      case SORT_NUMBER:
        sortFunc = sortOrder === SORT_DESC ? sortDefaultDesc : sortDefaultAsc;
        break;
      default:
        sortFunc = sortOrder === SORT_DESC ? sortDefaultDesc : sortDefaultAsc;
        break;
    }

    if (sorted.length > 0) {
      sorted.sort(sortFunc(sortProp));
    }

    return sorted;
  };

  /**
   * page data with given page configuration
   */
  const pageData = (_data, _currentPage, _perPage) => {
    return _data.length > 0
      ? _data.slice((_currentPage - 1) * _perPage, _currentPage * _perPage)
      : [];
  };

  /**
   * sort data by solumn and set new sort order if same column
   * @param {*} col
   */
  const sortByColumn = (col) => {
    if (col.sortable) {
      if (sortBy === col.key) {
        setSortOrder(sortOrder === SORT_DESC ? SORT_ASC : SORT_DESC);
      } else {
        setSortBy(col.key);
      }

      if (currentPage > 1) {
        setCurrentPage(1);
      }
    }
  };

  /**
   * render down or up sort icon arraw
   * @param {*} col
   */
  const renderSortOrderIcon = (col) => {
    let iconImage = null;
    if (sortBy === col.key) {
      const icon = sortOrder === SORT_DESC ? DESC_ICON : ASC_ICON;
      iconImage = <img src={icon} alt={sortOrder} />;
    }

    return iconImage;
  };

  /**
   * Handle table row click and progress with row data
   * @param {*} data
   */
  const handleRowClick = (data) => {
    if (onRowClick) {
      onRowClick(data);
    }
  };

  const allRowSelectChange = (state) => {
    let update = [...data];

    if (!state) {
      update = [];
    }

    setSelectedRows(update);
  };

  const selectRowChange = (state, dataRow) => {
    let update = [...selectedRows];
    if (state) {
      update.push(dataRow);
    } else {
      update = update.filter((row) => row.id !== dataRow.id);
    }

    setSelectedRows(update);
  };

  const isSelected = (dataRow) => {
    return selectedRows.filter((row) => row.id === dataRow.id).length > 0;
  };

  const hasSelectedAll = () => {
    return selectedRows.length === data.length;
  };

  /**
   * render the table rows based on column configuration
   */
  const renderRows = () => {
    return visibleData.map((dataRow, rowIndex) => {
      return (
        <tr key={`row-${tableKey}-${rowIndex}`} className={hasRowHover === true ? 'crrp' : ''}>
          {hasSelectableRows && (
            <td>
              <Box display="flex" justify="flex-start" alignItems="flex-start">
                <Checkbox
                  name={`row-${tableKey}-${rowIndex}`}
                  checked={isSelected(dataRow)}
                  onChange={(state) => onRowSelectChange({ checked: state, data: dataRow })}
                />
              </Box>
            </td>
          )}
          {columns.map((col, colIndex) => (
            <td
              key={`column-${tableKey}-${colIndex}`}
              className={col.className}
              style={col.columnStyle ? col.columnStyle : null}
              onClick={() => handleRowClick(dataRow)}
            >
              {col.outputValue(dataRow)}
            </td>
          ))}
          {rowActions.length > 0 && (
            <td className="no-hover">
              <Box
                display="flex"
                direction="row"
                justify="center"
                alginItems="center"
                alignContent="center"
              >
                {rowActions.map(({ key, icon, props }) => {
                  return (
                    <StyledActionButton
                      key={key}
                      {...props}
                      onClick={() => onRowActionClick({ key, data: dataRow })}
                    >
                      <FontAwesomeIcon icon={icon} size="lg" />
                    </StyledActionButton>
                  );
                })}
              </Box>
            </td>
          )}
        </tr>
      );
    });
  };

  /**
   * render no data text
   */
  const renderNoData = () => {
    let colSpan = columns.length;

    if (hasSelectableRows) {
      colSpan += 1;
    }

    if (rowActions.length > 0) {
      colSpan += 1;
    }
    return (
      <tr>
        <td className="center" colSpan={colSpan}>
          <Text align="center">{noDataText !== undefined ? noDataText : 'No Data'}</Text>
        </td>
      </tr>
    );
  };

  return (
    <TableBox display="flex" width="100%" hover={hasRowHover}>
      <StyledSortTable
        display="flex"
        width="100%"
        direction="column"
        alignItems="left"
        justify="flex-start"
        height={contentHeight !== null ? contentHeight : null}
      >
        <table>
          <thead>
            <tr>
              {hasSelectableRows && (
                <th key="header-checbox" width="60px">
                  <Box display="flex" justify="flex-start" alignItems="flex-start">
                    <Checkbox
                      name="head-row-checkbox"
                      checked={hasSelectedAll()}
                      onChange={(state) => onSelectAllChange({ checked: state })}
                    />
                  </Box>
                </th>
              )}
              {/* Header Column rendering */}
              {columns.map((col, i) => (
                <th
                  key={`header-${tableKey}-${col.key}`}
                  onClick={() => sortByColumn(col)}
                  style={col.headerStyle ? col.headerStyle : null}
                  className={`${col.classNameHeader ? col.classNameHeader : ''} ${
                    col.sortable ? 'sortable' : ''
                  } ${col.key === sortBy ? 'activesort' : ''}`}
                >
                  {col.label}
                  {renderSortOrderIcon(col)}
                </th>
              ))}
              {rowActions.length > 0 && (
                <th width={80 * rowActions.length} style={{ textAlign: 'center' }}>
                  {tn('general.actions')}
                </th>
              )}
            </tr>
          </thead>
          {/* Table Rows */}
          <tbody>{visibleData.length > 0 ? renderRows() : renderNoData()}</tbody>
        </table>

        {/* Pager */}
        {hasPagination ? (
          <SortTablePager
            data={data}
            onPageUpdate={(newPage) => setCurrentPage(newPage)}
            perPage={perPage}
            dataName={dataName}
            totalCount={data ? data.length : 0}
            condensed={pagerCondensed}
            currentPage={currentPage}
            fixedBottom={pagerFixedAtBottom}
            selectedRows={selectedRows}
          />
        ) : null}
      </StyledSortTable>
    </TableBox>
  );
}

SortTable.propTypes = {
  tableKey: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  dataName: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  noDataText: PropTypes.string,
  perPage: PropTypes.number,
  saveState: PropTypes.bool,
  hasPagination: PropTypes.bool,
  hasRowHover: PropTypes.bool,
  pagerFixedAtBottom: PropTypes.bool,
  pagerCondensed: PropTypes.bool,
  defaulSortBy: PropTypes.string,
  defaultSortOrder: PropTypes.string,
  onRowClick: PropTypes.func,
  extraData: PropTypes.any,
  contentHeight: PropTypes.number,
  hasSelectableRows: PropTypes.bool,
  resetPageOnDataUpdate: PropTypes.bool,
  onRowSelectChange: PropTypes.func,
  onRowActionClick: PropTypes.func,
  rowActions: PropTypes.array
};

SortTable.defaultProps = {
  noDataText: 'Keine Daten',
  perPage: 10,
  resetPageOnDataUpdate: true,
  saveState: false,
  hasPagination: true,
  hasRowHover: true,
  pagerFixedAtBottom: false,
  pagerCondensed: true,
  defaulSortBy: null,
  defaultSortOrder: SORT_ASC,
  extraData: [],
  selectedRows: [],
  contentHeight: null,
  hasSelectableRows: false,
  onRowSelectChange: () => {},
  onSelectAllChange: () => {},
  onRowClick: () => {},
  onRowActionClick: () => {},
  rowActions: []
};

export default SortTable;
