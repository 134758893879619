// PROD
export const FirebaseConfig = {
  apiKey: 'AIzaSyC6tWdD1UsEurf2x7LdgHkTc1ZJJUnZquA',
  authDomain: 'looxr-8fcc8.firebaseapp.com',
  projectId: 'looxr-8fcc8',
  storageBucket: 'looxr-8fcc8.appspot.com',
  messagingSenderId: '666280733287',
  appId: '1:666280733287:web:29600ab92eb3c187d402c2'
  // databaseURL: 'https://looxr-dev1.firebaseio.com',
};

/*
DEV / TEST
export const FirebaseConfig = {
  apiKey: 'AIzaSyAkkucMprWo_wbg1UPvrH_60erSOYR4aEs',
  authDomain: 'looxr-dev1.firebaseapp.com',
  databaseURL: 'https://looxr-dev1.firebaseio.com',
  projectId: 'looxr-dev1',
  storageBucket: 'looxr-dev1.appspot.com',
  messagingSenderId: '1075312732036',
  appId: '1:1075312732036:web:b6a2aac2590957c7749fd1',
  measurementId: 'G-RPG8V1JWDF'
};
*/

export const Collections = {
  user: 'user',
  customer: 'customer',
  package: 'package',
  summaryBox: 'summaryBox',
  leakLocationSummary: 'leakLocationSummary',
  leak: 'leak',
  component: 'leakComponent',
  qrcode: 'leakQrcode',
  leakPDF: 'leakPDF',
  leakComponentExport: 'leakComponentExport'
};

export const LOOXR_FIREBASE_ID = 'L0sVwRFE4p2C9W4rsFQ5';
export const LOOXR_DUMMY_COMPONENT_ID = '4SuiM5eTpiZWoBux8ZLC';
