import { useEffect } from 'react';
import Translate from './Translate';

function useLanguageChanged(handler) {
  useEffect(() => {
    const sub = Translate.registerLanguageChangeListener((newLangKey) => {
      handler(newLangKey);
    });

    return () => sub.remove();
  }, [handler]);
}

export default useLanguageChanged;
