import i18n from 'i18n-js';
import de from '../assets/de.json';
import en from '../assets/en.json';

i18n.languageChangeListener = {};

i18n.registerLanguageChangeListener = (handler) => {
  const id = `_${Math.random().toString(36).substr(2, 9)}`;
  const listener = {
    id,
    handler,
    remove: () => {
      delete i18n.languageChangeListener[id];
    }
  };

  i18n.languageChangeListener[id] = listener;

  return listener;
};

i18n.emitLanguageChange = (key) => {
  const listenerIDs = Object.keys(i18n.languageChangeListener);

  for (let i = 0; i < listenerIDs.length; i += 1) {
    const listenerID = listenerIDs[i];
    const listener = i18n.languageChangeListener[listenerID];

    listener.handler(key);
  }
};

i18n.changeLanguage = (key) => {
  i18n.locale = key;
  i18n.emitLanguageChange(key);
};

i18n.tn = (transKey, params) => {
  const trans = i18n.t(transKey, params);
  if (trans !== undefined && typeof trans === 'string' && trans.indexOf('@:') >= 0) {
    const lookupTransKey = trans.replace('@:', '');
    return i18n.t(lookupTransKey, params);
  }

  return trans;
};

i18n.setLocaleBasedOnBrowser = () => {
  const lang = navigator.language || navigator.userLanguage;
  let langKey = lang.split('-')[0];

  // we only support de => de
  // all others will result in xx => en
  if (lang.indexOf('de') >= 0) {
    langKey = 'de';
  } else {
    langKey = 'en';
  }

  i18n.locale = langKey;
};

i18n.init = () => {
  // Boostrap i18n language
  i18n.fallbacks = true;
  i18n.translations = { en, de };

  i18n.setLocaleBasedOnBrowser('en');

  return true;
};

export default i18n;
