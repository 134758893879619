import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import styled from 'styled-components';
import {
  Alert,
  Banner,
  Box,
  Button,
  ContentBox,
  LoadingBox,
  Modal,
  Popup,
  TeaserBox,
  Text
} from '@looxr/components';
import { Collections, LOOXR_FIREBASE_ID, ROLES } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { getUserDefault } from '../../constants';
import { useLoadCustomers, useLoadUsers, usePackages } from '../../hooks';
import { ApiService, AppStateService, FirebaseService, UserService } from '../../services';
import LoginBox from './LoginBox';
import LoginForm from './LoginForm';
import ManageableUsers from './ManageableUsers';
import UserFormGeneral from './UserFormGeneral';
import UserFormStatus from './UserFormStatus';

const StyledFormButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  button:first-child {
    margin-right: 20px;
  }
`;

function UserForm() {
  const tn = useTranslation();

  const { register, handleSubmit, errors, reset, formState } = useForm({
    defaultValues: getUserDefault()
  });

  const { id } = useParams();
  const { getUserById, getUsersByCustomer } = useLoadUsers();
  const { getCustomerById } = useLoadCustomers();
  const { getPackagesOfCustomer } = usePackages();

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(true);
  const [nonFormDataChanged, setNonFormDataChanged] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showArchivePopup, setShowArchivePopup] = useState(false);
  const [showActivatePopup, setShowActivatePopup] = useState(false);

  const [modalFormType, setModalFormType] = useState(null);
  const [modalFormPackages, setModalFormPackages] = useState([]);

  const [canEditLoginsAndManageable, setCanEditLoginsAndManageable] = useState(false);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [showMaxUserPopup, setShowMaxUserPopup] = useState(false);

  const [userRef, setUserRef] = useState({});
  const [userData, setUserData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [customerPackages, setCustomerPackages] = useState([]);

  const [manageableCustomers, setManageableCustomers] = useState([]);

  const [activeUserOfCustomerCount, setActiveUserOfCustomerCount] = useState(0);
  const [maxUserOfCustomerCount, setMaxUserOfCustomerCount] = useState(0);

  // Effect to load initial data
  useEffect(() => {
    const load = async () => {
      setLoading(true);

      let userDoc = null;
      let userData = getUserDefault();
      let customerDoc = null;
      let customerDocData = null;
      let create = false;
      let manageable = [];
      let customerPackages = [];
      let editLoginsAndManageable = false;

      if (id !== 'new') {
        userDoc = await getUserById(id);

        if (userDoc && userDoc.exists) {
          userData = userDoc.data();
          customerDoc = await userData.customer.get();

          // load manageable customers
          if (userData.manageableCustomers.refs.length > 0) {
            const customerLoads = [];
            for (let i = 0; i < userData.manageableCustomers.refs.length; i += 1) {
              customerLoads.push(userData.manageableCustomers.refs[i].get());
            }

            manageable = await Promise.all(customerLoads);
            manageable = manageable.map((doc) => {
              return {
                id: doc.id,
                ref: doc.ref,
                ...doc.data()
              };
            });
          }
        } else {
          setErrorMessage(tn('admin.page.userForm.notFoundNotice')); // 'Es konnte kein Benutzer mit dieser ID geladen werden'
          setShowErrorPopup(true);
          // @TODO manage redirect back to list
        }
      } else {
        create = true;

        const searchString = location.search ? location.search.replace('?', '') : null;
        if (searchString) {
          const searchParams = new URLSearchParams(searchString);
          // fetch customer by given id for reference of the new user ...
          if (searchParams.has('c')) {
            customerDoc = await getCustomerById(searchParams.get('c'));
          }
        } else {
          // ... or use the current users related customer for reference
          customerDoc = await AppStateService.user.customer.get();
        }
      }

      if (customerDoc) {
        customerPackages = await getPackagesOfCustomer(customerDoc.ref);
        editLoginsAndManageable = UserService.checkIfCanEditLogins(customerDoc.id);
        customerDocData = {
          id: customerDoc.id,
          ref: customerDoc.ref,
          ...customerDoc.data()
        };
      }

      if (customerDoc.id === LOOXR_FIREBASE_ID) {
        userData.role = ROLES.looxr;
      }

      // check max user <> active user of customer first thing
      // showUserNotice=true
      let maxUserCount = 0;
      let activeUserCount = 0;

      const userOfCustomerSnapshot = await getUsersByCustomer(customerDoc.ref);
      userOfCustomerSnapshot.forEach((doc) => {
        const docData = doc.data();
        if (!docData.archived === true) {
          activeUserCount += 1;
        }
      });

      if (customerPackages.length > 0) {
        maxUserCount = customerPackages
          .map((p) => (p.active ? p.maxAccounts : 0))
          .reduce((p, n) => p + n);
      }

      if (create) {
        if (customerDoc.id !== LOOXR_FIREBASE_ID) {
          if (activeUserCount >= maxUserCount) {
            let redirectUrl = `/customer/${customerDoc.id}?showUserNotice=true`;

            if (AppStateService.isCustomer()) {
              redirectUrl = '/';
            }

            // could be customer profile when its not looxr/mader/oem
            // could be no access to this customer maybe
            history.push(`${redirectUrl}?showUserNotice=true`);
          }
        }
      }

      setIsCreate(create);

      setUserRef(userDoc);
      setUserData(userData);

      setCustomerData(customerDocData);
      setCustomerPackages(customerPackages);
      setManageableCustomers(manageable);

      setCanEditLoginsAndManageable(editLoginsAndManageable);

      setActiveUserOfCustomerCount(activeUserCount);
      setMaxUserOfCustomerCount(maxUserCount);

      // reset used to init form with loaded data
      reset({ ...userData });

      setLoading(false);
    };

    load();
  }, [id]);

  /**
   * Save user data to firebase firestore
   * @param {*} data
   */
  const save = async (data) => {
    // form submit if RHF is called when required is valid it seems
    // username duplicate is not a required field but optional with error
    const hasErrors = Object.keys(errors).length > 0;

    if (!hasErrors) {
      setLoading(true);
      const formData = { ...data, manageableCustomers };

      let update = UserService.processFormData(isCreate, userData, formData, customerData);
      const id = UserService.getId(isCreate, userRef, update);

      try {
        if (isCreate) {
          update = await UserService.createOneloginUser(update);
          ApiService.sendInviteLink(update.email);
        }

        await FirebaseService.upsertDoc(id, update, Collections.user);

        reset({ ...update });
        setUserData(update);
        setNonFormDataChanged(false);
        setLoading(false);
        history.push(`/user/${id}`);
      } catch (e) {
        console.log('Error saving user => ', e);
        setErrorMessage(tn('admin.page.userForm.errorOnSaveNotice'));
        setLoading(false);
        setShowErrorPopup(true);
      }
    }
  };

  /**
   * Archive the user
   * - unlicense onelogin user
   * - disable firebase user
   * - renew firebase auth token for current session
   * - user.archived = true
   */
  const archive = async () => {
    setShowArchivePopup(false);
    setLoading(true);

    const update = { ...userData };

    try {
      // update user with archived flag
      update.archived = true;
      update.archivedAt = FirebaseService.getTimestamp();

      // remove packges and logins from user
      update.logins.pressure = false;
      update.logins.leak = false;

      update.leakLogin.package = null;
      update.pressureLogin.package = null;

      await FirebaseService.upsertDoc(id, update, Collections.user);

      // unlicense the onelogin user
      await ApiService.unlicenseUser({ email: update.email, oneloginID: update.oneloginID });

      setUserData(update);
      setLoading(false);
      history.push(`/user/${id}`);
    } catch (e) {
      console.log('Error saving user => ', e);
      setErrorMessage(tn('admin.page.userForm.errorOnSaveNotice'));
      setLoading(false);
      setShowErrorPopup(true);
    }
  };

  /**
   * Activate and license the user
   */
  const activate = async () => {
    setShowActivatePopup(false);
    console.log('active user => ', activeUserOfCustomerCount);
    console.log('max user count => ', maxUserOfCustomerCount);

    if (activeUserOfCustomerCount >= maxUserOfCustomerCount) {
      setShowMaxUserPopup(true);
    } else {
      setLoading(true);

      const update = { ...userData };

      try {
        update.archived = false;
        update.archivedAt = null;

        await FirebaseService.upsertDoc(id, update, Collections.user);

        // license user and activate on firebase auth
        await ApiService.licenseUser({ email: update.email, oneloginID: update.oneloginID });

        setUserData(update);
        setLoading(false);
      } catch (e) {
        console.log('Error saving user => ', e);
        setErrorMessage(tn('admin.page.userForm.errorOnSaveNotice'));
        setLoading(false);
        setShowErrorPopup(true);
      }
    }
  };

  /**
   * Render confirm leave dialog
   * @param {*} onConfirm
   * @param {*} onCancel
   */
  const renderConfirmLeaveModal = (onConfirm, onCancel) => {
    return (
      <Popup
        show
        size={400}
        title={tn('general.popup.unsaved.title')} // "Ungespeicherte Daten"
        message={tn('general.popup.unsaved.text')} // "Möchten Sie die ungeänderten Daten verwerfen"
        confirmColor="red"
        confirmText={tn('general.popup.unsaved.buttonYes')} // "Ja"
        confirmAction={onConfirm}
        abortAction={onCancel}
        abortText={tn('general.popup.unsaved.buttonNo')} // "Nein"
        abortColor="green"
      />
    );
  };

  /**
   * Cancel edit of user and return to list
   */
  const cancel = () => {
    history.push('/user/list');
  };

  /**
   * remove manageable customer from user
   * @param {*} customerDoc
   */
  const removeManageable = (customerDoc) => {
    const update = manageableCustomers.filter((customer) => customer.id !== customerDoc.id);
    setManageableCustomers(update);
  };

  /**
   * add manageable customer to user
   * @param {*} customerDoc
   */
  const addManageable = (customerDoc) => {
    const update = [...manageableCustomers];
    update.push(customerDoc);
    setManageableCustomers(update);
  };

  /**
   * Open modal to create a "login" for the user based on a package of the customer
   * @param {*} type
   * @param {*} selectablePackages
   */
  const openLoginModal = (type, selectablePackages) => {
    setModalFormPackages(selectablePackages);
    setModalFormType(type);
    setShowLoginModal(true);
  };

  /**
   * Check if current user has permission to archive
   */
  const canArchive = () => {
    let hasPermission = false;

    // LOOXR or Users with Admin Role have permission to archive
    if (AppStateService.isLOOXR() || AppStateService.user.role === ROLES.admin) {
      hasPermission = true;
    }

    // Not allowed to archive yourself
    if (id === AppStateService.user.id) {
      hasPermission = false;
    }

    return hasPermission;
  };

  return (
    <LoadingBox
      loading={loading}
      renderChildren={!loading}
      minHeight="calc(100vh - 143px)"
      height="100%"
      width="100%"
    >
      <>
        {userData && (
          <>
            <Banner
              // text={`Benutzer ${isCreate ? 'anlegen' : 'bearbeiten'}`}
              text={
                isCreate
                  ? tn('admin.page.userForm.title.add')
                  : tn('admin.page.userForm.title.edit')
              }
              subtext={isCreate ? `${userData.firstname} ${userData.lastname}` : ''}
            />

            <Box paddingX={10} paddingY={10}>
              {userData.archived && (
                <TeaserBox>
                  <Text size="sm" bold color="purple">
                    {tn('admin.page.userForm.archivedNotice')}
                    {/* Dieser Kunde ist archiviert */}
                  </Text>
                </TeaserBox>
              )}

              <form onSubmit={handleSubmit(save)} noValidate>
                <ContentBox
                  title={tn('admin.page.userForm.general.title')} // "Allgemein"
                >
                  <UserFormGeneral
                    register={register}
                    errors={errors}
                    userRef={userRef}
                    customerData={customerData}
                    isCreate={isCreate}
                    isArchived={userData.archived}
                  />
                </ContentBox>

                {!userData.archived && !isCreate && canEditLoginsAndManageable && (
                  <ContentBox
                    title={tn('admin.page.userForm.status.title')} // "Status"
                    marginTop={10}
                  >
                    <UserFormStatus register={register} userData={userData} />
                  </ContentBox>
                )}

                {!userData.archived &&
                  canEditLoginsAndManageable &&
                  userData.role !== ROLES.looxr && (
                    <Box display="flex" direction="row">
                      <LoginBox
                        type="leak"
                        userData={userData}
                        customerPackages={customerPackages}
                        onCreate={(selectablePackages) =>
                          openLoginModal('leak', selectablePackages)
                        }
                        onEdit={(selectablePackages) => openLoginModal('leak', selectablePackages)}
                        onDelete={(update) => {
                          setUserData(update);
                          setNonFormDataChanged(true);
                        }}
                      />
                      <LoginBox
                        type="pressure"
                        userData={userData}
                        customerPackages={customerPackages}
                        onCreate={(selectablePackages) =>
                          openLoginModal('pressure', selectablePackages)
                        }
                        onEdit={(selectablePackages) =>
                          openLoginModal('pressure', selectablePackages)
                        }
                        onDelete={(update) => {
                          setUserData(update);
                          setNonFormDataChanged(true);
                        }}
                      />
                    </Box>
                  )}

                {!userData.archived &&
                  canEditLoginsAndManageable &&
                  userData.role !== ROLES.looxr && (
                    <ManageableUsers
                      customerData={customerData}
                      manageableCustomers={manageableCustomers}
                      onAdd={(customerDoc) => {
                        setNonFormDataChanged(true);
                        addManageable(customerDoc);
                      }}
                      onRemove={(customerDoc) => {
                        setNonFormDataChanged(true);
                        removeManageable(customerDoc);
                      }}
                    />
                  )}
                <Box display="flex" width="100%">
                  <StyledFormButtonBox paddingX={0} paddingY={5} width={400} marginTop={10}>
                    <Button
                      disabled={userData.archived}
                      text={tn('general.save')} // "Speichern"
                      type="submit"
                    />
                    <Button
                      text={userData.archived ? tn('general.back') : tn('general.cancel')} // "Abbrechen"
                      background="red"
                      onClick={() => cancel()}
                    />
                  </StyledFormButtonBox>
                  {!isCreate && (
                    <Box
                      display="flex"
                      flex="1"
                      alignContent="flex-end"
                      justify="flex-end"
                      alignItems="flex-end"
                      paddingX={0}
                      paddingY={5}
                      marginTop={10}
                    >
                      {userData.archived && canArchive() && (
                        <Button
                          width={150}
                          text={tn('general.activate')} // "Aktivieren"
                          background="blue"
                          onClick={() => setShowActivatePopup(true)}
                        />
                      )}

                      {!userData.archived && canArchive() && (
                        <Button
                          width={150}
                          text={tn('general.archive')} // "Archivieren"
                          background="purple"
                          onClick={() => setShowArchivePopup(true)}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </form>

              <Modal
                show={showLoginModal}
                size={600}
                closeOnBackdrop={false}
                closeOnEsc={false}
                showModalCloseIcon={false}
                onClose={() => setShowLoginModal(false)}
              >
                <LoginForm
                  type={modalFormType}
                  customerPackages={customerPackages}
                  selectablePackages={modalFormPackages}
                  userData={userData}
                  onSave={(data) => {
                    setUserData(data);
                    setNonFormDataChanged(true);
                    setShowLoginModal(false);
                  }}
                  onCancel={() => setShowLoginModal(false)}
                />
              </Modal>

              <NavigationPrompt when={formState.dirty || nonFormDataChanged}>
                {({ onConfirm, onCancel }) => renderConfirmLeaveModal(onConfirm, onCancel)}
              </NavigationPrompt>

              <Alert
                show={showErrorPopup}
                title={tn('general.error')}
                message={errorMessage}
                btnText={tn('general.ok')}
                btnAction={() => setShowErrorPopup(false)}
              />

              <Alert
                show={showMaxUserPopup}
                title={tn('general.notice')}
                message={tn('admin.page.customerForm.maxUserNotice')}
                btnText={tn('general.ok')}
                btnAction={() => setShowMaxUserPopup(false)}
              />

              <Popup
                show={showArchivePopup}
                title={tn('admin.popup.archiveUser.title')}
                message={tn('admin.popup.archiveUser.text')}
                confirmText={tn('admin.popup.archiveUser.buttonYes')}
                abortText={tn('admin.popup.archiveUser.buttonNo')}
                confirmColor="green"
                abortColor="red"
                confirmAction={() => archive()}
                abortAction={() => setShowArchivePopup(false)}
              />

              <Popup
                show={showActivatePopup}
                title={tn('admin.popup.activateUser.title')}
                message={tn('admin.popup.activateUser.text')}
                confirmText={tn('admin.popup.activateUser.buttonYes')}
                abortText={tn('admin.popup.activateUser.buttonNo')}
                confirmColor="green"
                abortColor="red"
                confirmAction={() => activate()}
                abortAction={() => setShowActivatePopup(false)}
              />
            </Box>
          </>
        )}
      </>
    </LoadingBox>
  );
}

export default UserForm;
