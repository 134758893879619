export const extractIdsFromChildMap = childMap => {
  let extracted = [];
  if (childMap) {
    const ids = Object.keys(childMap);

    for (let i = 0; i < ids.length; i += 1) {
      const tree = ids[i];

      // push tree id itself
      extracted.push(tree);

      // push tree leafs if it has
      extracted = [...extracted, ...extractIdsFromChildMap(childMap[tree])];
    }
  }

  return extracted;
};

export const isInChildMap = (id, srcChildMap) => {
  const ids = extractIdsFromChildMap(srcChildMap);
  return ids.includes(id);
};
