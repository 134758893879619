import { LOOXR_FIREBASE_ID, ROLES } from '@looxr/constants';
import { MD5 } from '@looxr/utils';
import { isInChildMap } from '../utils';
import ApiService from './ApiService';
import AppStateService from './AppStateService';
import FirebaseService from './FirebaseService';

const UserService = {
  /**
   * Get ID (email) from userRef or userData
   * @param {*} isCreate
   * @param {*} userRef
   * @param {*} userData
   */
  getId(isCreate, userRef, userData) {
    let id = null;

    if (!isCreate) {
      id = userRef.id;
    } else {
      // use lower case here too ... just in case
      id = MD5(userData.email.toLowerCase());
    }

    return id;
  },

  /**
   * Check if current user can edit the logins of other users
   * @param {*} id
   */
  checkIfCanEditLogins(customerID) {
    const authCustomer = AppStateService.customer;
    const authUser = AppStateService.user;

    // is looxr
    if (authCustomer.id === LOOXR_FIREBASE_ID) {
      return true;
    }

    // is same customer
    if (authCustomer.id === customerID) {
      return true;
    }

    // is in manageable list of user or is a child of the users customer
    const manageable =
      authUser.manageableCustomers !== undefined ? authUser.manageableCustomers.ids : [];
    return isInChildMap(customerID, authCustomer.childMap) || manageable.includes(customerID);
  },

  /**
   * Handle the form data and update the userDoc object
   * @param {*} isCreate
   * @param {*} userData
   * @param {*} formData
   * @param {*} customerData
   */
  processFormData(isCreate, userData, formData, customerData) {
    const update = { ...userData, ...formData };
    const { manageableCustomers } = formData;

    // always make email lower case
    update.email = update.email.toLowerCase();

    // split up manageable customers into id and ref for better access
    if (manageableCustomers.length > 0) {
      update.manageableCustomers = {};
      update.manageableCustomers.ids = manageableCustomers.map((customerDoc) => customerDoc.id);
      update.manageableCustomers.refs = manageableCustomers.map((customerDoc) => customerDoc.ref);
    } else {
      update.manageableCustomers = {};
      update.manageableCustomers.ids = [];
      update.manageableCustomers.refs = [];
    }

    if (isCreate) {
      update.createdAt = FirebaseService.getTimestamp();
      update.createdBy = AppStateService.user.ref;

      update.customer = customerData.ref;
      update.customerID = customerData.id;
    } else {
      update.updatedAt = FirebaseService.getTimestamp();
      update.updatedBy = AppStateService.user.ref;
    }

    if (customerData.id === LOOXR_FIREBASE_ID) {
      update.role = ROLES.looxr;
    }

    return update;
  },

  /**
   * Create onelogin user update the user doc and return it
   * @param {*} userDoc
   */
  async createOneloginUser(userDoc) {
    const response = await ApiService.createOneloginUser(userDoc);
    const created = { ...userDoc };
    if (response.error) {
      throw response;
    } else {
      created.oneloginID = response.id;
      return created;
    }
  }
};

export default UserService;
