import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Text from './Text';

const StyledLabel = styled.label`
  display: ${props => props.display};
  color: ${props =>
    props.valid === false ? props.theme.colors.red : props.theme.colors[props.color]};
  font-size: 0.875rem;
  font-weight: ${props => (props.regular ? 300 : 600)};
  margin-bottom: ${props => (props.noMarginBottom ? '0px' : '3px')};
  line-height: 27px;
  height: 27px;
  ${props =>
    props.valid === false &&
    css`
      & > * {
        color: ${props => props.theme.colors.red};
      }
    `}
`;

function Label({ required, valid, children, htmlFor, display, regular, noMarginBottom, color }) {
  return (
    <StyledLabel
      color={color}
      display={display}
      htmlFor={htmlFor}
      regular={regular}
      required={required}
      valid={valid}
      noMarginBottom={noMarginBottom}
    >
      {typeof children === 'string' ? (
        <Text size="sm" inline color={color}>
          {children}
        </Text>
      ) : (
        children
      )}
      {required ? (
        <Text size="sm" color="green" inline marginLeft={1}>
          *
        </Text>
      ) : null}
    </StyledLabel>
  );
}

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  htmlFor: PropTypes.string.isRequired,
  display: PropTypes.string,
  regular: PropTypes.bool,
  required: PropTypes.bool,
  valid: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
  color: PropTypes.string
};

Label.defaultProps = {
  display: 'block',
  regular: false,
  color: 'black2',
  noMarginBottom: false,
  required: false,
  valid: null
};

export default Label;
