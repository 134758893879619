import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  faEnvelope as MailIcon,
  faMobileAlt as PhoneIcon,
  faUser as UserIcon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@looxr/utils';
import GriesingerImage from '../assets/griesinger_new.png';
import Box from './Box';
import LoadingBox from './LoadingBox';
import Modal from './Modal';
import Text from './Text';

const StyledAvatar = styled(Box)`
  img {
    width: 150px;
    display: block;
  }
`;

const StyledAvatarImage = styled(Box)`
  width: 120px;
  height: 120px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: 100%;
  }
`;

const StyledUserIconPlaceholder = styled(Box)`
  width: 120px;
  height: 120px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 60px;
  background-color: ${(props) => props.theme.colors.grey};
  color: ${(props) => props.theme.colors.white};
`;

const StyledInfoHolder = styled(Box)`
  svg {
    fill: ${(props) => props.theme.colors.purple};

    * {
      fill: ${(props) => props.theme.colors.purple};
    }
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.purple};
    & > * {
      color: ${(props) => props.theme.colors.purple};
    }
  }
`;

function HelpModalTree({ isVisible, onClose, looxrID, authCustomer }) {
  const tn = useTranslation();

  const [loading, setLoading] = useState(false);
  const [firstLevelContact, setFirstLevelContact] = useState(null);
  const [secondLevelContact, setSecondLevelContact] = useState(null);

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const parentCustomer = authCustomer.parent;

      if (authCustomer && authCustomer.id !== looxrID) {
        setFirstLevelContact(authCustomer.contact);

        if (parentCustomer && parentCustomer.id !== looxrID) {
          const parentCustomerDoc = await parentCustomer.get();
          const parentCustomerData = parentCustomerDoc.data();
          setSecondLevelContact(parentCustomerData.contact);
        }
      }

      setLoading(false);
    };

    if (isVisible) {
      load();
    }
  }, [isVisible]);

  const canShowContact = (type) => {
    let canShow = false;
    let sourceContact = null;

    if (type === 'first') {
      sourceContact = firstLevelContact;
    } else if (type === 'second') {
      sourceContact = secondLevelContact;
    }

    // contact needs at least
    // name + email OR phone
    if (
      sourceContact !== null &&
      sourceContact.name &&
      (sourceContact.email || sourceContact.phone)
    ) {
      canShow = true;
    }

    return canShow;
  };

  return (
    <Modal
      size={900}
      show={isVisible}
      onClose={onClose}
      closeOnBackdrop={true}
      closeOnEsc={true}
      showModalCloseIcon={true}
    >
      <LoadingBox
        loading={loading}
        renderChildren={!loading}
        display="flex"
        alignItems="center"
        direction="column"
        paddingY={8}
        paddingX={8}
      >
        <Box>
          <Text color="purple" size="xl">
            {tn('general.helpOverlay.needHelp')}
            {/* Benötigen Sie Unterstützung? */}
          </Text>
        </Box>
        <Box>
          <Text size="sm">
            {tn('general.helpOverlay.weAnswerYourQuestions')}
            {/* Gerne beantworten wir Ihre Fragen */}
          </Text>
        </Box>

        <Box
          display="flex"
          width="100%"
          alignItems="flex-start"
          justify="space-evenly"
          direction="row"
          marginTop={12}
        >
          <StyledAvatar display="flex" alignItems="center" direction="column">
            <StyledAvatarImage>
              <img src={GriesingerImage} alt="Marina Griesinger" />
            </StyledAvatarImage>
            <Box marginTop={4}>
              <Text color="grey">LOOXR</Text>
            </Box>
            <Box marginTop={2}>
              <Text color="black" bold>
                Marina Griesinger
              </Text>
            </Box>
            <StyledInfoHolder display="flex" direction="row" justify="center" alignItems="center">
              <FontAwesomeIcon icon={PhoneIcon} />
              <Box marginLeft={4}>
                <Text>+49 711 - 7972 118</Text>
              </Box>
            </StyledInfoHolder>
            <StyledInfoHolder display="flex" direction="row" justify="center" alignItems="center">
              <FontAwesomeIcon icon={MailIcon} />
              <Box marginLeft={4}>
                <StyledLink href="mailto:marina.griesinger@looxr.de">
                  <Text>marina.griesinger@looxr.de</Text>
                </StyledLink>
              </Box>
            </StyledInfoHolder>
          </StyledAvatar>

          {canShowContact('second') && (
            <StyledAvatar display="flex" alignItems="center" direction="column">
              {secondLevelContact.image ? (
                <StyledAvatarImage>
                  <img src={secondLevelContact.image} alt={secondLevelContact.name} />
                </StyledAvatarImage>
              ) : (
                <StyledUserIconPlaceholder>
                  <FontAwesomeIcon icon={UserIcon} />
                </StyledUserIconPlaceholder>
              )}

              <Box marginTop={4}>
                <Text color="grey">
                  {tn('general.helpOverlay.servicePartner')}
                  {/* Vertriebspartner */}
                </Text>
              </Box>
              <Box marginTop={2}>
                <Text color="black" bold>
                  {secondLevelContact.name}
                </Text>
              </Box>
              {secondLevelContact.phone && (
                <StyledInfoHolder
                  display="flex"
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <FontAwesomeIcon icon={PhoneIcon} />
                  <Box marginLeft={4}>
                    <Text>{secondLevelContact.phone}</Text>
                  </Box>
                </StyledInfoHolder>
              )}
              {secondLevelContact.email && (
                <StyledInfoHolder
                  display="flex"
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <FontAwesomeIcon icon={MailIcon} />
                  <Box marginLeft={4}>
                    <StyledLink href={`mailto:${secondLevelContact.email}`}>
                      <Text>{secondLevelContact.email}</Text>
                    </StyledLink>
                  </Box>
                </StyledInfoHolder>
              )}
            </StyledAvatar>
          )}

          {canShowContact('first') && (
            <StyledAvatar display="flex" alignItems="center" direction="column">
              {firstLevelContact.image ? (
                <StyledAvatarImage>
                  <img src={firstLevelContact.image} alt={firstLevelContact.name} />
                </StyledAvatarImage>
              ) : (
                <StyledUserIconPlaceholder>
                  <FontAwesomeIcon icon={UserIcon} />
                </StyledUserIconPlaceholder>
              )}

              <Box marginTop={4}>
                <Text color="grey">
                  {tn('general.helpOverlay.technicalSupport')}
                  {/* Technischer Support */}
                </Text>
              </Box>
              <Box marginTop={2}>
                <Text color="black" bold>
                  {firstLevelContact.name}
                </Text>
              </Box>
              {firstLevelContact.phone && (
                <StyledInfoHolder
                  display="flex"
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <FontAwesomeIcon icon={PhoneIcon} />
                  <Box marginLeft={4}>
                    <Text>{firstLevelContact.phone}</Text>
                  </Box>
                </StyledInfoHolder>
              )}
              {firstLevelContact.email && (
                <StyledInfoHolder
                  display="flex"
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <FontAwesomeIcon icon={MailIcon} />
                  <Box marginLeft={4}>
                    <StyledLink href={`mailto:${firstLevelContact.email}`}>
                      <Text>{firstLevelContact.email}</Text>
                    </StyledLink>
                  </Box>
                </StyledInfoHolder>
              )}
            </StyledAvatar>
          )}
        </Box>
      </LoadingBox>
    </Modal>
  );
}

HelpModalTree.propTypes = {
  authCustomer: PropTypes.any.isRequired,
  looxrID: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

HelpModalTree.defaultProps = {
  onClose: () => {}
};

export default HelpModalTree;
