import { AUTH_API_URL } from '@looxr/constants';

const ONELOGIN_PART = '/api/onelogin/user';
const USERMANAGEMENT_PART = '/api/user-management';
const FILES_PART = '/api/files';

const ApiService = {
  /**
   * Check if mail is already in use
   * @return {object}
   */
  checkIfEmailIsUnique: async (mail) => {
    const url = `${AUTH_API_URL}${USERMANAGEMENT_PART}/check-mail`;
    const body = {
      email: mail
    };

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    const check = await response.json();

    return check.answer;
  },

  /**
   * Check if username is already in use
   * @return {object}
   */
  checkIfUsernameIsUnique: async (username, isCreate, userRef) => {
    const url = `${AUTH_API_URL}${USERMANAGEMENT_PART}/check-username`;
    const body = {
      username,
      create: isCreate,
      id: userRef !== null ? userRef.id : null
    };

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    const check = await response.json();

    return check.answer;
  },

  /**
   * Check if customer name is already in use
   * @return {object}
   */
  checkIfCustomerNameIsUnique: async (name, isCreate, customerRef) => {
    const url = `${AUTH_API_URL}${USERMANAGEMENT_PART}/check-customer-name`;
    const body = {
      name,
      create: isCreate,
      id: customerRef !== null ? customerRef.id : null
    };

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    const check = await response.json();

    return check.answer;
  },

  /**
   * Change Password of current session user
   * @return {object}
   */
  changePassword: async (newPassword) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}/update-password`;
    const body = {
      password: newPassword
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        const jsonResponse = await response.json();

        return jsonResponse;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  /**
   * Create onelogin user
   * @return {object}
   */
  createOneloginUser: async (doc) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}`;
    const body = {
      email: doc.email,
      firstname: doc.firstname,
      lastname: doc.lastname,
      username: doc.username,
      custom_attributes: {
        mappedEmail: doc.email
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    return response.json();
  },

  /**
   * Update onelogin user
   * @return {object}
   */
  updateOneloginUser: async (id, doc) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}/${id}`;
    const body = {
      email: doc.email,
      firstname: doc.firstname,
      lastname: doc.lastname,
      username: doc.username,
      custom_attributes: {
        mappedEmail: doc.email
      }
    };

    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    return response.json();
  },

  /**
   * Delete onelogin user
   * @return {object}
   */
  deleteOneloginUser: async (id) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}/${id}`;
    const response = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.json();
  },

  /**
   * Add roles to onelogin user
   * @return {object}
   */
  addRolesToOneloginUser: async (id, roles) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}/update-roles/${id}`;
    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ role_id_array: roles })
    });

    return response.json();
  },

  /**
   * Remove roles to onelogin user
   * @return {object}
   */
  removeRolesToOneloginUser: async (id, roles) => {
    const url = `${AUTH_API_URL}${ONELOGIN_PART}/remove-roles/${id}`;
    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ role_id_array: roles })
    });

    return response.json();
  },

  /**
   * Unlicnese onelogin user
   * @return {object}
   */
  unlicenseUser: async ({ email, oneloginID }) => {
    try {
      const url = `${AUTH_API_URL}${ONELOGIN_PART}/unlicense`;
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, id: oneloginID })
      });

      return response.json();
    } catch (e) {
      return false;
    }
  },

  /**
   * License / Activate user in onelogin and firebase
   *
   * @param {email: string, oneloginID: string}
   * @returns {object}
   */
  licenseUser: async ({ email, oneloginID }) => {
    try {
      const url = `${AUTH_API_URL}${ONELOGIN_PART}/license`;
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, id: oneloginID })
      });

      return response.json();
    } catch (e) {
      return false;
    }
  },

  /**
   * Send invite link to onelogin user
   * @return {object}
   */
  sendInviteLink: async (email) => {
    try {
      const url = `${AUTH_API_URL}${ONELOGIN_PART}/send-invite`;
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      return response.json();
    } catch (e) {
      return false;
    }
  },

  /**
   * Send the license mail
   * @param {*} sendToMail
   * @returns
   */
  sendLicenseMail: async (sendToMail) => {
    const body = {
      sendToMail
    };

    const url = `${AUTH_API_URL}${FILES_PART}/send-license-mail`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        return true;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
};

export default ApiService;
