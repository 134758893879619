import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import deIcon from '../assets/icons/de.png';
import enIcon from '../assets/icons/en.png';

const StyledIcon = styled.img`
  width: 21px;
  height: 21px;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  border-radius: 50%;
  transition: opacity 1s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

function LanguageIcon({ currentLocale, languageKey, onClick }) {
  const isActive = currentLocale === languageKey;
  const icon = languageKey === 'de' ? deIcon : enIcon;

  return (
    <StyledIcon
      src={icon}
      active={isActive}
      className={isActive ? 'active' : null}
      onClick={() => onClick(languageKey)}
      alt={languageKey}
    />
  );
}

LanguageIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentLocale: PropTypes.string,
  languageKey: PropTypes.string.isRequired
};

LanguageIcon.defaultProps = {
  currentLocale: 'en'
};

export default LanguageIcon;
