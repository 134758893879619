import React from 'react';
import { Box, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';

function NoMatch() {
  const tn = useTranslation();
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
      justify="center"
      minHeight={650}
    >
      <Text>{tn('leak.page.notFound')}</Text>
    </Box>
  );
}

export default NoMatch;
