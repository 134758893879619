import { COUNTRY_TYPES, CUSTOMER_TYPES, ROLES } from '@looxr/constants';
import { Translate } from '@looxr/utils';

export const getPackageTypes = () => {
  return [
    {
      name: 'PACKAGE_LEAK_SERVICE_ACCOUNT',
      accounts: '10',
      accountsEditable: false,
      validUntil: '+4y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service]
    },
    {
      name: 'PACKAGE_LEAK_BASIC_SMALL',
      accounts: '1',
      accountsEditable: false,
      validUntil: '+2y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service, CUSTOMER_TYPES.customer]
    },
    {
      name: 'PACKAGE_LEAK_BASIC_BIG',
      accounts: '5',
      accountsEditable: false,
      validUntil: '+4y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service, CUSTOMER_TYPES.customer]
    },
    {
      name: 'PACKAGE_LEAK_COMFORT',
      accounts: '0',
      accountsEditable: true,
      validUntil: '+4y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service, CUSTOMER_TYPES.customer]
    },
    {
      name: 'PACKAGE_PRESSURE_SERVICE_ACCOUNT',
      accounts: '0',
      accountsEditable: false,
      validUntil: '+4y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service]
    },
    {
      name: 'PACKAGE_PRESSURE_SERVICE',
      accounts: '0',
      accountsEditable: false,
      validUntil: '+4y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service, CUSTOMER_TYPES.customer]
    },
    {
      name: 'PACKAGE_PRESSURE_EFFICIENCY',
      accounts: '0',
      accountsEditable: false,
      validUntil: '+4y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service, CUSTOMER_TYPES.customer]
    },
    {
      name: 'PACKAGE_PRESSURE_ANALYTICS',
      accounts: '0',
      accountsEditable: false,
      validUntil: '+4y',
      validForType: [CUSTOMER_TYPES.oem, CUSTOMER_TYPES.service, CUSTOMER_TYPES.customer]
    }
  ];
};

export const getPackageOptions = () => {
  const packageTypes = getPackageTypes();
  return [
    { label: Translate.tn('admin.formOptions.package.leakService'), value: packageTypes[0].name },
    { label: Translate.tn('admin.formOptions.package.leakSmall'), value: packageTypes[1].name },
    { label: Translate.tn('admin.formOptions.package.leakLarge'), value: packageTypes[2].name },
    { label: Translate.tn('admin.formOptions.package.leakComfort'), value: packageTypes[3].name },
    {
      label: Translate.tn('admin.formOptions.package.pressureServiceOEM'),
      value: packageTypes[4].name
    },
    {
      label: Translate.tn('admin.formOptions.package.pressureService'),
      value: packageTypes[5].name
    },
    {
      label: Translate.tn('admin.formOptions.package.pressureEfficiency'),
      value: packageTypes[6].name
    },
    {
      label: Translate.tn('admin.formOptions.package.pressureAnalytics'),
      value: packageTypes[7].name
    }
  ];
};

export const getArchivedFilterOptions = () => {
  return [
    { label: Translate.tn('admin.formOptions.archivedFilter.active'), value: 'false' },
    { label: Translate.tn('admin.formOptions.archivedFilter.archived'), value: 'true' }
  ];
};

export const getPackageFilterOptions = () => {
  return [
    { label: Translate.tn('admin.formOptions.packageFilter.all'), value: 'all' },
    { label: Translate.tn('admin.formOptions.packageFilter.active'), value: 'active' },
    { label: Translate.tn('admin.formOptions.packageFilter.inactive'), value: 'inactive' }
  ];
};

export const getCustomerDefault = () => {
  return {
    name1: '',
    name2: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    country: COUNTRY_TYPES.de,
    type: '',
    privacy: {
      agb: 'https://looxr.de/agb',
      dataprotection: 'https://looxr.de/agb',
      termsofuse: 'https://looxr.de/agb'
    },
    tableau: {
      user: '',
      passwort: '',
      site: '',
      url: ''
    },
    parameter: {
      measuredDeviceType: '',
      airPressurePrice: null,
      powerFactor: null,
      emission: null,
      electricPrice: null,
      operatingHours: null
    },
    childMap: {},
    childIds: [],
    parent: null,
    archived: false
  };
};

export const getUserDefault = () => {
  return {
    acceptedLicense: false,
    hasFinishedAppIntro: false,
    hasFinishedLandingIntro: false,
    language: 'de',
    role: ROLES.user,
    email: '',
    firstname: '',
    lastname: '',
    salutation: '',
    logins: {
      leak: false,
      pressure: false
    },
    pressureLogin: {
      package: null,
      validUntil: null,
      role: ''
    },
    leakLogin: {
      package: null,
      validUntil: null,
      role: ''
    },
    manageableCustomers: {
      ids: [],
      refs: []
    }
  };
};
