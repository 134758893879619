import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ContentBox,
  LoadingOverlay,
  Modal,
  TableBox,
  Text,
  TextInput
} from '@looxr/components';
import { getCountryOptions } from '@looxr/constants';
import { filterContains, filterStartsWith, useTranslation } from '@looxr/utils';
import { useLoadCustomers } from '../../hooks';

function ManageableUsers({ customerData, manageableCustomers, onAdd, onRemove }) {
  const tn = useTranslation();
  const { getCustomers } = useLoadCustomers();

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [showManageableCustomerModal, setShowManageableCustomerModal] = useState(false);
  const [manageablePool, setManageablePool] = useState([]);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    if (manageablePool && manageablePool.length > 0) {
      if (query && query.length > 3) {
        const filteredCustomers = manageablePool.filter((customer) => {
          let contains = false;

          if (
            filterStartsWith(query, customer.id) ||
            filterContains(query, customer.name1) ||
            filterContains(query, customer.zip) ||
            filterContains(query, customer.city) ||
            filterContains(query, customer.country)
          ) {
            contains = true;
          }

          return contains;
        });

        setFiltered(filteredCustomers);
      } else {
        setFiltered(manageablePool);
      }
    }
  }, [query, manageablePool]);

  const openManageableModal = async () => {
    // @TODO if pool is 0 dont show modal or show message in modal
    setLoading(true);

    // loads all customers based on current user
    let customers = await getCustomers();

    // filter base customer of user
    customers = customers.filter((doc) => doc.id !== customerData.id);

    // filter out already selected customers
    customers = customers.filter((doc) => {
      const match = manageableCustomers.filter((manageableDoc) => manageableDoc.id === doc.id)[0];
      return match === undefined;
    });

    setManageablePool(customers);
    setLoading(false);
    setShowManageableCustomerModal(true);
  };

  const addCustomer = (customerDoc) => {
    onAdd(customerDoc);
    const update = manageablePool.filter((manageable) => manageable.id !== customerDoc.id);
    setManageablePool(update);
    setQuery('');
  };

  return (
    <>
      <ContentBox
        title={tn('admin.page.userForm.manageableCustomers.title')} // "Zusätzliche Verwaltbare Kunden"
        marginTop={10}
        noPadding
        showAddBtn={true}
        onAddClick={() => openManageableModal()}
      >
        <TableBox width="100%" hover={false}>
          <table>
            <thead>
              <tr>
                <th>{tn('general.name')}</th>
                <th>{tn('general.address')}</th>
                <th>{tn('general.zip')}</th>
                <th>{tn('general.city')}</th>
                <th>{tn('general.country')}</th>
                <th width="90">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {manageableCustomers.map((customerDoc) => {
                const option = getCountryOptions().filter(
                  (option) => option.value === customerDoc.country
                )[0];
                return (
                  <tr key={customerDoc.id}>
                    <td>{customerDoc.name1}</td>
                    <td>{customerDoc.address1}</td>
                    <td>{customerDoc.zip}</td>
                    <td>{customerDoc.city}</td>
                    <td>{option.label}</td>
                    <td>
                      <Button
                        size="sm"
                        background="red"
                        text={tn('general.delete')} // "Löschen"
                        onClick={() => onRemove(customerDoc)}
                      />
                    </td>
                  </tr>
                );
              })}
              {manageableCustomers.length === 0 && (
                <tr className="no-hover">
                  <td colSpan="6">
                    <Text align="center" size="sm">
                      {tn('admin.page.userForm.manageableCustomers.noData')}
                      {/* Keine weiteren Daten hinterlegt */}
                    </Text>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </TableBox>
      </ContentBox>
      <Modal
        size={1000}
        show={showManageableCustomerModal}
        onClose={() => setShowManageableCustomerModal(false)}
        closeOnBackdrop={true}
        showModalCloseIcon={true}
        closeOnEsc={true}
      >
        <Box
          display="flex"
          alignItems="left"
          justify="center"
          paddingX={5}
          paddingY={5}
          direction="column"
        >
          <Box>
            <Text size="lg" bold>
              {tn('admin.page.userForm.manageableCustomers.addTitle')}
              {/* Verwaltbaren Kunden hinzufügen */}
            </Text>
          </Box>
          <Box>
            <TextInput
              id="customerSearch"
              name="customerSearch"
              placeholder={tn('admin.page.userForm.manageableCustomers.searchPlaceholder')} // "Tag, Name, PLZ oder Ort eingeben"
              value={query}
              onChange={(e) => setQuery(e.currentTarget.value)}
            />
          </Box>
          <TableBox
            width="100%"
            hover={true}
            marginTop={8}
            marginBottom={5}
            style={{
              display: 'block',
              width: '100%',
              minHeight: '400px',
              maxHeight: '400px',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>{tn('general.name')}</th>
                  <th>{tn('general.address')}</th>
                  <th>{tn('general.zip')}</th>
                  <th>{tn('general.city')}</th>
                  <th>{tn('general.country')}</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((customerDoc) => {
                  const option = getCountryOptions().filter(
                    (option) => option.value === customerDoc.country
                  )[0];
                  return (
                    <tr key={customerDoc.id} onClick={() => addCustomer(customerDoc)}>
                      <td>{customerDoc.name1}</td>
                      <td>{customerDoc.address1}</td>
                      <td>{customerDoc.zip}</td>
                      <td>{customerDoc.city}</td>
                      <td>{option.label}</td>
                    </tr>
                  );
                })}
                {filtered.length === 0 && (
                  <tr className="no-hover">
                    <td colSpan="5">
                      <Text align="center" size="sm">
                        {tn('admin.page.userForm.manageableCustomers.noDataAddable')}
                        {/* Keine Kunden zu diesem Filter oder es können keine Kunden mehr hinzugefügt werden */}
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableBox>
        </Box>
      </Modal>
      <LoadingOverlay isVisible={loading} />
    </>
  );
}

ManageableUsers.propTypes = {
  customerData: PropTypes.any,
  manageableCustomers: PropTypes.any,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

ManageableUsers.defaultProps = {
  customerData: null,
  manageableCustomers: []
};

export default ManageableUsers;
