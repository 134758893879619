import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Banner,
  Box,
  ContentBox,
  DateRangeInput,
  Label,
  LoadingOverlay,
  Select,
  SortTable,
  Text
} from '@looxr/components';
import { SORT_DESC, useLanguage, useTranslation } from '@looxr/utils';
import { getReportLeakTableConfig } from '../../constants';
import { useLoadCustomers, useLoadUsers } from '../../hooks';
import { FirebaseService } from '../../services';

function Report() {
  const { id } = useParams();
  const tn = useTranslation();
  const language = useLanguage();

  const { getUsersByCustomer } = useLoadUsers();
  const { getCustomerById } = useLoadCustomers();

  const [reportCustomer, setReportCustomer] = useState(null);

  const [loading, setLoading] = useState(false);

  const [leaks, setLeaks] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [filterCustomer, setFilterCustomer] = useState('all');

  const [range, setRange] = useState({
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate()
  });

  // init
  useEffect(() => {
    // set initial filter and load leaks by customer + users
    const init = async () => {
      // create a customer map to load customers off leaks to show
      let reportLeaks = [];
      const reportUsers = [];
      const reportCustomerMap = {};
      const reportCustomerDocs = [];

      if (range.from && range.to) {
        setLoading(true);

        const reportCustomerDoc = await getCustomerById(id);
        setReportCustomer({
          id: reportCustomerDoc.id,
          ref: reportCustomerDoc.ref,
          ...reportCustomerDoc.data()
        });

        // get users of customer
        const usersOfCustomersSnapshot = await getUsersByCustomer(reportCustomerDoc.ref);
        const usersOfCustomer = [];

        usersOfCustomersSnapshot.forEach((doc) => {
          if (doc.exists) {
            const docData = doc.data();
            usersOfCustomer.push({
              id: doc.id,
              ref: doc.ref,
              ...docData
            });
          }
        });

        for (let i = 0; i < usersOfCustomer.length; i += 1) {
          const user = usersOfCustomer[i];

          // eslint-disable-next-line no-await-in-loop
          const leaks = await FirebaseService.getLeaksByUserWithFilter(user, range);
          const leaksWithCustomerData = [];
          if (leaks.length > 0) {
            reportUsers.push(user);

            for (let k = 0; k < leaks.length; k += 1) {
              const leak = leaks[k];
              let customerData = null;

              if (!reportCustomerMap[leak.customer.id]) {
                // eslint-disable-next-line no-await-in-loop
                const customerOfLeak = await leak.customer.get();
                customerData = customerOfLeak.data();

                reportCustomerMap[customerOfLeak.id] = customerData;
                reportCustomerDocs.push({
                  id: customerOfLeak.id,
                  ...customerData
                });
              } else {
                customerData = reportCustomerMap[leak.customer.id];
              }

              leaksWithCustomerData.push({
                ...leak,
                customerData
              });
            }

            reportLeaks = [...reportLeaks, ...leaksWithCustomerData];
          }
        }
      }

      setUsers(reportUsers);
      setFilteredUsers(reportUsers);

      setCustomers(reportCustomerDocs);

      setLeaks(reportLeaks);
      setFiltered(reportLeaks);

      setLoading(false);
    };

    init();
  }, [id, range]);

  // filter leaks by selected customer id
  useEffect(() => {
    if (filterCustomer !== 'all' && leaks.length > 0) {
      const leaksOfCustomer = leaks.filter((leak) => leak.customerID === filterCustomer);
      const usersOfFilteredLeaksMap = {};

      for (let i = 0; i < leaksOfCustomer.length; i += 1) {
        const leak = leaksOfCustomer[i];
        usersOfFilteredLeaksMap[leak.createdBy.id] = true;
      }

      setFiltered(leaksOfCustomer);
      setFilteredUsers(Object.keys(usersOfFilteredLeaksMap));
    } else {
      setFiltered(leaks);
      setFilteredUsers(users);
    }
  }, [filterCustomer]);

  const updateRange = (value) => {
    setRange(value);
  };

  const updateCustomerFilter = (option) => {
    setFilterCustomer(option);
  };

  const getCustomerName = () => {
    if (reportCustomer) {
      return `#${reportCustomer.id.substring(0, 5).toUpperCase()} - ${reportCustomer.name1}`;
    }

    return '';
  };

  const getCustomerOptions = () => {
    let options = [];

    options.push({ value: 'all', label: 'Alle' });

    const customerOptions = customers.map((customer) => {
      return {
        value: `${customer.id}`,
        label: customer.name1
      };
    });

    options = [...options, ...customerOptions];

    return options;
  };

  return (
    <>
      <Banner text={tn('admin.page.report.headline')} subtext={getCustomerName()} />
      <Box paddingX={10} paddingY={10} minHeight="calc(100vh - 143px)">
        <ContentBox width={900}>
          <Box display="flex" direction="row" width="100%">
            <Box display="flex" direction="row" alignItems="center" flexGrow={1}>
              <Box>
                <Text bold size="4xl" color="purple" align="center">
                  {filtered.length}
                </Text>
                <Text bold color="black2" size="sm">
                  {tn('general.leaks')}
                </Text>
              </Box>

              <Box marginLeft={5}>
                <Text bold size="4xl" color="purple" align="center">
                  {filteredUsers.length}
                </Text>
                <Text bold color="black2" size="sm">
                  {tn('admin.page.report.filter.users')}
                </Text>
              </Box>

              <Box marginLeft={5}>
                <Text bold size="4xl" color="purple" align="center">
                  {filterCustomer !== 'all' ? 1 : customers.length}
                </Text>
                <Text bold color="black2" size="sm">
                  {tn('admin.page.report.filter.customers')}
                </Text>
              </Box>
            </Box>

            <Box display="flex" marginTop={3} width={250} direction="column">
              <Label htmlFor="filter-report-range">{tn('admin.page.report.filter.range')}</Label>
              <DateRangeInput
                locale={language}
                name="filter-report-range"
                range={range}
                onChange={(update) => updateRange(update)}
              />
            </Box>

            <Box width={250} marginTop={3} marginLeft={3}>
              <Label htmlFor="filter-customer">{tn('general.customer')}</Label>
              <Select
                id="filter-customer"
                name="filter-customer"
                value={filterCustomer}
                options={getCustomerOptions()}
                onChange={(e) => updateCustomerFilter(e.value)}
              />
            </Box>
          </Box>
        </ContentBox>
        <ContentBox title={tn('general.leaks')} marginTop={5} noPadding>
          <SortTable
            resetPageOnDataUpdate={true}
            tableKey="reportLeaks"
            data={filtered}
            hasPagination={true}
            pagerCondensed={false}
            hasRowHover={false}
            perPage={25}
            saveState={true}
            noDataText={tn('admin.page.report.table.noData')}
            dataName={tn('general.leaks')}
            columns={getReportLeakTableConfig()}
            defaulSortBy="id"
            defaultSortOrder={SORT_DESC}
          />
        </ContentBox>
      </Box>

      <LoadingOverlay isVisible={loading} />
    </>
  );
}

export default Report;
