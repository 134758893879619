import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  Box,
  ContentBox,
  Label,
  MoreMenuIcon,
  SelectRH as Select,
  Text,
  TextInput,
  TextInputRH
} from '@looxr/components';
import { CUSTOMER_TYPES, getCountryOptions, getCustomerTypeOptions } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { customerNameIsUnique } from '../../utils';
import CustomerMoreMenu from './CustomerMoreMenu';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function CustomerFormGeneral({
  register,
  errors,
  customerData,
  customerRef,
  parentData,
  isCreate,
  canEditType,
  showMoreMenu,
  onMoreActionClick
}) {
  const tn = useTranslation();

  const getCustomerTypeOptionsByParent = () => {
    let options = getCustomerTypeOptions();

    if (parentData) {
      if (parentData.type === CUSTOMER_TYPES.oem) {
        options = options.filter((o) => o.value !== CUSTOMER_TYPES.oem);
      }

      if (parentData.type === CUSTOMER_TYPES.service) {
        options = options.filter((o) => o.value !== CUSTOMER_TYPES.oem);
        options = options.filter((o) => o.value !== CUSTOMER_TYPES.service);
      }
    }

    return options;
  };

  return (
    <>
      <ContentBox
        title={tn('admin.page.customerForm.general.title')} // "Allgemein"
      >
        {!isCreate && showMoreMenu && (
          <MoreMenuIcon
            text={tn('admin.page.customerForm.moreMenu.title')}
            data={customerData}
            menuContent={CustomerMoreMenu}
            onMenuItemClick={(action) => onMoreActionClick(action)}
          />
        )}
        <Box display="flex" direction="column">
          <StyledFormBox>
            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="type" required valid={errors.type === undefined}>
                {tn('admin.page.customerForm.general.customerType')}
                {/* Kunden-Typ */}
              </Label>
              <Select
                disabled={customerData.archived || !canEditType}
                id="type"
                name="type"
                placeholder={tn('general.choose')} // "Bitte wählen"
                options={getCustomerTypeOptionsByParent()}
                ref={register({ required: true })}
              />
            </Box>
            {parentData && (
              <Box marginY={3} marginX={3} width={250}>
                <Label htmlFor="parent">
                  {tn('admin.page.customerForm.general.parentOf')}
                  {/* Zugehörig zu */}
                </Label>
                <TextInput id="parent" name="parent" disabled={true} value={parentData.name1} />
              </Box>
            )}
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="name1" required valid={errors.name1 === undefined}>
                {tn('admin.page.customerForm.general.name1')}
                {/* Name 1 */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="name1"
                name="name1"
                ref={register({
                  required: true,
                  validate: (name1) => customerNameIsUnique(isCreate, name1, customerRef)
                })}
              />
              {errors.name1 && errors.name1.type === 'validate' && (
                <Text size="sm" color="red">
                  {tn('admin.page.customerForm.general.nameDuplicateNotice')}
                  {/* Dieser Kundenname is bereits vergeben */}
                </Text>
              )}
            </Box>

            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="name2">
                {tn('admin.page.customerForm.general.name2')}
                {/* Name 2 */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="name2"
                name="name2"
                ref={register}
              />
            </Box>
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="address1" required valid={errors.address1 === undefined}>
                {tn('admin.page.customerForm.general.address1')}
                {/* Adresse 1 */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="address1"
                name="address1"
                ref={register({ required: true })}
              />
            </Box>

            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="address2">
                {tn('admin.page.customerForm.general.address2')}
                {/* Adresse 2 */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="address2"
                name="address2"
                ref={register}
              />
            </Box>
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="zip" required valid={errors.zip === undefined}>
                {tn('general.zip')}
                {/* PLZ */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="zip"
                name="zip"
                ref={register({ required: true })}
              />
            </Box>

            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="city" required valid={errors.city === undefined}>
                {tn('general.city')}
                {/* Ort */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="city"
                name="city"
                ref={register({ required: true })}
              />
            </Box>

            <Box marginY={3} marginX={3} width={250}>
              <Label htmlFor="country" required valid={errors.country === undefined}>
                {tn('general.country')}
                {/* Land */}
              </Label>
              <Select
                disabled={customerData.archived}
                id="country"
                name="country"
                placeholder={tn('general.choose')} // "Bitte wählen"
                options={getCountryOptions()}
                ref={register({ required: true })}
              />
            </Box>
          </StyledFormBox>
        </Box>
      </ContentBox>
    </>
  );
}

CustomerFormGeneral.propTypes = {
  isCreate: PropTypes.bool.isRequired,
  canEditType: PropTypes.bool,
  showMoreMenu: PropTypes.bool,
  customerData: PropTypes.any,
  customerRef: PropTypes.any,
  parentData: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any.isRequired,
  onMoreActionClick: PropTypes.func
};

CustomerFormGeneral.defaultProps = {
  canEditType: false,
  showMoreMenu: false,
  customerData: null,
  customerRef: null,
  parentData: null,
  errors: undefined,
  onMoreActionClick: () => {}
};

export default CustomerFormGeneral;
