import 'react-day-picker/lib/style.css';
import 'moment/locale/de';
import 'moment/locale/en-gb';

import PropTypes from 'prop-types';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import styled from 'styled-components/macro';
import { faTimes as ResetIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from './Box';
import Text from './Text';

const StyledDayPickerInput = styled.div`
  height: 39px;

  div.DayPickerInput {
    width: 100%;
  }

  input {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.borderColor};
    color: ${(props) => props.theme.colors.black};
    outline: 0;
    width: 100%;
    border-radius: 4px;
    padding: 7px 14px;
    font-size: 14px;
  }

  .DayPicker {
    &-Day {
      padding: 1px;
      border-radius: 0;
      &--today {
        color: ${(props) => props.theme.colors.green};
      }
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.colors.purple};
  }
`;

const StyledResetIcon = styled(Box)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

function OverlayComponent({ children, ...props }) {
  return (
    <Box background="white" zIndex="10" position="absolute" left="0" {...props}>
      {children}
    </Box>
  );
}

function DateInput({ onChange, value, disabled, name, placeholder, locale, showReset }) {
  return (
    <StyledDayPickerInput isDisabled={disabled}>
      <DayPickerInput
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        formatDate={formatDate}
        parseDate={parseDate}
        onDayChange={onChange}
        overlayComponent={OverlayComponent}
        dayPickerProps={{
          locale,
          localeUtils: MomentLocaleUtils
        }}
      />
      <Box
        display="flex"
        alignItems="left"
        justify="center"
        alignContent="center"
        position="absolute"
        height="100%"
        top={4}
        right={3}
      >
        {showReset && value !== null && value !== undefined && value !== '' && (
          <StyledResetIcon
            padding={2}
            alignSelf="center"
            alignItems="center"
            justify="center"
            alignContent="center"
            onClick={() => onChange(null)}
          >
            <Text color="red" size="sm">
              <FontAwesomeIcon icon={ResetIcon} />
            </Text>
          </StyledResetIcon>
        )}
      </Box>
    </StyledDayPickerInput>
  );
}

DateInput.propTypes = {
  showReset: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  locale: PropTypes.string
};

DateInput.defaultProps = {
  showReset: false,
  placeholder: 'dd.mm.yyyy',
  value: null,
  locale: 'de',
  disabled: false,
  onChange: () => {}
};

OverlayComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

OverlayComponent.defaultProps = {
  children: null
};

export default DateInput;
