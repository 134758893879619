import React from 'react';
import styled from 'styled-components/macro';

const StyledSeparator = styled.div`
  background-color: ${props => props.theme.colors.borderColor};
  height: 1px;
  width: 100%;
`;

function Separator() {
  return <StyledSeparator />;
}

export default Separator;
