import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, CheckboxRH, Label, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function UserFormStatus({ register, userData }) {
  const tn = useTranslation();

  return (
    <>
      <Box display="flex" direction="column">
        <StyledFormBox>
          <Box display="flex" marginY={3} marginX={3} width={550} direction="row">
            <Box width={250}>
              <Label htmlFor="acceptedLicense">
                {tn('admin.page.userForm.status.acceptedLicense')}
                {/* Lizenzbestimmung akzeptiert */}
              </Label>
            </Box>

            <Box width={50}>
              <CheckboxRH
                checked={userData?.acceptedLicense}
                id="acceptedLicense"
                name="acceptedLicense"
                ref={register({ required: false })}
              />
            </Box>

            {userData.acceptedLicenseAt && (
              <Box display="flex" width={250} justify="center" alignItems="center">
                <Text size="xs" spaced color="grey">
                  {tn('admin.page.userForm.status.acceptedLicenseAt')}{' '}
                  {moment(userData.acceptedLicenseAt.toDate()).format('DD.MM.YYYY HH:mm:ss')}
                </Text>
              </Box>
            )}
          </Box>

          <Box display="flex" marginY={3} marginX={3} width={550} direction="row">
            <Box width={250}>
              <Label htmlFor="hasFinishedAppIntro">
                {tn('admin.page.userForm.status.hasFinishedAppIntro')}
                {/* App Intro abgeschlossen */}
              </Label>
            </Box>

            <Box width={50}>
              <CheckboxRH
                checked={userData?.hasFinishedAppIntro}
                id="hasFinishedAppIntro"
                name="hasFinishedAppIntro"
                ref={register({ required: false })}
              />
            </Box>
          </Box>

          <Box display="flex" marginY={3} marginX={3} width={550} direction="row">
            <Box width={250}>
              <Label htmlFor="hasFinishedLandingIntro">
                {tn('admin.page.userForm.status.hasFinishedLandingIntro')}
                {/* Landingpage Intro abgeschlossen */}
              </Label>
            </Box>

            <Box width={50}>
              <CheckboxRH
                checked={userData?.hasFinishedLandingIntro}
                id="hasFinishedLandingIntro"
                name="hasFinishedLandingIntro"
                ref={register({ required: false })}
              />
            </Box>
          </Box>
        </StyledFormBox>
      </Box>
    </>
  );
}

UserFormStatus.propTypes = {
  register: PropTypes.any.isRequired,
  userData: PropTypes.any
};

UserFormStatus.defaultProps = {
  userData: null
};

export default UserFormStatus;
