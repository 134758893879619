import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, ContentBox, Label, Text, TextInputRH } from '@looxr/components';
import { URL_PATTERN } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function CustomerFormUrls({ register, errors, customerData }) {
  const tn = useTranslation();

  return (
    <>
      <ContentBox
        title={tn('admin.page.customerForm.tableau.title')} // "Tableau"
        marginTop={5}
      >
        <Box display="flex" direction="column">
          <StyledFormBox>
            <Box marginY={3} marginX={3} width={524}>
              <Label htmlFor="tableau.site" valid={errors?.tableau?.site === undefined}>
                {tn('admin.page.customerForm.tableau.page')}
                {/* Seite */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="tableau.site"
                name="tableau.site"
                ref={register({
                  required: false
                })}
              />
            </Box>
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={524}>
              <Label htmlFor="tableau.url" valid={errors?.tableau?.url === undefined}>
                {tn('admin.page.customerForm.tableau.url')}
                {/* Seite */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="tableau.url"
                name="tableau.url"
                ref={register({ required: false, pattern: URL_PATTERN })}
              />
              {errors?.tableau?.url?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validURLNotice')}
                </Text>
              )}
            </Box>
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={524}>
              <Label htmlFor="tableau.user" valid={errors?.tableau?.user === undefined}>
                {tn('admin.page.customerForm.tableau.user')}
                {/* Benutzer */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="tableau.user"
                name="tableau.user"
                ref={register({
                  required: false
                })}
              />
            </Box>
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={524}>
              <Label htmlFor="tableau.password" valid={errors?.tableau?.password === undefined}>
                {tn('admin.page.customerForm.tableau.password')}
                {/* Passwort */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="tableau.password"
                name="tableau.password"
                ref={register({
                  required: false
                })}
              />
            </Box>
          </StyledFormBox>
        </Box>
      </ContentBox>
    </>
  );
}

CustomerFormUrls.propTypes = {
  customerData: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any.isRequired
  // onUpdate: PropTypes.func.isRequired
};

CustomerFormUrls.defaultProps = {
  customerData: null,
  errors: undefined
  // onUpdate: () => {}
};

export default CustomerFormUrls;
