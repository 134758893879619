import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import LoadingGif from '../assets/icons/loading.gif';
import Box from './Box';

const StyledLoadingBox = styled(Box)`
  img {
    width: 100px;
  }
`;

const StyledLoadingOverlay = styled(Box)`
  z-index: 10;
  background-color: #fff;
  opacity: 1;
`;

function LoadingBox({ loading, children, renderChildren, ...rest }) {
  return (
    <>
      {loading ? (
        <StyledLoadingBox
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justify="center"
          {...rest}
        >
          {renderChildren ? (
            <>
              <StyledLoadingOverlay
                width="100%"
                height="100%"
                position="absolute"
                alignItems="center"
                display="flex"
                justify="center"
              >
                <img src={LoadingGif} alt="loading" />
              </StyledLoadingOverlay>
              <Box {...rest}>{children}</Box>
            </>
          ) : (
            <img src={LoadingGif} alt="loading" />
          )}
        </StyledLoadingBox>
      ) : (
        <Box {...rest}>{children}</Box>
      )}
    </>
  );
}

LoadingBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  loading: PropTypes.bool.isRequired,
  renderChildren: PropTypes.bool
};

LoadingBox.defaultProps = {
  renderChildren: true
};

export default LoadingBox;
