import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';

const StyledTextField = styled.input`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  color: ${(props) => props.theme.colors.black};
  outline: 0;
  width: 100%;
  border-radius: 4px;
  padding: 7px;
  font-size: 14px;
  display: 'block';

  &:disabled {
    background-color: ${(props) => props.theme.colors.disabled};
    cursor: not-allowed;
  }

  ${(props) =>
    props.hasAddOn &&
    css`
      border-radius: 4px 0 0 4px;
      border-right: 0;
    `}
`;

const StyledAddOn = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.grey2};
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 0 4px 4px 0;
  padding: 0 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

// eslint-disable-next-line
export const TextInputRH = React.forwardRef(
  ({ autoComplete, disabled, id, name, placeholder, type, addOn }, ref) => {
    const pattern = type === 'number' ? '\\d*' : undefined;

    if (addOn) {
      return (
        <Box display="flex" width="100%" alignItems="stretch">
          <StyledTextField
            autoComplete={autoComplete}
            disabled={disabled}
            id={id}
            name={name}
            placeholder={placeholder}
            type={type}
            hasAddOn={addOn}
            ref={ref}
          />
          <StyledAddOn>{addOn}</StyledAddOn>
        </Box>
      );
    }

    return (
      <StyledTextField
        autoComplete={autoComplete}
        disabled={disabled}
        id={id}
        name={name}
        pattern={pattern}
        placeholder={placeholder}
        type={type}
        ref={ref}
      />
    );
  }
);

TextInputRH.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['date', 'email', 'number', 'password', 'text', 'url']),
  addOn: PropTypes.string
};

TextInputRH.defaultProps = {
  autoComplete: 'off',
  disabled: false,
  name: '',
  placeholder: '',
  type: 'text',
  addOn: null
};

// export default TextInputRH;
