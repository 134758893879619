import { Collections, LOOXR_FIREBASE_ID, ROLES } from '@looxr/constants';
import { AppStateService, FirebaseService } from '../services';

function useLoadCustomers() {
  const getCustomerById = async (id) => {
    try {
      if (id !== LOOXR_FIREBASE_ID) {
        const doc = await FirebaseService.ref
          .firestore()
          .collection(Collections.customer)
          .doc(id)
          .get();

        const data = doc.data();

        if (doc.exists && (!data.archived || AppStateService.user.role === ROLES.looxr)) {
          return doc;
        }

        return false;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getCustomerByIds = async (ids) => {
    const loading = [];
    for (let i = 0; i < ids.length; i += 1) {
      const id = ids[i];
      loading.push(getCustomerById(id));
    }

    const docResults = await Promise.all(loading);
    const valid = docResults.filter((doc) => doc !== false);
    return valid.map((doc) => {
      return {
        id: doc.id,
        ref: doc.ref,
        ...doc.data()
      };
    });
  };

  const getCustomersForLOOXR = async () => {
    try {
      // @TODO make sure only customers are loaded the user can view / has access to
      const snapshot = await FirebaseService.getCustomers();
      const data = [];
      snapshot.forEach((doc) => {
        if (doc.exists && doc.id && doc.id !== LOOXR_FIREBASE_ID) {
          const docData = doc.data();
          data.push({
            id: doc.id,
            ref: doc.ref,
            ...docData
          });
        }
      });

      return data;
    } catch (error) {
      console.log('Error getting customer data');
      console.log(error);
      return [];
    }
  };

  const getCustomersForOther = async () => {
    const customerData = AppStateService.customer;
    const authUser = AppStateService.user;

    const { childIds } = customerData;
    const manageableIds = authUser.manageableCustomers.ids;

    let customerIdsToLoad = [...childIds, ...manageableIds];

    // make unique
    customerIdsToLoad = customerIdsToLoad.filter(
      (item, index) => customerIdsToLoad.indexOf(item) === index
    );

    return getCustomerByIds(customerIdsToLoad);
  };

  const getCustomers = async () => {
    if (AppStateService.customer.id === LOOXR_FIREBASE_ID) {
      return getCustomersForLOOXR();
    }

    return getCustomersForOther();
  };

  return {
    getCustomerById,
    getCustomerByIds,
    getCustomers
  };
}

export default useLoadCustomers;
