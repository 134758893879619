import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Banner,
  Box,
  Button,
  ContentBox,
  Label,
  LoadingOverlay,
  Select,
  SortTable,
  TextInput
} from '@looxr/components';
import { getCountryOptions, getCustomerTypeOptions } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { getArchivedFilterOptions, getCustomerListTableConfig } from '../../constants';
import { useCustomerFilter, useFilterCustomers, useLoadCustomers } from '../../hooks';

const StyledFilterBox = styled(Box)`
  & > * {
    margin-left: 10px;
  }
`;

function CustomerList() {
  const tn = useTranslation();
  const history = useHistory();
  const { getCustomers } = useLoadCustomers();
  const { filterCustomers } = useFilterCustomers();
  const {
    updateFilterField,
    storeFilter,
    resetFilter,
    filter,
    loadingFilter
  } = useCustomerFilter();

  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [customerTableData, setCustomerTableData] = useState([]);
  const [initialFilterApplied, setInitialFilterApplied] = useState(false);

  // Effect to load initial data
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const customerData = await getCustomers();
      const filtered = filterCustomers(filter, customerData);

      setCustomers(customerData);
      setCustomerTableData(filtered);
      setLoading(false);
    };

    load();
  }, []);

  // Effect to apply initial filter on load
  useEffect(() => {
    if (!loading && !loadingFilter && !initialFilterApplied) {
      const filteredData = filterCustomers(filter, customers);
      setCustomerTableData(filteredData);
      setInitialFilterApplied(true);
    }
  }, [filter, customers, loading, loadingFilter, initialFilterApplied]);

  const applyFilter = (e) => {
    if (e) {
      e.preventDefault();
    }

    const filtered = filterCustomers(filter, customers);

    storeFilter();
    setCustomerTableData(filtered);
  };

  const resetFilterData = () => {
    resetFilter();
    setCustomerTableData(customers);
  };

  const editCustomer = (data) => {
    history.push(`/customer/${data.id}`);
  };

  return (
    <>
      <Banner
        text={tn('admin.page.customerList.title')} // "Kunden Übersicht"
      />
      <Box paddingX={10} paddingY={10} minHeight="calc(100vh - 143px)">
        <form onSubmit={applyFilter}>
          <ContentBox title="Filter">
            <StyledFilterBox display="flex" direction="row" wrap="wrap">
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-tag">{tn('general.id')}</Label>
                <TextInput
                  id="filter-tag"
                  name="filter-tag"
                  value={filter.tag ? filter.tag : ''}
                  onChange={(e) => updateFilterField('tag', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-name">{tn('general.name')}</Label>
                <TextInput
                  id="filter-name"
                  name="filter-name"
                  value={filter.name ? filter.name : ''}
                  onChange={(e) => updateFilterField('name', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-zip">{tn('general.zip')}</Label>
                <TextInput
                  id="filter-zip"
                  name="filter-zip"
                  value={filter.zip ? filter.zip : ''}
                  onChange={(e) => updateFilterField('zip', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-city">{tn('general.city')}</Label>
                <TextInput
                  id="filter-city"
                  name="filter-city"
                  value={filter.city ? filter.city : ''}
                  onChange={(e) => updateFilterField('city', e.currentTarget.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-country">{tn('general.country')}</Label>
                <Select
                  id="filter-country"
                  name="filter-country"
                  placeholder={tn('general.choose')}
                  value={filter.country ? filter.country : ''}
                  options={getCountryOptions()}
                  onChange={(e) => updateFilterField('country', e.value)}
                />
              </Box>
              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-type">{tn('general.type')}</Label>
                <Select
                  id="filter-type"
                  name="filter-type"
                  placeholder={tn('general.choose')}
                  value={filter.type ? filter.type : ''}
                  options={getCustomerTypeOptions()}
                  onChange={(e) => updateFilterField('type', e.value)}
                />
              </Box>

              <Box width={200} marginBottom={3}>
                <Label htmlFor="filter-isArchived">{tn('general.archived')}</Label>
                <Select
                  id="filter-isArchived"
                  name="filter-isArchived"
                  value={filter.isArchived}
                  options={getArchivedFilterOptions()}
                  onChange={(e) => updateFilterField('isArchived', e.value)}
                />
              </Box>
              <Box width="100%" marginTop={6}>
                <Box display="flex" direction="row">
                  <Box width={120}>
                    <Button
                      type="submit"
                      size="sm"
                      text={tn('general.filter')} // "Filtern"
                      onClick={applyFilter}
                    />
                  </Box>

                  <Box width={120} marginX={3}>
                    <Button
                      size="sm"
                      text={tn('general.reset')} // "Zurücksetzen"
                      background="purple"
                      onClick={resetFilterData}
                    />
                  </Box>
                </Box>
              </Box>
            </StyledFilterBox>
          </ContentBox>

          <ContentBox
            title={tn('admin.page.customerList.table.title')} // "Kunden"
            noPadding
            marginTop={20}
            showAddBtn={true}
            onAddClick={() => history.push('/customer/new')}
          >
            <SortTable
              tableKey="customers"
              data={customerTableData}
              hasPagination={true}
              pagerCondensed={false}
              perPage={20}
              saveState={true}
              noDataText={tn('admin.page.customerList.table.noData')} // "Keine Kundendaten zu diesen Kriterien vorhanden"
              dataName={tn('admin.page.customerList.table.title')} // "Kunden"
              columns={getCustomerListTableConfig()}
              hasRowHover={true}
              onRowClick={editCustomer}
              defaulSortBy="name1"
              defaultSortOrder="asc"
            />
          </ContentBox>
        </form>
      </Box>
      <LoadingOverlay isVisible={loading} />
    </>
  );
}

export default CustomerList;
