import {
  filterBooleanEquals,
  filterContains,
  filterEquals,
  filterEqualsDate,
  filterIsInDateRange
} from '@looxr/utils';

function useFilterUsers() {
  const filterUsers = (filter, users) => {
    let filtered = [];
    const {
      email,
      firstname,
      lastname,
      customerName,
      hasLeak,
      hasPressure,
      leakValidRange,
      pressureValidRange,
      isArchived
    } = filter;

    if (users !== null && users !== undefined && users.length > 0) {
      filtered = users;

      if (email) {
        filtered = filtered.filter((user) => filterContains(email, user.email));
      }

      if (firstname) {
        filtered = filtered.filter((user) => filterContains(firstname, user.firstname));
      }

      if (lastname) {
        filtered = filtered.filter((user) => filterContains(lastname, user.lastname));
      }

      if (customerName) {
        filtered = filtered.filter((user) => filterContains(customerName, user.customerData.name1));
      }

      // @TODO use constant to check
      if (hasLeak !== undefined && hasLeak !== 'all') {
        // @TODO use constant to check
        const leakVal = hasLeak === 'active';
        filtered = filtered.filter((user) => filterEquals(leakVal, user.logins.leak));
      }

      // @TODO use constant to check
      if (hasPressure !== undefined && hasPressure !== 'all') {
        // @TODO use constant to check
        const pressureVal = hasPressure === 'active';
        filtered = filtered.filter((user) => filterEquals(pressureVal, user.logins.pressure));
      }

      if (leakValidRange) {
        if (leakValidRange.from !== null && leakValidRange.to === null) {
          filtered = filtered.filter((user) => {
            if (user.logins && user.logins.leak && user.leakLogin && user.leakLogin.validUntil) {
              return filterEqualsDate(leakValidRange.from, user.leakLogin.validUntil);
            }

            return false;
          });
        } else if (leakValidRange.from !== null && leakValidRange.to !== null) {
          filtered = filtered.filter((user) => {
            if (user.logins && user.logins.leak && user.leakLogin && user.leakLogin.validUntil) {
              return filterIsInDateRange(leakValidRange, user.leakLogin.validUntil);
            }

            return false;
          });
        }
      }

      if (pressureValidRange) {
        if (pressureValidRange.from !== null && pressureValidRange.to === null) {
          filtered = filtered.filter((user) => {
            if (
              user.logins &&
              user.logins.pressure &&
              user.pressureLogin &&
              user.pressureLogin.validUntil
            ) {
              return filterEqualsDate(pressureValidRange.from, user.pressureLogin.validUntil);
            }

            return false;
          });
        } else if (pressureValidRange.from !== null && pressureValidRange.to !== null) {
          filtered = filtered.filter((user) => {
            if (
              user.logins &&
              user.logins.pressure &&
              user.pressureLogin &&
              user.pressureLogin.validUntil
            ) {
              return filterIsInDateRange(pressureValidRange, user.pressureLogin.validUntil);
            }

            return false;
          });
        }
      }

      if (isArchived) {
        // adapt filter becuase some users did not have the property initially
        if (isArchived === false || isArchived === 'false') {
          filtered = filtered.filter(
            (user) => user.archived === undefined || user.archived === false
          );
        } else {
          filtered = filtered.filter((user) => filterBooleanEquals(true, user.archived));
        }
      }
    }

    return filtered;
  };

  return { filterUsers };
}

export default useFilterUsers;
