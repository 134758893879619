import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Popover from 'react-tiny-popover';
import styled from 'styled-components';
import { faEllipsisV as MenuIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from './Box';
import Text from './Text';

const StyledMoreMenuIcon = styled(Box)`
  position: absolute;
  top: -25px;
  right: 3px;
  cursor: pointer;
  font-size: 18px;
  color: ${(props) => props.theme.colors.purple};

  * {
    cursor: pointer;
  }

  &:hover {
    opacity: 0.75;
  }
`;

function MoreMenuIcon({ data, text, menuContent, onMenuItemClick }) {
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const handleItemClick = (itemKey) => {
    setShowMoreMenu(false);
    onMenuItemClick(itemKey);
  };

  const renderMenuContent = () => {
    const ContentComponent = menuContent;
    return <ContentComponent data={data} onItemClick={(itemKey) => handleItemClick(itemKey)} />;
  };

  return (
    <StyledMoreMenuIcon>
      <Popover
        isOpen={showMoreMenu}
        position="bottom"
        padding={20}
        windowBorderPadding={30}
        onClickOutside={() => setShowMoreMenu(false)}
        content={renderMenuContent}
      >
        {/* Popover Plugin needs some real html element here */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
          onClick={() => setShowMoreMenu(true)}
          aria-hidden="true"
        >
          {text !== '' && (
            <Box marginRight={2}>
              <Text size="sm" color="purple" bold>
                {text}
              </Text>
            </Box>
          )}
          <FontAwesomeIcon icon={MenuIcon} />
        </div>
      </Popover>
    </StyledMoreMenuIcon>
  );
}

MoreMenuIcon.propTypes = {
  text: PropTypes.string,
  data: PropTypes.any,
  menuContent: PropTypes.any.isRequired,
  onMenuItemClick: PropTypes.func.isRequired
};

MoreMenuIcon.defaultProps = {
  text: '',
  data: null
};

export default MoreMenuIcon;
