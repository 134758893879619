import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  faEdit as EditIcon,
  faTrash as RemoveIcon,
  faUser as UserIcon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Label, LoadingBox, Modal, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';
import { getPackageOptions } from '../../constants';
import { usePackages } from '../../hooks';
import CustomerPackageUserList from './CustomerPackageUserList';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

const StyledFloatButton = styled(Box)`
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : '10px')};
  right: 15px;

  background: ${(props) => props.theme.colors.white};
  cursor: pointer;
  color: ${(props) => props.theme.colors.purple};
  font-size: 16px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
  border-radius: 50%;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.35);
  width: 40px;
  height: 40px;

  &:hover {
    background: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};

    svg {
      color: ${(props) => props.theme.colors.white};
      fill: ${(props) => props.theme.colors.white};
      * {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

function CustomerPackage({ packageData, onDeleteClick, onEditClick }) {
  const tn = useTranslation();

  const { getUsersByPackage } = usePackages();

  const [loading, setLoading] = useState(false);
  const [usersWithPackage, setUsersWithPackage] = useState([]);
  const [showActions, setShowActions] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  useEffect(() => {
    // @TODO work around saving reloads the data leaking memory when load is still running
    const load = async () => {
      setLoading(true);
      const loginType = packageData.type.toLowerCase().includes('leak') ? 'leak' : 'pressure';
      const users = await getUsersByPackage(packageData.ref, loginType);

      setUsersWithPackage(users);
      setLoading(false);
    };

    if (packageData && packageData.ref) {
      load();
    }
  }, [packageData]);

  const getTypeName = () => {
    const typeOption = getPackageOptions().find((option) => option.value === packageData.type);

    if (typeOption) {
      return typeOption.label;
    }

    return '';
  };

  return (
    <>
      <LoadingBox
        loading={loading}
        renderChildren={!loading}
        position="relative"
        width="100%"
        height="100%"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        <StyledFormBox>
          <Box paddingY={3} paddingX={3} width="100%">
            <Label htmlFor="package.type">{tn('general.package')}</Label>
            <Text>{getTypeName()}</Text>
          </Box>
          <Box paddingY={3} paddingX={3} width="100%">
            <Label htmlFor="package.maxAccounts">
              {tn('admin.page.customerForm.packages.accountLimit')}
              {/* Account Anzahl */}
            </Label>
            <Text>
              {usersWithPackage.length} / {packageData.maxAccounts}
            </Text>
          </Box>
          <Box paddingY={3} paddingX={3} width="100%">
            <Label htmlFor="package.validUntil">
              {tn('general.validUntil')}
              {/* Gültig bis */}
            </Label>
            <Text>{moment(packageData.validUntil.toDate()).format('DD.MM.YYYY')}</Text>
          </Box>
        </StyledFormBox>

        {packageData.active && (
          <>
            <StyledFloatButton
              onClick={onDeleteClick}
              show={showActions}
              display="flex"
              justify="center"
              alignContent="center"
              paddingX={3}
              paddingY={3}
            >
              <FontAwesomeIcon icon={RemoveIcon} />
            </StyledFloatButton>
            <StyledFloatButton
              onClick={onEditClick}
              show={showActions}
              display="flex"
              justify="center"
              alignContent="center"
              paddingX={3}
              paddingY={3}
              top={60}
            >
              <FontAwesomeIcon icon={EditIcon} />
            </StyledFloatButton>
            {usersWithPackage.length > 0 && (
              <StyledFloatButton
                onClick={() => setShowUserModal(true)}
                show={showActions}
                display="flex"
                justify="center"
                alignContent="center"
                paddingX={3}
                paddingY={3}
                top={110}
              >
                <FontAwesomeIcon icon={UserIcon} />
              </StyledFloatButton>
            )}
          </>
        )}
      </LoadingBox>

      <Modal
        show={showUserModal}
        size={800}
        closeOnBackdrop={true}
        closeOnEsc={true}
        showModalCloseIcon={true}
        onClose={() => setShowUserModal(false)}
      >
        <CustomerPackageUserList users={usersWithPackage} />
      </Modal>
    </>
  );
}

CustomerPackage.propTypes = {
  packageData: PropTypes.any.isRequired,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func
};

CustomerPackage.defaultProps = {
  onDeleteClick: () => {},
  onEditClick: () => {}
};

export default CustomerPackage;
