const BaseTheme = {
  colors: {
    transparent: 'transparent',
    background: '#fcfcfc',
    black: '#1D1D1B',
    black2: 'rgba(0, 0, 0, .55)',
    disabled: '#f2f2f2',
    white: '#fff',
    green: '#46b9a0',
    orange: '#FF8A30',
    orangeRed: '#DB5461',
    red: '#db5461',
    purple: '#841d64',
    blue: '#2e4a94',
    grey: 'rgba(29,29,27,.55)',
    grey2: '#f9f9f9',
    borderColor: 'rgba(29,29,27,.12)',
    oddColor: 'rgba(61, 61, 61, 0.03)'
  },
  buttonHover: {
    red: '#db5461d4',
    purple: '#841d64de',
    blue: '#2e4a94d9',
    green: '#46b9a0d4',
    grey: 'rgba(29,29,27,.33)'
  },
  breakpoints: {
    small: '480px',
    medium: '960px',
    betweenMedium: '1025px',
    mediumLarge: '1280px',
    large: '1440px',
    xlarge: '2300px'
  }
};

export default BaseTheme;
