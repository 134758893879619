import moment from 'moment';
import { ApiService } from '../services';

export const packageDateIsValid = (type, date, customerData) => {
  let valid = true;

  if (customerData.packages[type]) {
    const customerPackageValid = customerData[`${type}Package`].validUntil.toDate();
    // add one day because we use isBefore with day range and same day is allowed
    const customerMoment = moment(customerPackageValid).add(1, 'days');
    const dateMoment = moment(date);

    valid = dateMoment.isBefore(customerMoment, 'day');
  } else {
    valid = false;
  }

  return valid;
};

export const emailIsUnique = async (isCreate, mail, errors) => {
  if (isCreate) {
    if (mail !== undefined && mail !== null && mail.length > 0 && errors.email === undefined) {
      const isUnique = await ApiService.checkIfEmailIsUnique(mail);
      return isUnique;
    }
  }

  return true;
};

export const userNameIsUnique = async (isCreate, username, userRef) => {
  if (username !== undefined && username !== null && username.length > 0) {
    const isUnique = await ApiService.checkIfUsernameIsUnique(username, isCreate, userRef);
    return isUnique;
  }

  return true;
};

export const customerNameIsUnique = async (isCreate, name, customerRef) => {
  if (name !== undefined && name !== null && name.length > 0) {
    const isUnique = await ApiService.checkIfCustomerNameIsUnique(name, isCreate, customerRef);
    return isUnique;
  }

  return true;
};
