import { filterBooleanEquals, filterContains, filterEquals, filterStartsWith } from '@looxr/utils';

function useFilterCustomers() {
  const filterCustomers = (filter, customers) => {
    let filtered = [];
    const { tag, name, zip, city, country, type, isArchived } = filter;

    if (customers !== null && customers !== undefined && customers.length > 0) {
      filtered = customers;

      if (tag) {
        const cleaned = tag.replace('#', '');
        filtered = filtered.filter((customer) => filterStartsWith(cleaned, customer.id));
      }

      if (name) {
        filtered = filtered.filter((customer) => filterContains(name, customer.name1));
      }

      if (zip) {
        filtered = filtered.filter((customer) => filterStartsWith(zip, customer.zip));
      }

      if (city) {
        filtered = filtered.filter((customer) => filterContains(city, customer.city));
      }

      if (country) {
        filtered = filtered.filter((customer) => filterEquals(country, customer.country));
      }

      if (type) {
        filtered = filtered.filter((customer) => filterEquals(type, customer.type));
      }

      if (isArchived) {
        filtered = filtered.filter((customer) =>
          filterBooleanEquals(isArchived, customer.archived)
        );
      }
    }

    return filtered;
  };

  return { filterCustomers };
}

export default useFilterCustomers;
