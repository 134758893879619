import React from 'react';
import styled from 'styled-components/macro';
import LooxrLogoCondensed from '../assets/icons/logo-head-condensed.svg';
import LooxrLogo from '../assets/icons/logo.svg';

const StyledLogo = styled.div`
  background: url(${LooxrLogoCondensed}) no-repeat;
  background-position: center;
  background-size: 80%;
  height: 32px;
  width: 65px;

  @media (min-width: ${(props) => props.theme.breakpoints.betweenMedium}) {
    background: url(${LooxrLogo}) no-repeat;
    background-position: center;
    background-size: 100%;
    height: 32px;
    width: 117px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    background: url(${LooxrLogo}) no-repeat;
    background-position: center;
    background-size: 100%;
    height: 32px;
    width: 117px;
  }
`;

// { width, height }
function Logo() {
  return <StyledLogo />;
}

export default Logo;
