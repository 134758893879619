import { useCallback, useEffect, useState } from 'react';
import { USER_STORAGE_KEY } from '../constants';
import StorageService from '../services/StorageService';

function useUserFilter() {
  const [filter, setFilter] = useState({});
  const [loadingFilter, setLoadingFilter] = useState(true);

  const getDefaultFilter = useCallback(() => {
    return {
      firstname: '',
      lastname: '',
      email: '',
      customerName: '',
      leakValidRange: { from: null, to: null },
      pressureValidRange: { from: null, to: null },
      hasLeak: 'all',
      hasPressure: 'all',
      isArchived: 'false'
    };
  }, []);

  // Effect to load filter from local storage or use default filter
  useEffect(() => {
    const load = async () => {
      let initialFilter = getDefaultFilter();

      const storageFilter = await loadFilter();

      if (storageFilter !== null) {
        initialFilter = storageFilter;
      }

      setFilter(initialFilter);
      setLoadingFilter(false);
    };

    load();
  }, [getDefaultFilter]);

  const resetFilter = () => {
    const defaultFilter = getDefaultFilter();
    setFilter({ ...defaultFilter });

    StorageService.remove(USER_STORAGE_KEY);
  };

  const updateFilter = (data) => {
    const update = { ...filter, ...data };
    setFilter(update);

    saveFilter(update);
  };

  const updateFilterField = (key, data) => {
    const update = { ...filter };
    update[key] = data;

    setFilter({ ...update });
  };

  const storeFilter = () => {
    saveFilter({ ...filter });
  };

  const loadFilter = async () => {
    const storageFilter = await StorageService.getObject(USER_STORAGE_KEY);

    if (storageFilter !== null) {
      if (storageFilter.leakValidRange.from !== null) {
        storageFilter.leakValidRange.from = new Date(storageFilter.leakValidRange.from);
      }

      if (storageFilter.leakValidRange.to !== null) {
        storageFilter.leakValidRange.to = new Date(storageFilter.leakValidRange.to);
      }

      if (storageFilter.pressureValidRange.from !== null) {
        storageFilter.pressureValidRange.from = new Date(storageFilter.pressureValidRange.from);
      }

      if (storageFilter.pressureValidRange.to !== null) {
        storageFilter.pressureValidRange.to = new Date(storageFilter.pressureValidRange.to);
      }

      return storageFilter;
    }

    return null;
  };

  const saveFilter = (data) => {
    const toSave = { ...data };

    if (toSave.leakValidRange && toSave.leakValidRange.from) {
      if (toSave.leakValidRange.from instanceof Date) {
        toSave.leakValidRange.from = toSave.leakValidRange.from.getTime();
      }
    }

    if (toSave.leakValidRange && toSave.leakValidRange.to) {
      if (toSave.leakValidRange.to instanceof Date) {
        toSave.leakValidRange.to = toSave.leakValidRange.to.getTime();
      }
    }

    if (toSave.pressureValidRange && toSave.pressureValidRange.from !== null) {
      if (toSave.pressureValidRange.from instanceof Date) {
        toSave.pressureValidRange.from = toSave.pressureValidRange.from.getTime();
      }
    }

    if (toSave.pressureValidRange && toSave.pressureValidRange.to !== null) {
      if (toSave.pressureValidRange.to instanceof Date) {
        toSave.pressureValidRange.to = toSave.pressureValidRange.to.getTime();
      }
    }

    StorageService.saveObject(USER_STORAGE_KEY, toSave);
  };

  return {
    filter,
    loadingFilter,
    storeFilter,
    resetFilter,
    updateFilter,
    updateFilterField
  };
}

export default useUserFilter;
