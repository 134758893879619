// DEV
// const loginPortalUrl = 'http://localhost:3001';
// const leakPortalUrl = 'http://localhost:3002';
// const adminPortalUrl = 'http://localhost:3003';
// const authApiUrl = 'http://localhost:3000';
// const pressurePortalUrl = 'https://druckluft.looxr.de';

// TEST
// const authApiUrl = 'https://testauth.looxr.de';
// const loginPortalUrl = 'https://testlogin.looxr.de';
// const leakPortalUrl = 'https://testleckage.looxr.de';
// const pressurePortalUrl = 'https://looxr-staging.appspot.com';
// const adminPortalUrl = 'https://testadmin.looxr.de';

// PROD
const authApiUrl = 'https://auth.looxr.de';
const loginPortalUrl = 'https://login.looxr.de';
const leakPortalUrl = 'https://leckage.looxr.de';
const pressurePortalUrl = 'https://druckluft.looxr.de';
const adminPortalUrl = 'https://admin.looxr.de';

// API AND PORTAL URLS
export const AUTH_API_URL = authApiUrl;
export const ADMIN_PORTAL_URL = adminPortalUrl;
export const LEAK_PORTAL_URL = leakPortalUrl;
export const PRESSURE_PORTAL_URL = pressurePortalUrl;
export const LOGIN_PORTAL_URL = loginPortalUrl;
