/* eslint-disable react/display-name */
import moment from 'moment';
import React from 'react';
import { Box, Text } from '@looxr/components';
import {
  ROLES,
  getCountryOptions,
  getCustomerTypeOptions,
  getLeakRoleOptions,
  getPressureRoleOptions
} from '@looxr/constants';
import { SORT_BOOL, SORT_NUMBER, SORT_STRING, Translate } from '@looxr/utils';

/*
=====================================================
========== CUSTOMER TABLE LIST CONFIG  ==============
=====================================================
*/
export const getCustomerListTableConfig = () => {
  return [
    {
      key: 'id',
      label: Translate.tn('general.id'), // 'ID',
      sortValue: 'id',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">#{dataRow.id.substring(0, 5).toUpperCase()}</Text>
          </>
        );
      },
      sortable: false
    },
    {
      key: 'name1',
      label: Translate.tn('general.name'), // 'Name',
      sortValue: 'name1',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.name1}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'zip',
      label: Translate.tn('general.zip'), // 'PLZ',
      sortValue: 'zip',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{`${dataRow.zip}`}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'city',
      label: Translate.tn('general.city'), // 'Stadt',
      sortValue: 'city',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.city}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'country',
      label: Translate.tn('general.country'), // 'Land',
      sortValue: 'country',
      outputValue: (dataRow) => {
        const option = getCountryOptions().filter((option) => option.value === dataRow.country)[0];
        return (
          <>
            <Text size="sm">{option.label}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'type',
      label: Translate.tn('general.type'), // 'Typ',
      sortValue: 'type',
      outputValue: (dataRow) => {
        const option = getCustomerTypeOptions().filter(
          (option) => option.value === dataRow.type
        )[0];
        return (
          <>
            <Text size="sm">{option.label}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'createdAt',
      label: Translate.tn('general.createdAt'), //  'Erstellt am',
      sortValue: 'createdAt',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{moment(dataRow.createdAt.toDate()).format('DD.MM.YYYY')}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    }
  ];
};

/*
=====================================================
============= USER TABLE LIST CONFIG  ===============
=====================================================
*/
export const getUserListTableConfig = () => {
  return [
    {
      key: 'firstname',
      label: Translate.tn('general.firstname'), // 'Vorname',
      sortValue: 'firstname',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.firstname}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'lastname',
      label: Translate.tn('general.lastname'), // 'Nachname',
      sortValue: 'lastname',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.lastname}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'email',
      label: Translate.tn('general.email'), // 'E-Mail',
      sortValue: 'email',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.email}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'customer',
      label: Translate.tn('general.customer'), // 'Kunde',
      sortValue: 'customerData.name1',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.customerData.name1}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'leak',
      label: Translate.tn('admin.page.userList.table.leak'), // `Leckage`,
      sortValue: 'logins.leak',
      outputValue: (dataRow) => {
        let roleText = Translate.tn('general.notActive');
        let roleDate = '';
        let roleColor = 'borderColor';

        if (dataRow.role === ROLES.looxr) {
          roleText = 'LOOXR';
          roleColor = 'green';
        } else {
          const option = getLeakRoleOptions().filter(
            (option) => option.value === dataRow.leakLogin.role
          )[0];
          if (dataRow.logins.leak && option) {
            roleText = option.label;
            roleColor = 'purple';
            roleDate = moment(dataRow.leakLogin.validUntil.toDate()).format('DD.MM.YYYY');
          }
        }

        return (
          <Box
            background={roleColor}
            width="160px"
            display="flex"
            justify="center"
            alignItems="center"
          >
            <Text size="sm" color="white" align="center" bold>
              {roleText} {roleDate !== '' ? ` - ${roleDate}` : ''}
            </Text>
          </Box>
        );
      },
      sortable: true,
      sortType: SORT_BOOL
    },
    {
      key: 'pressure',
      label: Translate.tn('admin.page.userList.table.pressure'), // `Druckluft`,
      sortValue: 'logins.pressure',
      outputValue: (dataRow) => {
        let roleText = Translate.tn('general.notActive');
        let roleDate = '';
        let roleColor = 'borderColor';

        if (dataRow.role === ROLES.looxr) {
          roleText = 'LOOXR';
          roleColor = 'green';
        } else {
          const option = getPressureRoleOptions().filter(
            (option) => option.value === dataRow.pressureLogin.role
          )[0];
          if (dataRow.logins.pressure && option) {
            roleText = option.label;
            roleColor = 'blue';
            roleDate = moment(dataRow.pressureLogin.validUntil.toDate()).format('DD.MM.YYYY');
          }
        }

        return (
          <Box
            background={roleColor}
            width="160px"
            display="flex"
            justify="center"
            alignItems="center"
          >
            <Text size="sm" color="white" align="center" bold>
              {roleText} {roleDate !== '' ? ` - ${roleDate}` : ''}
            </Text>
          </Box>
        );
      },
      sortable: true,
      sortType: SORT_BOOL
    }
  ];
};

/*
=====================================================
======== PACKAGE USER TABLE LIST CONFIG  ============
=====================================================
*/
export const getPackageUserListTableConfig = () => {
  return [
    {
      key: 'firstname',
      label: Translate.tn('general.firstname'), // 'Vorname',
      sortValue: 'firstname',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.firstname}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'lastname',
      label: Translate.tn('general.lastname'), // 'Nachname',
      sortValue: 'lastname',
      outputValue: (dataRow) => (
        <>
          <Text size="sm">{dataRow.lastname}</Text>
        </>
      ),
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'email',
      label: Translate.tn('general.email'), // 'E-Mail',
      sortValue: 'email',
      outputValue: (dataRow) => {
        return (
          <>
            <Text size="sm">{dataRow.email}</Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    }
  ];
};

// ====================================================
// REPORT LEAKS
// ====================================================
export const getReportLeakTableConfig = () => {
  return [
    {
      key: 'id',
      label: Translate.tn('general.id'), // ID
      sortValue: 'qrCodeSerial',
      outputValue: (dataRow) => (
        <>
          <Text size="sm">{dataRow.qrCodeSerial}</Text>
        </>
      ),
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'customer',
      label: Translate.tn('general.customer'),
      sortValue: (dataRow) => {
        return dataRow.customerData ? dataRow.customerData.name1 : dataRow.customer.id;
      },
      outputValue: (dataRow) => (
        <>
          <Text size="sm">{dataRow.customerData ? dataRow.customerData.name1 : '-'}</Text>
        </>
      ),
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'createdByName',
      label: Translate.tn('general.createdBy'),
      sortValue: 'createdByName',
      outputValue: (dataRow) => (
        <>
          <Text size="sm">{dataRow.createdByName}</Text>
        </>
      ),
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'createdAt',
      label: Translate.tn('general.createdAt'),
      sortValue: 'createdAt',
      headerStyle: {
        textAlign: 'center'
      },
      outputValue: (dataRow) => {
        let dateStr = '';
        if (dataRow.createdAt) {
          dateStr = moment(dataRow.createdAt.toDate()).format('DD.MM.YYYY');
        }
        return (
          <>
            <Text size="sm" align="center">
              {dateStr}
            </Text>
          </>
        );
      },
      sortable: true,
      sortType: SORT_STRING
    }
  ];
};

// ====================================================
// COMPONENTS
// ====================================================
export const getComponentListConfig = () => {
  return [
    {
      key: 'articleNr',
      label: Translate.tn('general.articleNr'),
      sortValue: 'articleNr',
      outputValue: (dataRow) => (
        <>
          <Text size="sm">{dataRow.articleNr}</Text>
        </>
      ),
      sortable: true,
      sortType: SORT_NUMBER
    },
    {
      key: 'description1',
      label: `${Translate.tn('general.description')} 1`,
      sortValue: 'description1',
      outputValue: (dataRow) => (
        <>
          <Text size="sm">{dataRow.description1}</Text>
        </>
      ),
      sortable: true,
      sortType: SORT_STRING
    },
    {
      key: 'description2',
      label: `${Translate.tn('general.description')} 2`,
      sortValue: 'description2',
      outputValue: (dataRow) => (
        <>
          <Text size="sm">{dataRow.description2}</Text>
        </>
      ),
      sortable: true,
      sortType: SORT_STRING
    }
  ];
};
