import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from './Box';
import Text from './Text';

const StyledModalWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 91;
`;

const StyledBackDrop = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  background-color: ${(props) => props.theme.colors.grey2};
  opacity: 0.9;
`;

const StyledCloseIcon = styled(Box)`
  cursor: pointer;
`;

function Modal({
  size,
  show,
  onClose,
  closeOnEsc,
  closeOnBackdrop,
  showModalCloseIcon,
  children,
  blockScroll
}) {
  const handleEscKey = useCallback(
    (event) => {
      if (event.keyCode === 27 && closeOnEsc === true) {
        onClose();
      }
    },
    [closeOnEsc, onClose]
  );

  const handleBackdrop = () => {
    if (closeOnBackdrop) {
      onClose();
    }
  };

  useEffect(() => {
    if (show) {
      if (blockScroll) {
        document.body.style.overflow = 'hidden';
      }
      document.addEventListener('keydown', handleEscKey, false);
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [show, handleEscKey]);

  if (!show) {
    return null;
  }

  return (
    <StyledModalWrapper>
      <Box
        zIndex={81}
        position="fixed"
        height="100%"
        width="100%"
        left="0"
        display="flex"
        justify="center"
        alignItems="center"
      >
        <Box width={size} background="white" padding={5} zIndex={82}>
          {showModalCloseIcon && (
            <Box display="flex" alignItems="right" justify="flex-end">
              <StyledCloseIcon padding={2} onClick={() => onClose()}>
                <Text color="grey" size="lg">
                  <FontAwesomeIcon icon={faTimes} />
                </Text>
              </StyledCloseIcon>
            </Box>
          )}
          {children}
        </Box>
        <StyledBackDrop show={true} onClick={() => handleBackdrop()} zIndex={995} />
      </Box>
    </StyledModalWrapper>
  );
}

Modal.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  closeOnEsc: PropTypes.bool,
  showModalCloseIcon: PropTypes.bool,
  closeOnBackdrop: PropTypes.bool,
  blockScroll: PropTypes.bool
};

Modal.defaultProps = {
  children: null,
  size: 700,
  show: false,
  onClose: () => {},
  closeOnEsc: false,
  closeOnBackdrop: false,
  showModalCloseIcon: false,
  blockScroll: true
};

export default Modal;
