import PropTypes from 'prop-types';
import React from 'react';
import Text from './Text';

function NumberText({ number, digits, minFractions, maxFractions, suffix, round, ...rest }) {
  // const { currentLocale } = intl.options;
  const currentLocale = 'de-DE';
  let formatted = number;

  if (
    number === undefined ||
    Number.isNaN(number) ||
    !Number.isFinite(number) ||
    number === null ||
    number === ''
  ) {
    formatted = '-';
  } else {
    if (round === true) {
      formatted = number.toFixed(digits);
    }

    formatted = new Intl.NumberFormat(currentLocale, {
      maximumFractionDigits: maxFractions,
      minimumFractionDigits: minFractions
    }).format(number);
  }

  return (
    <Text {...rest}>
      {formatted} {suffix}
    </Text>
  );
}

NumberText.propTypes = {
  number: PropTypes.number,
  digits: PropTypes.number,
  minFractions: PropTypes.number,
  maxFractions: PropTypes.number,
  round: PropTypes.bool,
  suffix: PropTypes.any
};

NumberText.defaultProps = {
  number: null,
  digits: 2,
  minFractions: 2,
  maxFractions: 2,
  suffix: '',
  round: false
};

export default NumberText;
