import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledIcon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.colors.green};
  stroke-width: 2px;
`;

const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  background: ${(props) => props.theme.colors.white};
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  cursor: pointer;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};

  ${(props) =>
    props.disabled &&
    css`
      background: ${(props) => props.theme.colors.disabled};
      cursor: not-allowed;
    `};

  ${StyledIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

function Checkbox({ checked, onChange, width, height, disabled, name, ...props }) {
  return (
    <div style={{ height: '30px', display: 'inline-block', verticalAlign: 'middle' }}>
      <StyledHiddenCheckbox
        id={name}
        name={name}
        checked={checked}
        {...props}
        onChange={() => onChange(!checked)}
      />
      <StyledCheckbox
        onClick={() => onChange(!checked)}
        width={width}
        height={height}
        checked={checked}
        disabled={disabled}
      >
        <StyledIcon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </StyledIcon>
      </StyledCheckbox>
    </div>
  );
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string.isRequired
};

Checkbox.defaultProps = {
  onChange: () => {},
  disabled: false,
  checked: false,
  width: 26,
  height: 26
};

export default Checkbox;
