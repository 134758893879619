import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { faEdit as EditIcon, faTrash as RemoveIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ContentBox, Label, LoadingBox, Popup, Text } from '@looxr/components';
import { getLeakRoleOptions, getPressureRoleOptions } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { getPackageOptions } from '../../constants';
import { usePackages } from '../../hooks';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
`;

const StyledActivationBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;

  &:hover span {
    opacity: 0.75;
  }
`;

const StyledFloatButton = styled(Box)`
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : '10px')};
  right: 15px;

  background: ${(props) => props.theme.colors.white};
  cursor: pointer;
  color: ${(props) => props.theme.colors.purple};
  font-size: 16px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease;
  border-radius: 50%;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.35);
  width: 40px;
  height: 40px;

  &:hover {
    background: ${(props) => props.theme.colors.purple};
    color: ${(props) => props.theme.colors.white};

    svg {
      color: ${(props) => props.theme.colors.white};
      fill: ${(props) => props.theme.colors.white};
      * {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

function LoginBox({ type, userData, customerPackages, onCreate, onEdit, onDelete }) {
  const tn = useTranslation();

  const { getSelectablePackages } = usePackages();

  const [loading, setLoading] = useState(true);
  const [showDisableAccountPopup, setShowDisableAccountPopup] = useState(false);
  const [hasActiveLogin, setHasActiveLogin] = useState(false);
  const [wasDeleted, setWasDeleted] = useState(false);
  const [packageInfo, setPackageInfo] = useState({});
  const [showActions, setShowActions] = useState(false);
  const [selectablePackages, setSelectablePackages] = useState([]);

  useEffect(() => {
    // @TODO same problem as with the package box, make sure this information is not loaded when mounted or unmounted
    // maybe try to have the information outside and make this a "dumber" component
    const load = async () => {
      setLoading(true);
      const packageKey = type === 'leak' ? 'leakLogin' : 'pressureLogin';
      const info = {};
      let hasActiveLogin = false;

      // @TODO make sure user format is same for all even for looxr or dont load this information based on role or LOOXR
      if (userData && userData[packageKey] && userData[packageKey].package) {
        hasActiveLogin = true;

        info.validUntil = moment(userData[packageKey].validUntil.toDate()).format('DD.MM.YYYY');

        const roleOptions = type === 'leak' ? getLeakRoleOptions() : getPressureRoleOptions();
        const roleOption = roleOptions.find((role) => role.value === userData[packageKey].role);

        if (roleOption) {
          info.role = roleOption.label;
        }

        const packageRef = userData[packageKey].package;
        const customerPackage = customerPackages.find(
          (customerPackage) => customerPackage.id === packageRef.id
        );

        if (customerPackage) {
          info.name = getPackageName(customerPackage);
        }
      }

      const selectable = await getSelectablePackages(customerPackages, type);

      setSelectablePackages(selectable);
      setHasActiveLogin(hasActiveLogin);
      setPackageInfo(info);
      setLoading(false);
    };

    load();
  }, [userData]);

  const getPackageName = (customerPackage) => {
    const packageOption = getPackageOptions().find(
      (option) => option.value === customerPackage.type
    );
    return packageOption !== undefined ? packageOption.label : '-';
  };

  const disableLogin = () => {
    const data = { ...userData };

    if (type === 'leak') {
      data.leakLogin.package = null;
      data.logins.leak = false;
    }

    if (type === 'pressure') {
      data.pressureLogin.package = null;
      data.logins.pressure = false;
    }

    // @TODO needed ?
    // data.removedAt = null;
    setWasDeleted(true);
    setShowDisableAccountPopup(false);
    onDelete(data);
  };

  const onCreateClick = () => {
    if (selectablePackages.length > 0 && !wasDeleted) {
      onCreate(selectablePackages);
    }

    // @TODO route to customer so user can create package
  };

  const getActivationText = () => {
    if (wasDeleted) {
      return tn('admin.page.userForm.logins.wasDeletedNotice'); // 'Login entfernt';
    }

    if (selectablePackages.length > 0) {
      return tn('admin.page.userForm.logins.createLogin'); // 'Zugang einrichten';
    }

    return tn('admin.page.userForm.logins.noSelectablePackageNotice'); // 'Kein freies Paket verfügbar';
  };

  return (
    <ContentBox
      title={
        type === 'leak'
          ? tn('admin.page.userForm.logins.leakLogin')
          : tn('admin.page.userForm.logins.pressureLogin')
      }
      // title={type === 'leak' ? `Leckage Zugang` : `Druckluft Zugang`}
      marginTop={10}
      marginLeft={type === 'leak' ? 0 : 10}
      noPadding
      width="400px"
    >
      <LoadingBox
        loading={loading}
        renderChildren={!loading}
        display="flex"
        direction="column"
        paddingY={5}
        paddingX={5}
        height="300px"
        width="100%"
        onMouseEnter={() => setShowActions(true)}
        onMouseLeave={() => setShowActions(false)}
      >
        {hasActiveLogin ? (
          <>
            <StyledFormBox>
              <Box marginY={3} marginX={3} width={250}>
                <Label htmlFor="leakLogin.createdAt">
                  {tn('general.package')}
                  {/* Paket */}
                </Label>
                <Text>{packageInfo.name ? packageInfo.name : ''}</Text>
              </Box>
              <Box marginY={3} marginX={3} width={150}>
                <Label htmlFor="leakLogin.role">
                  {tn('general.role')}
                  {/* Paket */}
                </Label>
                <Text>{packageInfo.role ? packageInfo.role : ''}</Text>
              </Box>
              <Box marginY={3} marginX={3} width={150}>
                <Label htmlFor="leakLogin.validUntil">
                  {tn('general.validUntil')}
                  {/* Gültig bis */}
                </Label>
                <Text>{packageInfo.validUntil ? packageInfo.validUntil : ''}</Text>
              </Box>
            </StyledFormBox>

            <StyledFloatButton
              onClick={() => setShowDisableAccountPopup(true)}
              show={showActions}
              display="flex"
              justify="center"
              alignContent="center"
              paddingX={3}
              paddingY={3}
            >
              <FontAwesomeIcon icon={RemoveIcon} />
            </StyledFloatButton>
            <StyledFloatButton
              onClick={() => onEdit(selectablePackages)}
              show={showActions}
              display="flex"
              justify="center"
              alignContent="center"
              paddingX={3}
              paddingY={3}
              top={60}
            >
              <FontAwesomeIcon icon={EditIcon} />
            </StyledFloatButton>
          </>
        ) : (
          <StyledActivationBox onClick={onCreateClick}>
            <Text size="lg" color="purple">
              {getActivationText()}
            </Text>
          </StyledActivationBox>
        )}
      </LoadingBox>

      <Popup
        show={showDisableAccountPopup}
        title={tn('admin.popup.deleteLogin.title')} // "Account deaktivieren"
        message={tn('admin.popup.deleteLogin.text')} // "Möchten Sie diesen Account-Zugriff wirklich deaktivieren ?"
        confirmText={tn('admin.popup.deleteLogin.buttonYes')} // "Deaktivieren"
        abortText={tn('admin.popup.deleteLogin.buttonNo')} // "Abbrechen"
        confirmColor="green"
        abortColor="red"
        confirmAction={() => disableLogin()}
        abortAction={() => setShowDisableAccountPopup(false)}
      />
    </ContentBox>
  );
}

LoginBox.propTypes = {
  userData: PropTypes.any,
  customerPackages: PropTypes.array,
  type: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

LoginBox.defaultProps = {
  userData: {},
  customerPackages: []
};

export default LoginBox;
