import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';

const StyledNavigation = styled(Box)`
  position: ${(props) => (props.isSticky ? 'fixed' : 'relative')};
  top: ${(props) => (props.isSticky ? '0px' : null)};
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
`;

function Navigation({ children, sticky }) {
  return (
    <>
      <StyledNavigation
        isSticky={sticky}
        width="100%"
        background="white"
        display="flex"
        alignItems="left"
        justify="space-between"
        zIndex={60}
        height={75}
        noBorderRadius
      >
        {children}
      </StyledNavigation>
      {sticky && <Box height={75} />}
    </>
  );
}

Navigation.propTypes = {
  children: PropTypes.any,
  sticky: PropTypes.bool
};

Navigation.defaultProps = {
  children: null,
  sticky: true
};

export default Navigation;
