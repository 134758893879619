import moment from 'moment';

export const filterStartsWith = (filterVar, dataVar) => {
  if (dataVar !== undefined && dataVar !== null) {
    return dataVar.toLowerCase().startsWith(filterVar.toLowerCase());
  }

  return false;
};

export const filterContains = (filterVar, dataVar) => {
  if (dataVar !== undefined && dataVar !== null) {
    return dataVar.toLowerCase().includes(filterVar.toLowerCase());
  }

  return false;
};

export const filterEquals = (filterVar, dataVar) => {
  if (dataVar !== undefined && dataVar !== null) {
    if (typeof filterVar === 'string') {
      return dataVar.toLowerCase() === filterVar.toLowerCase();
    }
    return dataVar === filterVar;
  }

  return false;
};

export const filterBooleanEquals = (filterVar, dataVar) => {
  let boolVar = filterVar;
  if (dataVar !== undefined && dataVar !== null) {
    if (typeof filterVar === 'string') {
      if (filterVar === 'true') {
        boolVar = true;
      }

      if (filterVar === 'false') {
        boolVar = false;
      }
    }
    return dataVar === boolVar;
  }

  return false;
};

export const filterEqualsDate = (filterVar, dataVar) => {
  if (dataVar !== undefined && dataVar !== null) {
    const filterMoment = moment(filterVar).startOf('day');
    const dataMoment = moment(dataVar.toDate()).startOf('day');
    const isSame = filterMoment.isSame(dataMoment);
    return isSame;
  }

  return false;
};

export const filterIsInDateRange = (filterVar, dataVar) => {
  if (dataVar !== undefined && dataVar !== null) {
    const compareDate = moment(dataVar.toDate()).startOf('day');
    const startDate = moment(filterVar.from)
      .startOf('day')
      .subtract(1, 'days');
    const endDate = moment(filterVar.to)
      .startOf('day')
      .add(1, 'days');

    const isBetween = compareDate.isBetween(startDate, endDate, 'day');

    return isBetween;
  }

  return false;
};
