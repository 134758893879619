import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Popover from 'react-tiny-popover';
import styled from 'styled-components/macro';
import { faTh as AppIcon, faUserCircle as ProfileIcon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  HelpModalTree,
  LanguageSelect,
  Logo,
  NaviAppMenu,
  Navigation,
  NavigationItem,
  NavigationLink,
  Text
} from '@looxr/components';
import { CUSTOMER_TYPES, Collections, LOOXR_FIREBASE_ID, ROLES } from '@looxr/constants';
import { Translate, textSizes, useLanguage, useTranslation } from '@looxr/utils';
import { AppStateService, FirebaseService } from '../services';

const StyledAppMenuIcon = styled(Box)`
  font-size: 24px;
  color: ${(props) => props.theme.colors.grey};
  cursor: pointer;

  &.open,
  &:hover {
    color: ${(props) => props.theme.colors.green};
  }
`;

const StyledLinkAlike = styled(Text)`
  cursor: pointer;
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey};
  &:hover {
    color: ${(props) => props.theme.colors.green};
  }

  font-size: ${() => textSizes('xs')};

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    font-size: ${() => textSizes('sm')};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    font-size: ${() => textSizes('base')};
  }
`;

const StyledProfileIcon = styled(Box)`
  cursor: pointer;
  font-size: 22px;
`;

function Header() {
  const tn = useTranslation();
  const language = useLanguage();

  const [showAppMenu, setShowAppMenu] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const changeLocale = (locale) => {
    Translate.changeLanguage(locale);
    moment.locale(locale);

    const userDoc = AppStateService.user;
    const userData = { ...userDoc };

    delete userData.id;
    delete userData.ref;

    // Update User
    userData.language = locale;
    userDoc.language = locale;

    AppStateService.setUser(userDoc);
    FirebaseService.upsertDoc(userDoc.id, userData, Collections.user);
  };

  const handleScroll = useCallback(() => {
    if (showAppMenu) {
      setShowAppMenu(false);
    }
  }, [showAppMenu]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, false);

    return () => {
      document.removeEventListener('scroll', handleScroll, false);
    };
  }, [handleScroll]);

  const renderAppMenu = () => {
    const canSeeAdmin =
      AppStateService.user.role === ROLES.admin || AppStateService.user.role === ROLES.looxr;
    return <NaviAppMenu canSeeAdmin={canSeeAdmin} />;
  };

  return (
    <Navigation>
      <NavigationItem width="25%" justify="flex-start">
        {AppStateService.customer.type !== CUSTOMER_TYPES.looxr && (
          <NavigationLink navLink={true} linkTo="/" align="left">
            {tn('admin.navi.customerProfile')}
            {/* Kundenprofil */}
          </NavigationLink>
        )}

        {AppStateService.customer.type !== CUSTOMER_TYPES.customer && (
          <NavigationLink
            linkTo="/customer/list"
            align="left"
            navLink={true}
            isActiveCheck={(match, location) => {
              if (location.pathname.includes('/customer/')) {
                return true;
              }

              return false;
            }}
          >
            {tn('admin.navi.customers')}
            {/* Kunden */}
          </NavigationLink>
        )}

        {/* custom active check to have it active for user/list and user/edit ... */}
        <NavigationLink
          linkTo="/user/list"
          align="left"
          navLink={true}
          isActiveCheck={(match, location) => {
            if (location.pathname.includes('/user/')) {
              return true;
            }

            return false;
          }}
        >
          {tn('admin.navi.users')}
          {/* Benutzer */}
        </NavigationLink>

        {(AppStateService.isLOOXR() || AppStateService.customer.type === CUSTOMER_TYPES.oem) && (
          <NavigationLink
            linkTo="/component/list"
            align="left"
            navLink={true}
            isActiveCheck={(match, location) => {
              if (location.pathname.includes('/component/')) {
                return true;
              }

              return false;
            }}
          >
            {tn('admin.navi.components')}
          </NavigationLink>
        )}
      </NavigationItem>

      <NavigationItem noPadding width="50%">
        <Logo width={117} height={32} color="black" />
      </NavigationItem>

      <NavigationItem width="25%" justify="flex-end">
        <NavigationItem>
          <LanguageSelect currentLocale={language} onChange={(locale) => changeLocale(locale)} />
        </NavigationItem>

        <NavigationItem onClick={() => setShowHelpModal(true)}>
          <StyledLinkAlike>{tn('general.help')}</StyledLinkAlike>
        </NavigationItem>

        <NavigationLink linkTo="/logout" navLink={true}>
          {tn('general.logout')}
        </NavigationLink>

        <NavigationLink linkTo="/profile" navLink={true}>
          <StyledProfileIcon display="flex" alignItems="center" justify="center">
            <FontAwesomeIcon icon={ProfileIcon} />
          </StyledProfileIcon>
        </NavigationLink>

        <NavigationItem>
          <StyledAppMenuIcon
            display="flex"
            alignItems="center"
            justify="center"
            className={`${showAppMenu ? 'open' : null}`}
          >
            <Popover
              isOpen={showAppMenu}
              position="bottom"
              padding={20}
              windowBorderPadding={30}
              onClickOutside={() => setShowAppMenu(false)}
              content={renderAppMenu}
            >
              {/* Popover Plugin needs some real html element here */}
              <div
                style={{ display: 'flex' }}
                onClick={() => setShowAppMenu(true)}
                aria-hidden="true"
              >
                <FontAwesomeIcon icon={AppIcon} />
              </div>
            </Popover>
          </StyledAppMenuIcon>
        </NavigationItem>
      </NavigationItem>

      <HelpModalTree
        isVisible={showHelpModal}
        authCustomer={AppStateService.customer}
        looxrID={LOOXR_FIREBASE_ID}
        onClose={() => setShowHelpModal(!showHelpModal)}
        blockScroll={false}
      />
    </Navigation>
  );
}

export default Header;
