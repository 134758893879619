import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';

const StyledItem = styled(Box)``;

function NavigationItem({ children, noPadding, ...rest }) {
  return (
    <StyledItem
      display="flex"
      alignItems="center"
      justify="center"
      paddingX={noPadding ? null : 3}
      {...rest}
    >
      {children}
    </StyledItem>
  );
}

NavigationItem.propTypes = {
  children: PropTypes.any,
  noPadding: PropTypes.bool
};

NavigationItem.defaultProps = {
  children: null,
  noPadding: false
};

export default NavigationItem;
