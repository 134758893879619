import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';

const StyledItem = styled(Box)``;

function FooterItem({ children }) {
  return (
    <StyledItem display="flex" alignItems="center" justify="center" paddingX={3}>
      {children}
    </StyledItem>
  );
}

FooterItem.propTypes = {
  children: PropTypes.any
};

FooterItem.defaultProps = {
  children: null
};

export default FooterItem;
