import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  ContentBox,
  Info,
  Label,
  SelectRH as Select,
  Text,
  TextInputRH
} from '@looxr/components';
import {
  MEASURED_DEVICE_TYPES,
  NUMBER_PATTERN,
  getMeasuredDeviceTypeOptions
} from '@looxr/constants';
import { useTranslation } from '@looxr/utils';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function CustomerFormParams({ register, errors, watch, clearError, customerData }) {
  const tn = useTranslation();
  const [deviceType, setDeviceType] = useState(customerData.parameter.measuredDeviceType);
  const [deviceTypeChange, setDeviceTypeChange] = useState(false);

  const deviceTypeUpdate = watch('parameter.measuredDeviceType');

  useEffect(() => {
    if (
      deviceTypeUpdate !== customerData.parameter.measuredDeviceType &&
      customerData.parameter.measuredDeviceType !== ''
    ) {
      setDeviceTypeChange(true);
    }

    // clear errors because required is conditional
    clearError('parameter.airPressurePrice');
    clearError('parameter.powerFactor');
    clearError('parameter.electricPrice');
    clearError('parameter.emission');

    setDeviceType(deviceTypeUpdate);
  }, [deviceTypeUpdate]);

  const isParameterRequired = (parameter) => {
    const deviceConfig = MEASURED_DEVICE_TYPES[deviceType];
    return deviceConfig ? deviceConfig.neededParameter[parameter] : true;
  };

  const getPowerFactorUnitLabel = () => {
    const deviceConfig = MEASURED_DEVICE_TYPES[deviceType];
    let label = '[(kW/m³)min.]';

    if (deviceConfig) {
      if (deviceConfig.powerFactorInSec) {
        label = '[(kW/m³)sec.]';
      }
    }

    return label;
  };

  return (
    <>
      <ContentBox
        title={tn('admin.page.customerForm.parameter.title')} // "Parameter"
        marginTop={5}
      >
        <Box display="flex" direction="column">
          <StyledFormBox marginBottom={5}>
            <Box marginY={3} marginX={3} width={524}>
              <Box display="flex" direction="row">
                <Label
                  htmlFor="parameter.measuredDeviceType"
                  required
                  valid={errors?.parameter?.measuredDeviceType === undefined}
                >
                  {tn('general.measuredDeviceType')}
                </Label>
                <Info
                  infoText={tn('admin.formOptions.measureDeviceInfo')}
                  bubbleWidth={300}
                  align="right"
                />
              </Box>

              <Select
                disabled={customerData.archived}
                id="parameter.measuredDeviceType"
                name="parameter.measuredDeviceType"
                placeholder={tn('general.choose')} // "Bitte wählen"
                options={getMeasuredDeviceTypeOptions()}
                ref={register({ required: true })}
              />
            </Box>
          </StyledFormBox>

          {deviceTypeChange ? (
            <Box marginY={3} marginX={3} width={500}>
              <Text size="sm" color="purple">
                Bitte beachten Sie bei der Änderung des Messgeräts darauf, dass andere Parameter und
                ggfs. Einheiten für die Parameter benutzt werden können.
              </Text>
            </Box>
          ) : null}

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={250}>
              <Label
                htmlFor="operatingHours"
                required={isParameterRequired('operatingHours')}
                valid={errors?.parameter?.operatingHours === undefined}
              >
                {tn('general.operatingHours')} {tn('general.hoursPerYear')}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="parameter.operatingHours"
                name="parameter.operatingHours"
                type="number"
                ref={register({ required: true, pattern: NUMBER_PATTERN })}
              />
              {errors?.parameter?.operatingHours?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validNumberNotice')}
                  {/* Bitte eine gültige Zahl angeben */}
                </Text>
              )}
            </Box>
            <Box marginY={3} marginX={3} width={250}>
              <Label
                htmlFor="parameter.electricPrice"
                required={isParameterRequired('electricPrice')}
                valid={errors?.parameter?.electricPrice === undefined}
              >
                {tn('general.electricPrice')} €/kWh
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="parameter.electricPrice"
                name="parameter.electricPrice"
                type="number"
                ref={register({
                  validate: {
                    required: (value) =>
                      !value && isParameterRequired('electricPrice') ? 'required parameter' : true
                  },
                  pattern: NUMBER_PATTERN
                })}
              />
              {errors?.parameter?.electricPrice?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validNumberNotice')}
                </Text>
              )}
            </Box>
          </StyledFormBox>
          <StyledFormBox>
            <Box marginY={3} marginX={3} width={250}>
              <Label
                htmlFor="parameter.emission"
                required={isParameterRequired('emission')}
                valid={errors?.parameter?.emission === undefined}
              >
                {tn('general.emission')} kg/kWh
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="parameter.emission"
                name="parameter.emission"
                type="number"
                ref={register({
                  validate: {
                    required: (value) =>
                      !value && isParameterRequired('emission') ? 'required parameter' : true
                  },
                  pattern: NUMBER_PATTERN
                })}
              />
              {errors?.parameter?.emission?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validNumberNotice')}
                </Text>
              )}
            </Box>
            <Box marginY={3} marginX={3} width={250}>
              <Label
                htmlFor="parameter.powerFactor"
                required={isParameterRequired('powerFactor')}
                valid={errors?.parameter?.powerFactor === undefined}
              >
                {tn('general.powerFactor')} {getPowerFactorUnitLabel()}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="parameter.powerFactor"
                name="parameter.powerFactor"
                type="number"
                ref={register({
                  validate: {
                    required: (value) =>
                      !value && isParameterRequired('powerFactor') ? 'required parameter' : true
                  },
                  pattern: NUMBER_PATTERN
                })}
              />
              {errors?.parameter?.powerFactor?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validNumberNotice')}
                </Text>
              )}
            </Box>
          </StyledFormBox>
          <StyledFormBox>
            <Box marginY={3} marginX={3} width={250}>
              <Label
                htmlFor="parameter.airPressurePrice"
                required={isParameterRequired('airPressurePrice')}
                valid={errors?.parameter?.airPressurePrice === undefined}
              >
                {tn('general.airPressurePrice')} [€/1000m³]
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="parameter.airPressurePrice"
                name="parameter.airPressurePrice"
                type="number"
                ref={register({
                  validate: {
                    required: (value) =>
                      !value && isParameterRequired('airPressurePrice')
                        ? 'required parameter'
                        : true
                  },
                  pattern: NUMBER_PATTERN
                })}
              />
              {errors?.parameter?.airPressurePrice?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validNumberNotice')}
                </Text>
              )}
            </Box>
          </StyledFormBox>
        </Box>
      </ContentBox>
    </>
  );
}

CustomerFormParams.propTypes = {
  customerData: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any.isRequired,
  watch: PropTypes.any,
  clearError: PropTypes.any
};

CustomerFormParams.defaultProps = {
  customerData: null,
  errors: undefined,
  clearError: () => false,
  watch: () => false
};

export default CustomerFormParams;
