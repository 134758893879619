import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MAIL_PATTERN } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import Box from './Box';
import Button from './Button';
import Checkbox from './Checkbox';
import Label from './Label';
import Text from './Text';
import TextInput from './TextInput';

const StyledLink = styled.a`
  outline: none;
  text-decoration: none;
`;

function LicensePage({ onLicenseAccepted }) {
  const tn = useTranslation();

  const [acceptedLicense, setAcceptedLicense] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
  const [sendAsMail, setSendAsMail] = useState(false);
  const [sendToMail, setSendToMail] = useState('');
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (acceptedPrivacy && acceptedLicense) {
      if (sendAsMail) {
        const mailTest = new RegExp(MAIL_PATTERN);
        const validMail = sendToMail !== '' && mailTest.test(sendToMail);

        if (validMail) {
          setValid(true);
        } else {
          setValid(false);
        }
      } else {
        setValid(true);
      }
    } else {
      setValid(false);
    }
  }, [acceptedLicense, acceptedPrivacy, sendAsMail, sendToMail]);

  const acceptLicence = () => {
    onLicenseAccepted({ sendAsMail, sendToMail });
  };

  return (
    <Box
      display="flex"
      height="100vh"
      width="100%"
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Text size="3xl">{tn('general.license.title')}</Text>
      <Box width={600} marginTop={6}>
        <Text size="lg">{tn('general.license.text')}</Text>
      </Box>
      <Box marginTop={6}>
        <StyledLink href="https://www.looxr.de/datenschutz-lizenzvereinbarung/" target="_blank">
          <Text color="green" size="medium" bold center>
            looxr.de/datenschutz-lizenzvereinbarung
          </Text>
        </StyledLink>
      </Box>
      <Box width={600} marginTop={8}>
        <Box display="flex" direction="row" justify="space-between">
          <Label htmlFor="license">{tn('general.license.acceptLicenseLabel')}</Label>
          <Checkbox
            id="license"
            name="license"
            checked={acceptedLicense}
            onChange={(update) => setAcceptedLicense(update)}
          />
        </Box>
        <Box display="flex" direction="row" justify="space-between" marginTop={3}>
          <Label htmlFor="privacy">{tn('general.license.acceptPricacyLabel')}</Label>
          <Checkbox
            id="privacy"
            name="privacy"
            checked={acceptedPrivacy}
            onChange={(update) => setAcceptedPrivacy(update)}
          />
        </Box>
        <Box display="flex" direction="row" justify="space-between" marginTop={3}>
          <Label htmlFor="sendasmail">{tn('general.license.sendAsMailLabel')}</Label>
          <Checkbox
            id="sendasmail"
            name="sendasmail"
            checked={sendAsMail}
            onChange={(update) => setSendAsMail(update)}
          />
        </Box>
        <Box marginTop={6}>
          <TextInput
            id="sendtomail"
            name="sendtomail"
            value={sendToMail}
            onChange={(e) => setSendToMail(e.target.value)}
            placeholder={tn('general.license.sendToMailPlaceholder')} // "E-Mail eintragen"
            disabled={!sendAsMail}
          />
        </Box>
        <Box display="flex" justify="center" alignItems="center" marginTop={8}>
          <Button
            width={200}
            text={tn('general.license.buttonAccept')} // "Akzeptieren & Weiter"
            disabled={!valid}
            onClick={acceptLicence}
          />
        </Box>
      </Box>
    </Box>
  );
}

LicensePage.propTypes = {
  onLicenseAccepted: PropTypes.func.isRequired
};

export default LicensePage;
