import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Box, Button, Label, SelectRH as Select, Text, TextInput } from '@looxr/components';
import { getLeakRoleOptions, getPressureRoleOptions } from '@looxr/constants';
import { useLanguage, useTranslation } from '@looxr/utils';
import { getPackageOptions } from '../../constants';
import { FirebaseService } from '../../services';

const StyledPackageBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  padding: 30px 10px;
  margin-top: 15px;
  cursor: pointer;
  width: 100%;

  * {
    cursor: pointer;
  }

  & > * {
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.purple};
    background-color: ${(props) => props.theme.colors.purple};

    * {
      color: ${(props) => props.theme.colors.white} !important;
    }
  }
`;

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

const StyledFormButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button:first-child {
    margin-right: 20px;
  }
`;

function LoginForm({ onSave, onCancel, type, selectablePackages, customerPackages, userData }) {
  const tn = useTranslation();

  const [showPackageSelect, setShowPackageSelect] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [validUntilValue, setValidUntilValue] = useState(null);

  const { register, unregister, handleSubmit, errors, reset, setValue } = useForm({
    defaultValues: { role: null, validUntil: '' }
  });

  // Effect to register custom date input
  useEffect(() => {
    register({ name: 'validUntil' }, { required: true });

    return () => {
      unregister('validUntil');
    };
  }, [register]);

  // Effect to update initial data and set state
  useEffect(() => {
    const loginKey = type === 'leak' ? 'leakLogin' : 'pressureLogin';

    const date =
      userData[loginKey].validUntil && userData[loginKey].validUntil.toDate
        ? userData[loginKey].validUntil.toDate()
        : null;

    const packageRef = userData[loginKey].package;
    let customerPackage = null;
    let hasPackage = false;

    if (packageRef) {
      customerPackage = customerPackages.find(
        (customerPackage) => customerPackage.id === packageRef.id
      );
      if (customerPackage) {
        hasPackage = customerPackage !== null && customerPackage !== undefined;
      }
    }

    setValue('validUntil', date);
    setValidUntilValue(date);
    setSelectedPackage(customerPackage);
    setShowPackageSelect(!hasPackage);

    if (type === 'leak') {
      reset({ ...userData.leakLogin });
    }

    if (type === 'pressure') {
      reset({ ...userData.pressureLogin });
    }
  }, [userData]);

  const updateValidUntil = (date) => {
    // form update & trigger validation
    setValidUntilValue(date);
    setValue('validUntil', date, true);
  };

  const selectPackage = (customerPackage) => {
    const validUntilDate = customerPackage.validUntil.toDate();
    setValue('validUntil', validUntilDate, true);
    setValidUntilValue(validUntilDate);
    setSelectedPackage(customerPackage);
    setShowPackageSelect(false);
  };

  const getPackageName = (customerPackage) => {
    const packageOption = getPackageOptions().find(
      (option) => option.value === customerPackage.type
    );
    return packageOption !== undefined ? packageOption.label : '-';
  };

  const save = async (data) => {
    const login = {};
    const update = { ...userData };

    login.role = data.role;
    login.package = selectedPackage.ref;
    login.validUntil =
      data.validUntil.toDate !== undefined
        ? data.validUntil
        : FirebaseService.getTimestamp(data.validUntil);

    if (type === 'leak') {
      update.leakLogin = login;
    }

    if (type === 'pressure') {
      update.pressureLogin = login;
    }

    // @TODO needed and to be used ?
    update.logins[type] = true;

    onSave(update);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(save)} noValidate>
        {showPackageSelect ? (
          <Box>
            <Text color="grey" bold size="md">
              Paketauswahl
            </Text>
            <Box>
              {selectablePackages.map((customerPackage) => {
                const accountsInUse =
                  customerPackage.inUse !== undefined ? customerPackage.inUse : 0;
                const { maxAccounts } = customerPackage;
                const packageName = getPackageName(customerPackage);
                const validUntil = moment(customerPackage.validUntil.toDate()).format('DD.MM.YYYY');

                return (
                  <StyledPackageBox
                    key={customerPackage.id}
                    onClick={() => selectPackage(customerPackage)}
                  >
                    <Box width="60%">
                      <Text bold color="purple">
                        {packageName}
                      </Text>
                    </Box>
                    <Box width="25%">
                      <Text align="center">{validUntil}</Text>
                    </Box>
                    <Box width="15%">
                      <Text align="center">{`${accountsInUse}/${maxAccounts}`}</Text>
                    </Box>
                  </StyledPackageBox>
                );
              })}
            </Box>
          </Box>
        ) : (
          <StyledFormBox>
            <Box paddingY={3} paddingX={3} width="100%">
              <Label htmlFor="package">{tn('general.package')}</Label>
              <Text>{getPackageName(selectedPackage)}</Text>
            </Box>
            <Box paddingY={3} paddingX={3} width="100%">
              <Label htmlFor="role" required={true} valid={errors?.role === undefined}>
                {tn('general.role')}
              </Label>
              <Select
                id="role"
                name="role"
                options={type === 'leak' ? getLeakRoleOptions() : getPressureRoleOptions()}
                placeholder={tn('general.choose')} // "Bitte wählen"
                ref={register({ required: true })}
              />
            </Box>
          </StyledFormBox>
        )}

        <Box display="flex" justify="center" align="center">
          <StyledFormButtonBox paddingX={3} paddingY={5} width={300} marginTop={10}>
            <Button
              text={tn('general.save')} // "Speichern"
              type="submit"
            />
            <Button
              text={tn('general.cancel')} // "Abbrechen"
              background="red"
              onClick={() => onCancel()}
            />
          </StyledFormButtonBox>
        </Box>
      </form>
    </Box>
  );
}

LoginForm.propTypes = {
  type: PropTypes.string,
  userData: PropTypes.any,
  selectablePackages: PropTypes.array,
  customerPackages: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

LoginForm.defaultProps = {
  type: 'leak',
  userData: null,
  selectablePackages: [],
  customerPackages: [],
  onSave: () => {},
  onCancel: () => {}
};

export default LoginForm;
