import Translate from './Translate';

function useTranslation() {
  const tn = (key, params) => {
    return Translate.tn(key, params);
  };

  return tn;
}

export default useTranslation;
