import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { faLinkedin as LinkedInIcon, faXing as XingIcon } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Footer, FooterLink } from '@looxr/components';
import { CUSTOMER_TYPES, LOOXR_FIREBASE_ID } from '@looxr/constants';
import { useLanguage, useTranslation } from '@looxr/utils';
import { Header } from './components';
import {
  ComponentList,
  CustomerForm,
  CustomerList,
  CustomerProfile,
  Logout,
  NoMatch,
  Profile,
  Report,
  UserForm,
  UserList
} from './pages';
import { AppStateService } from './services';

export default function Routes() {
  const language = useLanguage();
  const tn = useTranslation();

  const renderWhenCustomerType = ({ Component, props, validCustomerTypes }) => {
    let render = false;

    const authCustomer = AppStateService.customer;

    if (AppStateService.isLOOXR()) {
      render = true;
    }

    if (validCustomerTypes.includes(authCustomer.type)) {
      render = true;
    }

    if (render) {
      return <Component currentLocale={language} {...props} />;
    }

    return null;
  };

  return (
    <Router>
      <Header />
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => {
            const authUser = AppStateService.user;

            if (authUser) {
              return authUser.customer.id === LOOXR_FIREBASE_ID ? (
                <Redirect to="/customer/list" />
              ) : (
                <CustomerProfile {...props} />
              );
            }

            return null;
          }}
        />

        <Route
          path="/component/list"
          render={(props) => {
            return renderWhenCustomerType({
              Component: ComponentList,
              props,
              validCustomerTypes: [CUSTOMER_TYPES.oem]
            });
          }}
        />

        <Route
          path="/customer/list"
          render={(props) => {
            return renderWhenCustomerType({
              Component: CustomerList,
              props,
              validCustomerTypes: [CUSTOMER_TYPES.service, CUSTOMER_TYPES.oem]
            });
          }}
        />

        <Route
          path="/customer/report/:id"
          render={(props) => {
            return renderWhenCustomerType({
              Component: Report,
              props,
              validCustomerTypes: []
            });
          }}
        />

        <Route
          path="/customer/:id"
          render={(props) => {
            return renderWhenCustomerType({
              Component: CustomerForm,
              props,
              validCustomerTypes: [CUSTOMER_TYPES.service, CUSTOMER_TYPES.oem]
            });
          }}
        />

        <Route
          path="/user/list"
          render={(props) => <UserList currentLocale={language} {...props} />}
        />
        <Route
          path="/user/:id"
          render={(props) => <UserForm currentLocale={language} {...props} />}
        />

        {/* @TODO not for looxr */}
        <Route
          path="/profile"
          exact
          render={(props) => <Profile currentLocale={language} {...props} />}
        />

        <Route
          path="/logout"
          exact
          render={(props) => <Logout currentLocale={language} {...props} />}
        />
        <Route path="*">
          <NoMatch currentLocale={language} />
        </Route>
      </Switch>
      <Footer>
        <Box display="flex" alignItems="center" justify="flex-start">
          <FooterLink linkTo="https://www.looxr.de/impressum">
            {tn('general.footer.imprint')}
          </FooterLink>
          <FooterLink linkTo="https://www.looxr.de/j/privacy">
            {tn('general.footer.privacy')}
          </FooterLink>
          <FooterLink linkTo="https://www.looxr.de/agb/">{tn('general.footer.agb')}</FooterLink>
        </Box>

        <Box display="flex" alignItems="center" justify="flex-end">
          <FooterLink linkTo="https://www.linkedin.com/company/looxr">
            <FontAwesomeIcon icon={LinkedInIcon} size="lg" />
          </FooterLink>
          <FooterLink linkTo="https://www.xing.com/pages/looxrgmbh">
            <FontAwesomeIcon icon={XingIcon} size="lg" />
          </FooterLink>
        </Box>
      </Footer>
    </Router>
  );
}
