import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, Text } from '@looxr/components';
import { CUSTOMER_TYPES } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { AppStateService } from '../../services';

const StyledMoreMenu = styled(Box)``;

const StyledMoreMenuItem = styled(Box)`
  cursor: pointer;
  span {
    cursor: pointer;
    color: ${(props) => props.theme.colors.grey};
    font-size: 16px;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.purple};
    span {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

// @TODO create config to render more menu with static names to reference
function CustomerMoreMenu({ data, onItemClick }) {
  const tn = useTranslation();

  return (
    <StyledMoreMenu
      background="white"
      display="flex"
      direction="column"
      justify="left"
      alignItems="left"
      noElevation={false}
    >
      {!data?.archived &&
        (data?.type === CUSTOMER_TYPES.oem || data?.type === CUSTOMER_TYPES.service) && (
          <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('showtree')}>
            <Text>
              {tn('admin.page.customerForm.moreMenu.showTree')}
              {/* Baumstruktur anzeigen */}
            </Text>
          </StyledMoreMenuItem>
        )}

      {!data?.archived && (
        <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('adduser')}>
          <Text>
            {tn('admin.page.customerForm.moreMenu.addUser')}
            {/* Neuen Benutzer hinzufügen */}
          </Text>
        </StyledMoreMenuItem>
      )}

      {!data?.archived && (
        <StyledMoreMenuItem
          paddingX={3}
          paddingY={3}
          onClick={() => onItemClick('showUsersOfCustomer')}
        >
          <Text>
            {tn('admin.page.customerForm.moreMenu.showUser')}
            {/* Benutzer anzeigen */}
          </Text>
        </StyledMoreMenuItem>
      )}

      {!data?.archived &&
        (data?.type === CUSTOMER_TYPES.oem || data?.type === CUSTOMER_TYPES.service) && (
          <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('addcustomer')}>
            <Text>
              {tn('admin.page.customerForm.moreMenu.addCustomer')}
              {/* Neuen Kunden hinzufügen */}
            </Text>
          </StyledMoreMenuItem>
        )}

      {!data?.archived && AppStateService.isLOOXR() && (
        <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('changeparent')}>
          <Text>
            {tn('admin.page.customerForm.moreMenu.changeParent')}
            {/* Anderen OEM/Dienstleister zuweisen */}
          </Text>
        </StyledMoreMenuItem>
      )}

      {!data?.archived && AppStateService.isLOOXR() && (
        <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('generatereport')}>
          <Text>{tn('admin.page.customerForm.moreMenu.generateReport')}</Text>
        </StyledMoreMenuItem>
      )}

      {!data?.archived && (
        <StyledMoreMenuItem
          paddingX={3}
          paddingY={3}
          onClick={() => onItemClick('disablecustomer')}
        >
          <Text>
            {tn('admin.page.customerForm.moreMenu.archiveCustomer')}
            {/* Kunde archivieren */}
          </Text>
        </StyledMoreMenuItem>
      )}

      {data?.archived && (
        <StyledMoreMenuItem paddingX={3} paddingY={3} onClick={() => onItemClick('enablecustomer')}>
          <Text>
            {tn('admin.page.customerForm.moreMenu.activateCustomer')}
            {/* Kunde aktivieren */}
          </Text>
        </StyledMoreMenuItem>
      )}
    </StyledMoreMenu>
  );
}

CustomerMoreMenu.propTypes = {
  data: PropTypes.any,
  onItemClick: PropTypes.func
};

CustomerMoreMenu.defaultProps = {
  data: null,
  onItemClick: () => {}
};

export default CustomerMoreMenu;
