import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, ContentBox, Label, Text, TextAreaRH, TextInputRH } from '@looxr/components';
import { URL_PATTERN } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function CustomerFormPrivacy({ register, errors, customerData }) {
  const tn = useTranslation();

  return (
    <>
      <ContentBox
        title={tn('admin.page.customerForm.privacySettings.title')} // "Datenschutz & Nutzerbestimmung"
        marginTop={5}
      >
        <Box display="flex" direction="column">
          <StyledFormBox>
            <Box marginY={3} marginX={3} width={524}>
              <Label htmlFor="privacy.agb" required valid={errors?.privacy?.agb === undefined}>
                {tn('admin.page.customerForm.privacySettings.agbUrl')}
                {/* AGB URL */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="privacy.agb"
                name="privacy.agb"
                ref={register({
                  required: true,
                  pattern: URL_PATTERN
                })}
              />
              {errors?.privacy?.agb?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validURLNotice')}
                  {/* Bitte eine gültige URL angeben */}
                </Text>
              )}
            </Box>
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={524}>
              <Label
                htmlFor="privacy.dataprotection"
                required
                valid={errors?.privacy?.dataprotection === undefined}
              >
                {tn('admin.page.customerForm.privacySettings.dataProtectionUrl')}
                {/* Datenschutz URL */}
              </Label>
              <TextInputRH
                disabled={customerData.archived}
                id="privacy.dataprotection"
                name="privacy.dataprotection"
                ref={register({ required: true, pattern: URL_PATTERN })}
              />
              {errors?.privacy?.dataprotection?.type === 'pattern' && (
                <Text size="sm" color="red">
                  {tn('general.validation.validURLNotice')}
                </Text>
              )}
            </Box>
          </StyledFormBox>

          <StyledFormBox>
            <Box marginY={3} marginX={3} width={524}>
              <Label
                htmlFor="privacy.termsofuse"
                required
                valid={errors?.privacy?.termsofuse === undefined}
              >
                {tn('admin.page.customerForm.privacySettings.termsOfUse')}
                {/* Nutzungsbedingungen */}
              </Label>
              <TextAreaRH
                disabled={customerData.archived}
                id="privacy.termsofuse"
                name="privacy.termsofuse"
                rows={8}
                ref={register({ required: true })}
              />
            </Box>
          </StyledFormBox>
        </Box>
      </ContentBox>
    </>
  );
}

CustomerFormPrivacy.propTypes = {
  customerData: PropTypes.any,
  errors: PropTypes.any,
  register: PropTypes.any.isRequired
};

CustomerFormPrivacy.defaultProps = {
  customerData: null,
  errors: undefined
};

export default CustomerFormPrivacy;
