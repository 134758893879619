import { Collections, LOOXR_FIREBASE_ID } from '@looxr/constants';
import { AppStateService, FirebaseService } from '../services';
import { extractIdsFromChildMap } from '../utils';
import useLoadCustomers from './useLoadCustomers';

function useLoadUsers() {
  const { getCustomerByIds } = useLoadCustomers();

  const getUserById = async (id) => {
    try {
      const userRef = await FirebaseService.ref
        .firestore()
        .collection(Collections.user)
        .doc(id)
        .get();

      return userRef;
    } catch (error) {
      console.log('Error getting user data');
      console.log(error);
      // @TODO create error mapping
      // not found
      // no permission
      return false;
    }
  };

  const getUserByUserName = async (username) => {
    try {
      const userRef = await FirebaseService.ref
        .firestore()
        .collection(Collections.user)
        .where('username', '==', username)
        .get();

      return userRef;
    } catch (error) {
      console.log('Error getting user data');
      console.log(error);
      return false;
    }
  };

  const getUsersForLOOXR = async () => {
    try {
      // @TODO load user based on hierarchy of loaded user/customer
      const snapshot = await FirebaseService.getUsers();
      const docs = [];
      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push({
          id: doc.id,
          ref: doc.ref,
          ...docData
        });
      });

      const loadCustomerOfUser = docs.map(async (doc) => {
        const customerSnapshot = await doc.customer.get();
        // @TODO fix it
        // eslint-disable-next-line no-param-reassign
        doc.customerData = customerSnapshot.data();

        return doc;
      });

      const docsWithCustomerData = await Promise.all(loadCustomerOfUser);

      return docsWithCustomerData;
    } catch (error) {
      console.log('Error getting user data');
      console.log(error);
      return [];
    }
  };

  const getUsersForOther = async () => {
    const customerData = AppStateService.customer;

    try {
      // @TODO load user based on hierarchy of loaded user/customer
      const snapshot = await FirebaseService.getUsersForCustomer(customerData.ref);
      const docs = [];
      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push({
          id: doc.id,
          ref: doc.ref,
          customerData,
          ...docData
        });
      });

      const { childMap } = customerData;
      const childIds = extractIdsFromChildMap(childMap);

      const childCustomers = await getCustomerByIds(childIds);
      const childUserSnapshots = [];

      for (let i = 0; i < childCustomers.length; i += 1) {
        const childCustomer = childCustomers[i];
        childUserSnapshots.push(FirebaseService.getUsersForCustomer(childCustomer.ref));
      }

      const childSnapshots = await Promise.all(childUserSnapshots);

      childSnapshots.map((snapshot) => {
        snapshot.forEach((doc) => {
          const docData = doc.data();
          const childCustomer = childCustomers.filter(
            (childCustomer) => childCustomer.id === docData.customer.id
          )[0];

          docs.push({
            id: doc.id,
            ref: doc.ref,
            customerData: childCustomer,
            ...docData
          });
        });

        return true;
      });

      return docs;
    } catch (error) {
      console.log('Error getting user data');
      console.log(error);
      return [];
    }
  };

  const getUsersByCustomer = async (customerRef) => {
    try {
      const userSnapshot = await FirebaseService.ref
        .firestore()
        .collection(Collections.user)
        .where('customer', '==', customerRef)
        .get();

      return userSnapshot;
    } catch (error) {
      console.log('Error getting user by customer data');
      console.log(error);
      return false;
    }
  };

  const getUsers = async () => {
    if (AppStateService.customer.id === LOOXR_FIREBASE_ID) {
      return getUsersForLOOXR();
    }

    return getUsersForOther();
  };

  return {
    getUsersByCustomer,
    getUserById,
    getUserByUserName,
    getUsers
  };
}

export default useLoadUsers;
