import { useEffect, useState } from 'react';
import Translate from './Translate';

function useLanguage(handler) {
  const [language, setLanguage] = useState('de');

  useEffect(() => {
    setLanguage(
      Translate.locale !== undefined && Translate.locale !== null ? Translate.locale : 'en'
    );

    const sub = Translate.registerLanguageChangeListener((newLangKey) => {
      setLanguage(newLangKey);
    });

    return () => sub.remove();
  }, [handler]);

  return language;
}

export default useLanguage;
