import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import Link from './Link';

const StyledItem = styled(Box)`
  a {
    color: ${(props) => props.theme.colors.grey};
    font-weight: 500;
    text-decoration: none;
  }

  a.active,
  a:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.green};
  }
`;

function FooterLink({ children, linkTo }) {
  return (
    <StyledItem display="flex" alignItems="center" justify="center" paddingX={3}>
      <Link href={linkTo}>{children}</Link>
    </StyledItem>
  );
}

FooterLink.propTypes = {
  children: PropTypes.any,
  linkTo: PropTypes.string
};

FooterLink.defaultProps = {
  children: null,
  linkTo: ''
};

export default FooterLink;
