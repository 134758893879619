import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { textSizes } from '@looxr/utils';
import Box from './Box';
import Link from './Link';

const StyledItem = styled(Box)`
  a {
    color: ${(props) =>
      props.color !== '' ? props.theme.colors[props.color] : props.theme.colors.grey};
    font-weight: 500;
    text-decoration: none;
    font-size: ${() => textSizes('xs')};

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      font-size: ${() => textSizes('sm')};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      font-size: ${() => textSizes('base')};
    }
  }

  a > * {
    color: ${(props) =>
      props.color !== '' ? props.theme.colors[props.color] : props.theme.colors.grey};
    font-weight: 500;
    text-decoration: none;
  }

  a.active,
  a:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.green};
  }

  a.active > *,
  a:hover > * {
    text-decoration: none;
    color: ${(props) => props.theme.colors.green};
  }
`;

function NavigationLink({ id, color, children, linkTo, onClick, isActiveCheck, ...rest }) {
  return (
    <StyledItem
      id={id}
      color={color}
      display="flex"
      alignItems="center"
      justify="center"
      paddingX={3}
    >
      <Link
        navLink={rest.navLink}
        href={linkTo}
        onClick={onClick}
        isActiveCheck={isActiveCheck}
        {...rest}
      >
        {children}
      </Link>
    </StyledItem>
  );
}

NavigationLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  children: PropTypes.any,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  isActiveCheck: PropTypes.func
};

NavigationLink.defaultProps = {
  id: null,
  color: '',
  children: null,
  linkTo: null,
  onClick: () => {},
  isActiveCheck: null
};

export default NavigationLink;
