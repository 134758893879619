import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, Text } from '@looxr/components';
import { useTranslation } from '@looxr/utils';

const StyledTextField = styled.input`
  color: ${(props) => props.theme.colors.black};
  outline: 0;
  width: 100%;
  padding: 10px;

  &:disabled {
    background-color: ${(props) => props.theme.colors.disabled};
    cursor: not-allowed;
  }
`;

const StyledUploadOverlay = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  color: ${(props) => props.theme.colors.black};
  outline: 0;
  width: 100%;
  border-radius: 4px;
  padding: 7px;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const StyledUploadInput = styled(Box)`
  position: relative;
`;

function UploadInput({ disabled, id, name, onChange, placeholder, accept }) {
  const tn = useTranslation();

  const onFileChange = (event) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const src = e.target.result;

      // if (src.indexOf('data:image/') >= 0) {
      onChange(src);
      // }
    };

    // read the image file as a data URL.
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <StyledUploadInput>
      <StyledTextField
        id={id}
        name={name}
        autoComplete="off"
        disabled={disabled}
        placeholder={placeholder}
        type="file"
        accept={accept}
        onChange={onFileChange}
      />
      <StyledUploadOverlay>
        <Text>
          {tn('general.chooseImage')}
          {/* Bild auswählen */}
        </Text>
      </StyledUploadOverlay>
    </StyledUploadInput>
  );
}

UploadInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  accept: PropTypes.string
};

UploadInput.defaultProps = {
  disabled: false,
  name: '',
  placeholder: '',
  accept: 'image/*',
  onChange: () => {}
};

export default UploadInput;
