import * as d3 from 'd3';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Box } from '@looxr/components';

const StyledTreeGraph = styled.svg`
  overflow: hidden;
  min-height: 500px;

  .node circle {
    fill: #fff;
    stroke: ${(props) => props.theme.colors.purple};
    stroke-width: 2px;
  }

  .node text {
    font-size: 14px;
    cursor: pointer;
  }

  .node--leaf {
    cursor: pointer;
  }

  .link {
    fill: none;
    stroke: ${(props) => props.theme.colors.borderColor};
    stroke-width: 2px;
  }
`;

function TreeGraph({ data }) {
  const graphContainer = useRef();
  const graphSVG = useRef();

  useEffect(() => {
    if (data && graphSVG && graphSVG.current) {
      const tree = (data) => {
        const root = d3.hierarchy(data);
        root.dx = 10;
        root.dy = 500 / (root.height + 1);
        return d3.tree().nodeSize([root.dx, root.dy])(root);
      };

      const root = tree(data);

      let x0 = Infinity;
      let x1 = -x0;
      root.each((d) => {
        if (d.x > x1) x1 = d.x;
        if (d.x < x0) x0 = d.x;
      });

      const svg = d3.select(graphSVG.current).attr('viewBox', [0, 0, 500, x1 - x0 + root.dx * 2]);

      const g = svg
        .append('g')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .attr('transform', `translate(${root.dy / 3},${root.dx - x0})`);

      g.append('g')
        .attr('fill', 'none')
        .attr('stroke', '#555')
        .attr('stroke-opacity', 0.4)
        .attr('stroke-width', 1.5)
        .selectAll('path')
        .data(root.links())
        .join('path')
        .attr(
          'd',
          d3
            .linkHorizontal()
            .x((d) => d.y)
            .y((d) => d.x)
        );

      const node = g
        .append('g')
        .attr('stroke-linejoin', 'round')
        .attr('stroke-width', 3)
        .selectAll('g')
        .data(root.descendants())
        .join('g')
        .attr('transform', (d) => `translate(${d.y},${d.x})`);

      node
        .append('circle')
        .attr('fill', (d) => (d.children ? '#841d64' : '#841d64'))
        .attr('r', 2.5);

      node
        .append('text')
        .attr('dy', '0.31em')
        .attr('x', (d) => (d.children ? -6 : 6))
        .attr('text-anchor', (d) => (d.children ? 'end' : 'start'))
        .text((d) => d.data.name)
        .clone(true)
        .lower()
        .attr('stroke', 'white');

      svg.call(
        d3
          .zoom()
          .extent([
            [0, 0],
            [500, x1 - x0 + root.dx * 2]
          ])
          .scaleExtent([0.2, 8])
          .on('zoom', () => {
            g.attr('transform', d3.event.transform);
          })
      );

      svg.node();
    }
  }, [data, graphSVG]);

  return (
    <Box
      background="white"
      display="flex"
      minHeight="75vh"
      width="100%"
      alignItems="center"
      justify="center"
      noElevation
    >
      <div style={{ width: '100%', minHeight: '500px' }} ref={graphContainer}>
        <StyledTreeGraph id="tree-graph" ref={graphSVG} />
      </div>
    </Box>
  );
}

TreeGraph.propTypes = {
  data: PropTypes.any
  // width: PropTypes.number,
  // height: PropTypes.number
};

TreeGraph.defaultProps = {
  data: {}
  // width: 650,
  // height: 500
};

export default TreeGraph;
