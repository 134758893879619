import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Box, Button, Label, LoadingBox, TextInputRH } from '@looxr/components';
import { Collections } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { FirebaseService } from '../../services';

const StyledFormButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  button:first-child {
    margin-right: 20px;
  }
`;

const StyledFormBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

function ComponentForm({ component, onSave, onCancel }) {
  const tn = useTranslation();

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {}
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (component) {
      const data = { ...component };

      delete data.id;
      delete data.ref;

      reset({ ...data });
    } else {
      reset({});
    }
  }, [component]);

  /**
   * Save customer form data
   * @param {*} data
   */
  const save = async (data) => {
    const hasErrors = Object.keys(errors).length > 0;

    if (!hasErrors) {
      setLoading(true);

      let id = null;

      const update = { ...data };

      if (component && component.id) {
        id = component.id;
      }

      await FirebaseService.upsertDoc(id, update, Collections.component);
      setLoading(false);

      onSave();
    }
  };

  return (
    <LoadingBox
      loading={loading}
      renderChildren={!loading}
      height="100%"
      width="100%"
      minHeight={300}
    >
      <form onSubmit={handleSubmit(save)} noValidate>
        <Box display="flex" direction="row" width="100%">
          <StyledFormBox width="100%">
            <Box marginY={3} marginX={3} width="100%">
              <Label htmlFor="articleNr" required valid={errors.articleNr === undefined}>
                {tn('admin.page.componentForm.articleNr')}
              </Label>
              <TextInputRH id="articleNr" name="articleNr" ref={register({ required: true })} />
            </Box>
            <Box marginY={3} marginX={3} width="100%">
              <Label htmlFor="description1" required valid={errors.description1 === undefined}>
                {tn('admin.page.componentForm.description')} 1
              </Label>
              <TextInputRH
                id="description1"
                name="description1"
                ref={register({ required: true })}
              />
            </Box>
            <Box marginY={3} marginX={3} width="100%">
              <Label htmlFor="description2" required valid={errors.description2 === undefined}>
                {tn('admin.page.componentForm.description')} 2
              </Label>
              <TextInputRH
                id="description2"
                name="description2"
                ref={register({ required: true })}
              />
            </Box>
          </StyledFormBox>
        </Box>
        <StyledFormButtonBox paddingX={0} paddingY={5} width="100%" marginTop={10}>
          <Button
            text={tn('general.save')} // "Speichern"
            type="submit"
          />
          <Button
            text={tn('general.cancel')} // "Abbrechen"
            background="red"
            onClick={() => onCancel()}
          />
        </StyledFormButtonBox>
      </form>
    </LoadingBox>
  );
}

ComponentForm.propTypes = {
  component: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

ComponentForm.defaultProps = {
  component: null,
  onSave: () => {},
  onCancel: () => {}
};

export default ComponentForm;
