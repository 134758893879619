import { useCallback, useEffect, useState } from 'react';
import { CUSTOMER_STORAGE_KEY } from '../constants';
import StorageService from '../services/StorageService';

function useCustomerFilter() {
  const [filter, setFilter] = useState({});
  const [loadingFilter, setLoadingFilter] = useState(true);

  const getDefaultFilter = useCallback(() => {
    return {
      tag: '',
      name: '',
      zip: '',
      city: '',
      country: '',
      type: '',
      isArchived: 'false'
    };
  }, []);

  // Effect to load filter from local storage or use default filter
  useEffect(() => {
    const load = async () => {
      let initialFilter = getDefaultFilter();

      const storageFilter = await loadFilter();

      if (storageFilter !== null) {
        initialFilter = storageFilter;
      }

      setFilter(initialFilter);
      setLoadingFilter(false);
    };

    load();
  }, [getDefaultFilter]);

  const resetFilter = () => {
    const defaultFilter = getDefaultFilter();
    setFilter(defaultFilter);

    StorageService.remove(CUSTOMER_STORAGE_KEY);
  };

  const updateFilter = (data) => {
    const update = { ...filter, ...data };
    setFilter(update);
    saveFilter(update);
  };

  const updateFilterField = (key, data) => {
    const update = { ...filter };
    update[key] = data;

    setFilter(update);
  };

  const storeFilter = () => {
    saveFilter({ ...filter });
  };

  const loadFilter = async () => {
    const storageFilter = await StorageService.getObject(CUSTOMER_STORAGE_KEY);
    return storageFilter;
  };

  const saveFilter = (data) => {
    const toSave = { ...data };
    StorageService.saveObject(CUSTOMER_STORAGE_KEY, toSave);
  };

  return {
    filter,
    loadingFilter,
    resetFilter,
    updateFilter,
    updateFilterField,
    storeFilter
  };
}

export default useCustomerFilter;
