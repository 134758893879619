import React, { useEffect, useState } from 'react';
import { faTrash as DeleteIcon } from '@fortawesome/free-solid-svg-icons';
import {
  Alert,
  Banner,
  Box,
  ContentBox,
  LoadingOverlay,
  Modal,
  Popup,
  SortTable,
  TextInput
} from '@looxr/components';
import { LOOXR_DUMMY_COMPONENT_ID } from '@looxr/constants';
import { useTranslation } from '@looxr/utils';
import { getComponentListConfig } from '../../constants';
import { AppStateService, FirebaseService } from '../../services';
import ComponentForm from './ComponentForm';

function ComponentList() {
  const tn = useTranslation();

  const [loading, setLoading] = useState(true);
  const [components, setComponents] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [componentToEdit, setComponentToEdit] = useState(null);
  const [componentToDelete, setComponentToDelete] = useState(null);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showNotDeleteablePopup, setShowNotDeleteablePopup] = useState(false);

  const [showComponentForm, setShowComponentForm] = useState(false);

  const [query, setQuery] = useState('');

  // Effect to load initial data
  useEffect(() => {
    const updateComponents = async (snapshot) => {
      if (components.length === 0) {
        setLoading(true);
      }

      let componentDocs = [];

      snapshot.forEach((doc) => {
        componentDocs.push({
          id: doc.id,
          ref: doc.ref,
          ...doc.data()
        });
      });

      componentDocs = componentDocs.filter((c) => c.id !== LOOXR_DUMMY_COMPONENT_ID);

      setComponents(componentDocs);
      setLoading(false);
    };
    const unsub = FirebaseService.getComponentQuery().onSnapshot(updateComponents);

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (query && query.length > 1) {
      const filteredDocs = [...components].filter((component) => {
        let matches = false;

        if (
          component.articleNr.includes(query) ||
          component.description1.includes(query) ||
          component.description2.includes(query)
        ) {
          matches = true;
        }
        return matches;
      });
      setFiltered(filteredDocs);
    } else {
      setFiltered(components);
    }
  }, [components, query]);

  const updateQuery = (update) => {
    setQuery(update);
  };

  const editComponent = (data) => {
    setComponentToEdit(data);
    setShowComponentForm(true);
  };

  const handleActionClick = ({ key, data }) => {
    if (key === 'delete') {
      setComponentToDelete(data);
      setShowDeletePopup(true);
    }
  };

  const deleteComponent = async () => {
    const toDelete = { ...componentToDelete };
    let canBeDeleted = true;

    setShowDeletePopup(false);
    setLoading(true);
    setComponentToDelete(null);

    const leaks = await FirebaseService.getLeaksByComponent(toDelete);

    if (leaks.length > 0) {
      canBeDeleted = false;
    }

    if (canBeDeleted) {
      const leaksRepair = await FirebaseService.getLeaksByRepairComponent(toDelete);
      if (leaksRepair.length > 0) {
        canBeDeleted = false;
      }
    }

    if (canBeDeleted) {
      await FirebaseService.deleteComponent(componentToDelete);
      setLoading(false);
    } else {
      setLoading(false);
      setShowNotDeleteablePopup(true);
    }
  };

  return (
    <>
      <Banner text={tn('admin.page.componentList.headline')} />
      <Box paddingX={10} paddingY={10} minHeight="calc(100vh - 143px)">
        <ContentBox width={500} title="Filter">
          <Box width={450}>
            <TextInput
              placeholder={tn('admin.page.componentList.placeholder')}
              id="filter-query"
              name="filter-query"
              value={query}
              onChange={(e) => updateQuery(e.currentTarget.value)}
            />
          </Box>
        </ContentBox>

        <ContentBox
          title={tn('admin.page.componentList.table.title')}
          noPadding
          marginTop={20}
          showAddBtn={true}
          onAddClick={() => editComponent(null)}
        >
          <SortTable
            tableKey="components"
            data={filtered}
            hasPagination={true}
            pagerCondensed={false}
            perPage={20}
            saveState={true}
            noDataText={tn('admin.page.componentList.table.noData')} // "Keine Benutzerdaten zu diesen Kriterien vorhanden"
            dataName={tn('admin.page.componentList.table.title')} // "Benutzer"
            columns={getComponentListConfig()}
            hasRowHover={true}
            onRowClick={AppStateService.isLOOXR() ? editComponent : null}
            defaulSortBy="articleNr"
            defaultSortOrder="asc"
            rowActions={
              AppStateService.isLOOXR()
                ? [{ key: 'delete', icon: DeleteIcon, props: { color: 'red' } }]
                : []
            }
            onRowActionClick={AppStateService.isLOOXR() ? handleActionClick : null}
          />
        </ContentBox>
      </Box>

      <Modal
        show={showComponentForm}
        size={600}
        closeOnBackdrop={false}
        closeOnEsc={false}
        showModalCloseIcon={false}
        onClose={() => setShowComponentForm(false)}
      >
        <ComponentForm
          component={componentToEdit}
          onSave={() => setShowComponentForm(false)}
          onCancel={() => setShowComponentForm(false)}
        />
      </Modal>

      <Popup
        show={showDeletePopup}
        size={400}
        title={tn('admin.page.componentList.popup.delete.title')} // "Ungespeicherte Daten"
        message={tn('admin.page.componentList.popup.delete.text')} // "Möchten Sie die ungeänderten Daten verwerfen"
        confirmColor="red"
        confirmText={tn('general.popup.unsaved.buttonYes')} // "Ja"
        confirmAction={() => deleteComponent()}
        abortAction={() => setShowDeletePopup(false)}
        abortText={tn('general.popup.unsaved.buttonNo')} // "Nein"
        abortColor="green"
      />

      <Alert
        show={showNotDeleteablePopup}
        title={tn('admin.page.componentList.popup.notdeleteable.title')}
        message={tn('admin.page.componentList.popup.notdeleteable.text')}
        btnText={tn('general.ok')}
        btnAction={() => setShowNotDeleteablePopup(false)}
      />

      <LoadingOverlay isVisible={loading} />
    </>
  );
}

export default ComponentList;
