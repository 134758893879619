import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { textSizes } from '@looxr/utils';
import ArrowDown from '../assets/icons/arrow-down.svg';
import Box from './Box';

const StyledSelect = styled.select`
  background-color: ${props =>
    props.background !== '' ? props.background : props.theme.colors.white};
  color: ${props =>
    props.color !== '' ? props.theme.colors[props.color] : props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.borderColor};
  appearance: none;
  padding: 0 35px 0 14px;
  outline: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  height: 39px;
  width: 100%;
  font-size: ${props => (props.size ? textSizes(props.size) : textSizes('sm'))};
  border-radius: 4px;

  ${props => props.noBorder && 'border: none'};

  background-image: url(${ArrowDown});
  background-repeat: no-repeat;
  background-position: right 14px center;

  /*
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  */

  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    cursor: not-allowed;
  }

  &::-ms-expand {
    display: none;
  }
`;

function Select({
  background,
  noBorder,
  color,
  size,
  disabled,
  id,
  name,
  onChange,
  options,
  placeholder,
  value
}) {
  const handleOnChange = event => {
    if (event.target instanceof HTMLSelectElement && value !== event.target.value) {
      onChange({ event, value: event.target.value });
    }
  };

  return (
    <Box display="flex" position="relative" width="100%">
      <StyledSelect
        id={id}
        name={name}
        background={background}
        noBorder={noBorder}
        color={color}
        size={size}
        disabled={disabled}
        onBlur={handleOnChange}
        onChange={handleOnChange}
        value={value}
      >
        {placeholder && value === '' && (
          <option disabled value="" hidden>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </Box>
  );
}

Select.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  noBorder: PropTypes.bool
};

Select.defaultProps = {
  disabled: false,
  name: null,
  placeholder: '',
  value: '',
  background: '',
  color: '',
  size: null,
  noBorder: false
};

export default Select;
