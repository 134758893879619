import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { textSizes } from '@looxr/utils';
import ArrowDown from '../assets/icons/arrow-down.svg';
import Box from './Box';

const StyledSelect = styled.select`
  background-color: ${(props) =>
    props.background !== '' ? props.background : props.theme.colors.white};
  color: ${(props) =>
    props.color !== '' ? props.theme.colors[props.color] : props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  appearance: none;
  padding: 0 35px 0 14px;
  outline: 0;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  height: 39px;
  width: 100%;
  font-size: ${(props) => (props.size ? textSizes(props.size) : textSizes('sm'))};
  border-radius: 4px;

  ${(props) => props.noBorder && 'border: none'};

  background-image: url(${ArrowDown});
  background-repeat: no-repeat;
  background-position: right 14px center;

  /*
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  */

  &:disabled {
    background-color: ${(props) => props.theme.colors.disabled};
    cursor: not-allowed;
  }

  &::-ms-expand {
    display: none;
  }
`;

// eslint-disable-next-line
export const SelectRH = React.forwardRef(
  (
    { background, noBorder, color, size, disabled, id, name, options, optGroups, placeholder },
    ref
  ) => {
    return (
      <Box display="flex" position="relative" width="100%">
        <StyledSelect
          background={background}
          noBorder={noBorder}
          color={color}
          size={size}
          disabled={disabled}
          id={id}
          name={name}
          ref={ref}
        >
          {placeholder && (
            <option disabled value="" hidden>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}

          {optGroups.map((group) => (
            <optgroup label={group.label} key={group.label}>
              {group.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </optgroup>
          ))}
        </StyledSelect>
      </Box>
    );
  }
);

SelectRH.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  optGroups: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.exact({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    })
  ),
  placeholder: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  noBorder: PropTypes.bool
};

SelectRH.defaultProps = {
  disabled: false,
  name: null,
  placeholder: '',
  background: '',
  color: '',
  size: null,
  noBorder: false,
  options: [],
  optGroups: []
};

// export default SelectRH;
