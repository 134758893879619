import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Box from './Box';

const StyledTextField = styled.input`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.borderColor};
  color: ${props => props.theme.colors.black};
  outline: 0;
  width: 100%;
  border-radius: 4px;
  padding: 7px 14px;
  font-size: 14px;
  display: 'block';

  &:disabled {
    background-color: ${props => props.theme.colors.disabled};
    cursor: not-allowed;
  }

  ${props =>
    props.hasAddOn &&
    css`
      border-radius: 4px 0 0 4px;
      border-right: 0;
    `}
`;

const StyledAddOn = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.grey2};
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 0 4px 4px 0;
  padding: 0 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;

function TextInput({
  autoComplete,
  disabled,
  id,
  name,
  onChange,
  onFocus,
  onKeyDown,
  onBlur,
  placeholder,
  type,
  value,
  addOn,
  isRequired
}) {
  const pattern = type === 'number' ? '\\d*' : undefined;

  if (addOn) {
    return (
      <Box display="flex" width="100%" alignItems="stretch">
        <StyledTextField
          autoComplete={autoComplete}
          disabled={disabled}
          id={id}
          name={name}
          pattern={pattern}
          placeholder={placeholder}
          type={type}
          hasAddOn={addOn}
          required={isRequired}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
        <StyledAddOn>{addOn}</StyledAddOn>
      </Box>
    );
  }

  return (
    <StyledTextField
      autoComplete={autoComplete}
      disabled={disabled}
      id={id}
      name={name}
      pattern={pattern}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
}

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['date', 'email', 'number', 'password', 'text', 'url']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addOn: PropTypes.string
};

TextInput.defaultProps = {
  autoComplete: 'off',
  disabled: false,
  isRequired: false,
  name: '',
  placeholder: '',
  value: '',
  type: 'text',
  addOn: null,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {}
};

export default TextInput;
