import { Collections } from '@looxr/constants';
import { FirebaseService } from '../services';

function useLoadUsers() {
  const getPackagesOfCustomer = async (customerRef) => {
    const snapshot = await FirebaseService.getPackagesOfCustomer(customerRef);
    const data = [];

    snapshot.forEach((doc) => {
      if (doc.exists) {
        const docData = doc.data();
        data.push({
          id: doc.id,
          ref: doc.ref,
          ...docData
        });
      }
    });

    return data;
  };
  const updateCustomerPackages = async (packages) => {
    const packageUpdates = [];
    for (let i = 0; i < packages.length; i += 1) {
      const packageData = packages[i];
      const update = { ...packageData };
      let id = null;

      // save id and remove "no data" fields
      if (packageData.id) {
        id = packageData.id;
        delete update.id;
        delete update.ref;
      }

      packageUpdates.push(FirebaseService.upsertDoc(id, update, Collections.package));
    }

    await Promise.all(packageUpdates);

    return true;
  };

  const getUsersByPackage = async (customerPackageRef, loginType) => {
    try {
      const snapshot = await FirebaseService.getUsersByPackage(loginType, customerPackageRef);
      const docs = [];
      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push({
          id: doc.id,
          ref: doc.ref,
          ...docData
        });
      });

      return docs;
    } catch (error) {
      console.log('Error getting user data');
      console.log(error);
      return [];
    }
  };

  const filterPacakgesForType = (customerPackages, loginType) => {
    return [...customerPackages].filter((customerPackage) => {
      let matching = false;
      const packageType = customerPackage.type.toLowerCase();

      if (customerPackage.active && packageType.includes(loginType)) {
        matching = true;
      }

      return matching;
    });
  };

  const getSelectablePackages = async (customerPackages, loginType) => {
    let selectable = filterPacakgesForType(customerPackages, loginType);

    const loadUsersWithPackage = selectable.map(async (selectablePackage) => {
      const userDocs = await getUsersByPackage(selectablePackage.ref, loginType);
      // @TOOD fix it
      // eslint-disable-next-line no-param-reassign
      selectablePackage.inUse = userDocs.length;

      return selectablePackage;
    });

    const packagesWithInUseData = await Promise.all(loadUsersWithPackage);

    // map the in use to the correct package
    selectable = packagesWithInUseData;

    // Filter out where inUse = maxAccounts
    selectable = selectable.filter(
      (selectablePackage) => selectablePackage.inUse < selectablePackage.maxAccounts
    );

    return selectable;
  };

  return {
    getSelectablePackages,
    getUsersByPackage,
    updateCustomerPackages,
    filterPacakgesForType,
    getPackagesOfCustomer
  };
}

export default useLoadUsers;
