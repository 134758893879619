import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
      box-sizing: border-box;
  }

  html {
    line-height: inherit;
    -ms-text-size-adjust: inherit;
    -webkit-text-size-adjust: inherit;
  }

  body {
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: 'Catamaran', sans-serif;
  }

  html,
  body {
    background: ${(props) => props.theme.colors.background};
    height: 100%;
  }

  :focus,
  :active {
    outline: 0 !important;
  }

  button:disabled {
    cursor: not-allowed;
  }

  #looxr {
    width: 100%;
    height: auto;
  }

  .react-tiny-popover-container {
    box-shadow: rgba(0, 0, 0, 0.07) 4px 4px 17px 0px;
  }
`;

export default GlobalStyle;
